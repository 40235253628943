import { commonCoverPropData } from "../../shared/constants/shared.contants";
import { getYesNoLabel } from "../../shared/utility/get-yes-no-label.utility";
import { ProtectDataInfoType } from "./protect.types";

export const setProtectDBPropData = (protectInfo: ProtectDataInfoType) => {
    const setIndicatortitle = (indicatorCode: string) => {
        const indicatorData = protectInfo.indicators.find(
            (indicator: { code: string }) => {
                return indicator.code === indicatorCode;
            },
        );
        return indicatorData ? indicatorData.title : "";
    };

    return [
        {
            id: 1,
            heading: setIndicatortitle("P1_healthcare"),
            text: getYesNoLabel(protectInfo.P1_healthcare),
            meets: protectInfo.P1_healthcare,
        },
        {
            id: 2,
            heading: setIndicatortitle("P2_education"),
            text: getYesNoLabel(protectInfo.P2_education),
            meets: protectInfo.P2_education,
        },
        {
            id: 3,
            heading: setIndicatortitle("P3_universities"),
            text: getYesNoLabel(protectInfo.P3_universities),
            meets: protectInfo.P3_universities,
        },
        {
            id: 4,
            heading: setIndicatortitle("P4_govt"),
            text: getYesNoLabel(protectInfo.P4_govt),
            meets: protectInfo.P4_govt,
        },
        {
            id: 5,
            heading: setIndicatortitle("P5_indoor_off"),
            text: getYesNoLabel(protectInfo.P5_indoor_off),
            meets: protectInfo.P5_indoor_off,
        },
        {
            id: 6,
            heading: setIndicatortitle("P6_restaurants"),
            text: getYesNoLabel(protectInfo.P6_restaurants),
            meets: protectInfo.P6_restaurants,
        },
        {
            id: 7,
            heading: setIndicatortitle("P7_pubs"),
            text: getYesNoLabel(protectInfo.P7_pubs),
            meets: protectInfo.P7_pubs,
        },
        {
            id: 8,
            heading: setIndicatortitle("P8_pub_transp"),
            text: getYesNoLabel(protectInfo.P8_pub_transp),
            meets: protectInfo.P8_pub_transp,
        },
        {
            id: 9,
            heading: setIndicatortitle("P11_fines_any"),
            text: getYesNoLabel(protectInfo.P11_fines_any),
            meets: protectInfo.P11_fines_any,
        },
        {
            id: 10,
            heading: setIndicatortitle("P12_fines_establishment"),
            text: getYesNoLabel(protectInfo.P12_fines_establishment),
            meets: protectInfo.P12_fines_establishment,
        },
        {
            id: 11,
            heading: setIndicatortitle("P13_fines_smoker"),
            text: getYesNoLabel(protectInfo.P13_fines_smoker),
            meets: protectInfo.P13_fines_smoker,
        },
        {
            id: 12,
            heading: setIndicatortitle("P14_funds_for_enforce"),
            text: getYesNoLabel(protectInfo.P14_funds_for_enforce),
            meets: protectInfo.P14_funds_for_enforce,
        },
        {
            id: 13,
            heading: setIndicatortitle("P15_complaints"),
            text: getYesNoLabel(protectInfo.P15_complaints),
            meets: protectInfo.P15_complaints,
        },
    ];
};

export const setProtectPropData = (protectInfo: ProtectDataInfoType) => {
    let groupNumber = parseInt(protectInfo.P_Group);
    const protectData = [...commonCoverPropData];
    for (let i = 0; i < groupNumber; i++) {
        protectData[i] = { ...protectData[i], meets: true };
    }

    for (let i = 1; i <= groupNumber; i++) {
        if (i === groupNumber) {
            protectData[i - 1] = {
                ...protectData[i - 1],
                lastExpanded: true,
            };
        }
    }

    return protectData;
};

export const getProccessedProtectInfo = (protectInfo: ProtectDataInfoType) => {
    protectInfo.protectPropData = setProtectPropData(protectInfo);
    protectInfo.protectDBPropData = setProtectDBPropData(protectInfo);

    return protectInfo;
};
