import React from "react";

//Interface import
import { CountryIndicatorProps } from "./country-indicator-block-interface";

/**
 * CountryIndicatorBlockComponent is a React component responsible for displaying a set of indicators.
 *
 * @component
 * @param {Object} props - The props for this component.
 * @param {Array} props.indicatorData - An array of indicator data objects to be displayed.
 * @param {string} props.indicatorData[].iconImg - The image source for the indicator.
 * @param {string} props.indicatorData[].alt - The alternative text for the indicator image.
 * @param {string} props.indicatorData[].text - The text label for the indicator.
 * @param {string|number} props.indicatorData[].status - The status value for the indicator.
 * @function CountryIndicatorBlockComponent
 * @returns {React.JSX.Element}
 */

const CountryIndicatorBlockComponent = (
    props: CountryIndicatorProps,
): React.JSX.Element => {
    return (
        <>
            {props.indicatorData.map((indicator) => (
                <React.Fragment key={indicator.id}>
                    <div className="flex justify-center gap-2 col-span-12 md:col-span-4 m-2 lg:m-4 items-center indicator-block" id={`indicatortext-${indicator.id}`}>
                        <div className="indicator-img">
                            <img
                                src={indicator.iconImg}
                                alt={indicator.alt}
                                className="w-8 h-8"
                            />
                        </div>
                        <div className="self-center">
                            <span>{indicator.text}:&nbsp;</span>
                            {!indicator.status ? (
                                <span className="font-bold text-center">
                                    N/A
                                </span>
                            ) : (
                                <span className="font-bold">
                                    {indicator.status}
                                </span>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

export default CountryIndicatorBlockComponent;
