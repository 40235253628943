import { commonCoverPropData } from "../../shared/constants/shared.contants";
import { MassMediaDataInfoType } from "./warn-mass-media.types";

//Image or Icon or logo imports
import assemblyImg from "../../assets/icon/assembly.png";
import televisionImg from "../../assets/icon/television.png";
import comprehensiveImg from "../../assets/icon/comprehensive.png";
import preTestedImg from "../../assets/icon/pre-tested.png";
import formativeResearchImg from "../../assets/icon/input-search.png";
import campaignImg from "../../assets/icon/campaign.png";
import mediaImg from "../../assets/icon/media.png";
import processEvaluationImg from "../../assets/icon/thumbs-up.png";
import { getYesNoLabel } from "../../shared/utility/get-yes-no-label.utility";

export const setMassMediaCoverPropData = (
    massMediaInfo: MassMediaDataInfoType,
) => {
    let groupNumber = parseInt(massMediaInfo["W-MM_Group"]);
    const massMediaData = [...commonCoverPropData];

    for (let i = 0; i < groupNumber; i++) {
        massMediaData[i] = { ...massMediaData[i], meets: true };
    }

    for (let i = 1; i <= groupNumber; i++) {
        if (i === groupNumber) {
            massMediaData[i - 1] = {
                ...massMediaData[i - 1],
                lastExpanded: true,
            };
        }
    }

    return massMediaData;
};

const setIndicatortitle = (
    indicatorCode: string,
    massMediaInfo: MassMediaDataInfoType,
) => {
    const indicatorData = massMediaInfo.indicators.find(
        (indicator: { code: string }) => {
            return indicator.code === indicatorCode;
        },
    );
    return indicatorData ? indicatorData.title : "";
};

export const setMassMediaStatusPropData = (
    massMediaInfo: MassMediaDataInfoType,
) => {
    return [
        {
            id: "01",
            img: assemblyImg,
            alt: "assebmly-care-image",
            title: setIndicatortitle("Camp_nat", massMediaInfo),
            result: massMediaInfo.Camp_nat,
        },
        {
            id: "02",
            img: televisionImg,
            alt: "television-image",
            title: setIndicatortitle("Camp_tv_radio", massMediaInfo),
            result: massMediaInfo.Camp_tv_radio,
        },
        {
            id: "03",
            img: comprehensiveImg,
            alt: "comprehensive-image",
            title: setIndicatortitle("Camp_gov_prog", massMediaInfo),
            result: massMediaInfo.Camp_gov_prog,
        },
        {
            id: "04",
            img: preTestedImg,
            alt: "pre-tested-image",
            title: setIndicatortitle("Camp_mat_tested", massMediaInfo),
            result: massMediaInfo.Camp_mat_tested,
        },
        {
            id: "05",
            img: formativeResearchImg,
            alt: "formative-esearch-image",
            title: setIndicatortitle("Camp_aud_research", massMediaInfo),
            result: massMediaInfo.Camp_aud_research,
        },
        {
            id: "06",
            img: campaignImg,
            alt: "campaign-image",
            title: setIndicatortitle("Camp_media_planning", massMediaInfo),
            result: massMediaInfo.Camp_media_planning,
        },
        {
            id: "07",
            img: mediaImg,
            alt: "media-image",
            title: setIndicatortitle("Camp_news", massMediaInfo),
            result: massMediaInfo.Camp_news,
        },
        {
            id: "08",
            img: processEvaluationImg,
            alt: "process-evaluation-image",
            title: setIndicatortitle("Camp_media_monitor", massMediaInfo),
            result: massMediaInfo.Camp_media_monitor,
        },
    ];
};

export const setMassMediaDBPropData = (
    massMediaInfo: MassMediaDataInfoType,
) => {
    return [
        {
            id: 1,
            heading: setIndicatortitle("Camp_nat", massMediaInfo),
            text: getYesNoLabel(massMediaInfo.Camp_nat),
            meets: massMediaInfo.Camp_nat,
        },
        {
            id: 2,
            heading: setIndicatortitle("Camp_tv_radio", massMediaInfo),
            text: getYesNoLabel(massMediaInfo.Camp_tv_radio),
            meets: massMediaInfo.Camp_tv_radio,
        },
        {
            id: 3,
            heading: setIndicatortitle("Camp_gov_prog", massMediaInfo),
            text: getYesNoLabel(massMediaInfo.Camp_gov_prog),
            meets: massMediaInfo.Camp_gov_prog,
        },
        {
            id: 4,
            heading: setIndicatortitle("Camp_mat_tested", massMediaInfo),
            text: getYesNoLabel(massMediaInfo.Camp_mat_tested),
            meets: massMediaInfo.Camp_mat_tested,
        },
        {
            id: 5,
            heading: setIndicatortitle("Camp_aud_research", massMediaInfo),
            text: getYesNoLabel(massMediaInfo.Camp_aud_research),
            meets: massMediaInfo.Camp_aud_research,
        },
        {
            id: 6,
            heading: setIndicatortitle("Camp_media_planning", massMediaInfo),
            text: getYesNoLabel(massMediaInfo.Camp_media_planning),
            meets: massMediaInfo.Camp_media_planning,
        },
        {
            id: 7,
            heading: setIndicatortitle("Camp_news", massMediaInfo),
            text: getYesNoLabel(massMediaInfo.Camp_news),
            meets: massMediaInfo.Camp_news,
        },
        {
            id: 8,
            heading: setIndicatortitle("Camp_media_monitor", massMediaInfo),
            text: getYesNoLabel(massMediaInfo.Camp_media_monitor),
            meets: massMediaInfo.Camp_media_monitor,
        },
    ];
};

export const getProccessedMassMediaInfo = (
    massMediaInfo: MassMediaDataInfoType,
) => {
    massMediaInfo.massMediaCoverPropData =
        setMassMediaCoverPropData(massMediaInfo);
    massMediaInfo.massMediaCurrentPropData =
        setMassMediaStatusPropData(massMediaInfo);
    massMediaInfo.massMediaDBPropData = setMassMediaDBPropData(massMediaInfo);

    return massMediaInfo;
};
