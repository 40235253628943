import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_DATA,
    STATUS_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";
import { MpowerKeyDataType } from "../mpower-score-key/mpower-score-key-interface";
import { ProgressYearDataPropType } from "./mpower-progress-main-interface";

export const GENERAL_MPOWER_SCORE_KEY_DATA: MpowerKeyDataType[] = [
    {
        id: "01",
        cardText: STATUS_NO_DATA,
        cardBgColor: BG_COLOR_NO_DATA,
    },
    {
        id: "02",
        cardText: STATUS_NO_REQUIREMENT,
        cardBgColor: BG_COLOR_NO_REQUIREMENT,
    },
    {
        id: "03",
        cardText: STATUS_MINIMAL,
        cardBgColor: BG_COLOR_MINIMAL,
    },
    {
        id: "04",
        cardText: STATUS_MODERATE,
        cardBgColor: BG_COLOR_MODERATE,
    },
    {
        id: "05",
        cardText: STATUS_COMPLETE,
        cardBgColor: BG_COLOR_COMPLETE,
    },
];

export const MONITOR_MPOWER_SCORE_KEY_DATA: MpowerKeyDataType[] = [
    {
        id: "01",
        cardText: `No known data or no recent¹ data
        or data that are not both recent¹
        and representative²`,
        cardBgColor: BG_COLOR_NO_REQUIREMENT,
    },
    {
        id: "02",
        cardText: `Recent¹ and representative² data
        for either adults or adolescents`,
        cardBgColor: BG_COLOR_MINIMAL,
        lowestLevel: true,
    },
    {
        id: "03",
        cardText: `Recent¹ and representative² data
        for both adults and adolescents`,
        cardBgColor: BG_COLOR_MODERATE,
    },
    {
        id: "04",
        cardText: `Recent¹, representative², and
        periodic³ data for both adults
        and adolescents`,
        cardBgColor: BG_COLOR_COMPLETE,
    },
];

export const PROTECT_MPOWER_SCORE_KEY_DATA: MpowerKeyDataType[] = [
    {
        id: "01",
        cardText: "Data not reported",
        cardBgColor: BG_COLOR_NO_DATA,
    },
    {
        id: "02",
        cardText:
            "Complete absence of bans, or up to two public places completely smoke-free",
        cardBgColor: BG_COLOR_NO_REQUIREMENT,
    },
    {
        id: "03",
        cardText: "Three to five public places completely smoke-free",
        cardBgColor: BG_COLOR_MINIMAL,
    },
    {
        id: "04",
        cardText: "Six to seven public places completely smoke-free",
        cardBgColor: BG_COLOR_MODERATE,
    },
    {
        id: "05",
        cardText: "All public places completely smoke-free",
        cardBgColor: BG_COLOR_COMPLETE,
    },
];

export const OFFER_MPOWER_SCORE_KEY_DATA: MpowerKeyDataType[] = [
    {
        id: "01",
        cardText: "Data not reported",
        cardBgColor: BG_COLOR_NO_DATA,
    },
    {
        id: "02",
        cardText: "None",
        cardBgColor: BG_COLOR_NO_REQUIREMENT,
    },
    {
        id: "03",
        cardText: "NRT and/or some cessation services (neither cost-covered)",
        cardBgColor: BG_COLOR_MINIMAL,
    },
    {
        id: "04",
        cardText:
            "NRT and/or some cessation services (at least one of which is cost-covered)",
        cardBgColor: BG_COLOR_MODERATE,
    },
    {
        id: "05",
        cardText:
            "National quit line, and both NRT and some cessation services cost-covered",
        cardBgColor: BG_COLOR_COMPLETE,
    },
];

export const WARN_PACKAGE_MPOWER_SCORE_KEY_DATA: MpowerKeyDataType[] = [
    {
        id: "01",
        cardText: "Data not reported",
        cardBgColor: BG_COLOR_NO_DATA,
    },
    {
        id: "02",
        cardText: "No warnings or small warnings¹",
        cardBgColor: BG_COLOR_NO_REQUIREMENT,
    },
    {
        id: "03",
        cardText:
            "Medium size warnings² missing some³ or many⁴ appropriate characteristics⁵ OR large warnings⁶ missing many⁴ appropriate characteristics⁵",
        cardBgColor: BG_COLOR_MINIMAL,
    },
    {
        id: "04",
        cardText:
            "Medium size warnings² with all appropriate characteristics⁵ OR large warnings⁶ missing some³ appropriate characteristics⁵",
        cardBgColor: BG_COLOR_MODERATE,
    },
    {
        id: "05",
        cardText: "Large warnings⁶ with all appropriate characteristics⁵",
        cardBgColor: BG_COLOR_COMPLETE,
    },
];

export const WARN_MASSMEDIA_MPOWER_SCORE_KEY_DATA: MpowerKeyDataType[] = [
    {
        id: "01",
        cardText: "Data not reported",
        cardBgColor: BG_COLOR_NO_DATA,
    },
    {
        id: "02",
        cardText:
            "No national campaign conducted during the past 2 years with a duration of at least three weeks",
        cardBgColor: BG_COLOR_NO_REQUIREMENT,
    },
    {
        id: "03",
        cardText:
            "National campaign conducted with one to four appropriate characteristics",
        cardBgColor: BG_COLOR_MINIMAL,
    },
    {
        id: "04",
        cardText:
            "National campaign conducted with five to six appropriate characteristics",
        cardBgColor: BG_COLOR_MODERATE,
    },
    {
        id: "05",
        cardText:
            "National campaign conducted with at least seven appropriate characteristics including airing on television and/or radio",
        cardBgColor: BG_COLOR_COMPLETE,
    },
];

export const ENFORCE_MPOWER_SCORE_KEY_DATA: MpowerKeyDataType[] = [
    {
        id: "01",
        cardText: "Data not reported",
        cardBgColor: BG_COLOR_NO_DATA,
    },
    {
        id: "02",
        cardText:
            "Complete absence of ban, or ban that does not cover national television (TV), radio and print media",
        cardBgColor: BG_COLOR_NO_REQUIREMENT,
    },
    {
        id: "03",
        cardText: "Ban on national TV, radio and print media only",
        cardBgColor: BG_COLOR_MINIMAL,
    },
    {
        id: "04",
        cardText:
            "Ban on national TV, radio and print media as well as on some (but not all) other forms of direct¹ and/or indirect² advertising",
        cardBgColor: BG_COLOR_MODERATE,
    },
    {
        id: "05",
        cardText: "Ban on all forms of direct¹ and indirect² advertising",
        cardBgColor: BG_COLOR_COMPLETE,
    },
];

export const RAISETAX_MPOWER_SCORE_KEY_DATA: MpowerKeyDataType[] = [
    {
        id: "01",
        cardText: "Data not reported",
        cardBgColor: BG_COLOR_NO_DATA,
    },
    {
        id: "02",
        cardText: "<25% of retail price is tax",
        cardBgColor: BG_COLOR_NO_REQUIREMENT,
    },
    {
        id: "03",
        cardText: "≥25% and <50% of retail price is tax",
        cardBgColor: BG_COLOR_MINIMAL,
    },
    {
        id: "04",
        cardText: "≥50% and <75% of retail price is tax",
        cardBgColor: BG_COLOR_MODERATE,
    },
    {
        id: "05",
        cardText: "≥75% of retail price is tax",
        cardBgColor: BG_COLOR_COMPLETE,
    },
];

export const yearData: ProgressYearDataPropType[] = [
    {
        id: "01",
        year: "2007",
    },
    {
        id: "02",
        year: "2008",
    },
    {
        id: "03",
        year: "2010",
    },
    {
        id: "04",
        year: "2012",
    },
    {
        id: "05",
        year: "2014",
    },
    {
        id: "06",
        year: "2016",
    },
    {
        id: "07",
        year: "2018",
    },
    {
        id: "08",
        year: "2020",
    },
    {
        id: "09",
        year: "2022",
    },
];
