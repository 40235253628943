import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../shared/components/rest-handler/rest-handler.service";
import * as MONITORINFO_SLICE from "./monitor.slice";
import { monitorPropData } from "../components/monitor.constants";

export function* getMonitorInfo(action: any): any {
    let url = `/Monitoring?countryCode=${action?.payload || ""}`;
    try {
        const monitorInfo = yield call(RestHandlerService.get, url, {});
        let groupNumber = parseInt(monitorInfo?.M_Group);

        const monitorData = [...monitorPropData];

        for (let i = 0; i < groupNumber; i++) {
            monitorData[i] = { ...monitorData[i], meets: true };
        }

        for (let i = 1; i <= groupNumber; i++) {
            if (i === groupNumber) {
                monitorData[i - 1] = {
                    ...monitorData[i - 1],
                    lastExpanded: true,
                };
            }
        }

        monitorInfo.monitorPropData = monitorData;
        yield put(MONITORINFO_SLICE.getMonitorInfoSuccess(monitorInfo));
    } catch (err) {
        yield put(MONITORINFO_SLICE.getMonitorInfoError());
    }
}

export function* monitorInfoSaga() {
    yield takeLatest("monitorInfo/getMonitorInfo", getMonitorInfo);
}
