//Interface import
import React from "react";
import { SuccessPropType, SuccessStoryProps } from "./success-story-interface";
import { getUniqueKey } from "../../utility/shared.utility";

/**
 * SuccessStoryComponent is a functional component that displays a success story banner along with image and related content.
 *
 * @component
 * @param {SuccessStoryProps} props
 * @returns {React.JSX.Element}
 */

const SuccessStoryComponent = (props: SuccessStoryProps): React.JSX.Element => {
    return (
        <div className="banner-bg py-24">
            <div className="container mx-auto">
                <div className="mx-4">
                    <h2 className="text-white text-4xl border-b border-white" id="MPtext19">
                        Success stories
                    </h2>
                </div>
                <div className="grid grid-cols-3 py-10 mx-4">
                    <div className="col-span-3 lg:col-span-1 mr-10 text-white" id="MPtext20">
                        <p>
                            Adopting new measures and implementing them can
                            often meet with challenges, but countries that have
                            been bold and forward-thinking have achieved some
                            fantastic results. You can find more success stories
                            and case studies in the full report.
                        </p>
                    </div>
                    <div className="col-span-3 lg:col-span-2 mt-4 lg:mt-0 bg-white rounded">
                        {props?.successData.map(
                            (successData: SuccessPropType) => {
                                return (
                                    <React.Fragment key={successData.id}>
                                        {successData?.img && (
                                            <div className="flex justify-center items-center m-8">
                                                <img
                                                    src={successData?.img}
                                                    alt={successData?.alt}
                                                />
                                            </div>
                                        )}
                                        <div className="m-8 mt-0">
                                            <h2 className="success-story-h2">
                                                {successData?.heading}
                                            </h2>
                                            <div className="h-0.5 hp-hr-bgcolor mb-10"></div>
                                            <div>
                                                {successData?.text
                                                    ?.split("\n")
                                                    .map((subText: string) => {
                                                        return (
                                                            <div
                                                                key={getUniqueKey()}
                                                            >
                                                                {subText}
                                                                <br />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            },
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessStoryComponent;
