// Components imports
import SuccessStoryComponent from "../../../shared/components/success-story/success-story.component";

import { WARN_PACKAGING_SUCCESS_PROP_DATA } from "../../../shared/constants/shared.contants";

/**
 * WarnPackageSuccessStoryComponent is a functional component that displays a success story section with a description and an image.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const WarnPackageSuccessStoryComponent = (): React.JSX.Element => {
    return (
        <SuccessStoryComponent successData={WARN_PACKAGING_SUCCESS_PROP_DATA} />
    );
};

export default WarnPackageSuccessStoryComponent;
