import React from "react";
import { HeaderPropType } from "./header-interface";
import WhoBlueLogo from "../../../assets/logo/who-logo-blue.png";
import mpowerLogo from "../../../assets/logo/mpower-logo-gradient.png";
import { Link } from "react-router-dom";
import HeaderImageComponent from "./header-image.component";

interface HeaderProps {
    imgData?: HeaderPropType[];
}

const HeaderComponent = ({ imgData }: HeaderProps): React.JSX.Element => {
    return (
        <div className="header bg-white p-2" data-testid={`headerComponent`}>
            <div className="container mx-auto flex">
                {imgData?.map((imageData) => (
                    <React.Fragment key={imageData?.id}>
                        <div className="mx-4 w-1/2">
                            {imageData?.url ? (
                                <div className="flex justify-end" id={imageData?.headerId}>
                                    <Link to={imageData?.url}>
                                        <HeaderImageComponent
                                            imageSrc={imageData?.img}
                                            imageAlt={imageData?.alt}
                                            imageClass={imageData?.class}
                                        />
                                    </Link>
                                </div>
                            ) : (
                                <div>
                                    <HeaderImageComponent
                                        imageSrc={imageData?.img}
                                        imageAlt={imageData?.alt}
                                        imageClass={imageData?.class}
                                    />
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

HeaderComponent.defaultProps = {
    imgData: [
        {
            id: "01",
            img: WhoBlueLogo,
            alt: "who-blue-logo",
            class: "whologo",
            url: "",
        },
        {
            id: "02",
            img: mpowerLogo,
            alt: "mpower-logo",
            class: "mpowerlogo",
            url: "",
        },
    ],
};

export default HeaderComponent;
