import { useEffect } from "react";
import {
    clearCountryCompareData,
    setCompareSearchData,
    setIsCountryCompareNotFound,
} from "../../../shared/components/countries-compare/redux/country-compare.slice";
import { useAppDispatch } from "../../../redux/hooks";
import { CountryInfoType } from "../../redux/country.types";
import { useSelector } from "react-redux";
import { selectedCountrySelector } from "../../redux/country.selector";

// Components imports
import MpowerBlockComponent from "../mpower-block/mpower-block.component";
import MpowerBlockHeaderComponent from "../mpower-block-header/mpower-block-header.component";

/**
 * MpowerBlockViewComponent for rendering Mpower main block views.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const MpowerBlockViewComponent = (): React.JSX.Element => {
    const selectedCountry: CountryInfoType = useSelector(
        selectedCountrySelector,
    );

    const dispatch = useAppDispatch();

    let raiseTaxBgColor;
    if (selectedCountry?.affordCat === "Yes") {
        raiseTaxBgColor = "block-box-green";
    } else if (selectedCountry?.affordCat === "No") {
        raiseTaxBgColor = "block-box-red";
    } else {
        raiseTaxBgColor = "";
    }

    let raiseTaxPercenatge = selectedCountry?.totalTaxEstimate;

    if (raiseTaxPercenatge === ". . .") {
        raiseTaxPercenatge = "";
    } else {
        raiseTaxPercenatge = parseFloat(raiseTaxPercenatge).toFixed(1);
    }

    useEffect(() => {
        dispatch(clearCountryCompareData());
        dispatch(setCompareSearchData(""));
        dispatch(setIsCountryCompareNotFound(false));
    }, []);

    return (
        <div className="mpower-blockview">
            <div className="grid grid-cols-12">
                <div className="col-span-12 md:col-span-4 mx-4 my-6">
                    <MpowerBlockHeaderComponent
                        mpowerBlockHeaderData={
                            selectedCountry?.monitorBlockPropData
                        }
                        id="monitor"
                    />
                    <MpowerBlockComponent
                        mpowerBlockData={selectedCountry?.monitorBlockPropData}
                        policyData={"0"}
                        id="Monitor"
                    />
                </div>
                <div className="col-span-12 md:col-span-4 mx-4 my-6">
                    <MpowerBlockHeaderComponent
                        mpowerBlockHeaderData={
                            selectedCountry?.protectBlockPropData
                        }
                        id="protect"
                    />
                    <MpowerBlockComponent
                        mpowerBlockData={selectedCountry?.protectBlockPropData}
                        policyData={"1"}
                        id="Protect"
                    />
                </div>
                <div className="col-span-12 md:col-span-4 mx-4 my-6">
                    <MpowerBlockHeaderComponent
                        mpowerBlockHeaderData={
                            selectedCountry?.offerBlockPropData
                        }
                        id="offer"
                    />
                    <MpowerBlockComponent
                        mpowerBlockData={selectedCountry?.offerBlockPropData}
                        policyData={"2"}
                        id="Offer"
                    />
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-12 md:col-span-8 mx-4 m-6">
                    <MpowerBlockHeaderComponent
                        mpowerBlockHeaderData={
                            selectedCountry?.warnPackageBlockPropData
                        }
                        id="warn"
                    />
                    <div className="grid grid-cols-2">
                        <div className="col-span-2 md:col-span-1 md:me-4 mb-6">
                            <MpowerBlockComponent
                                mpowerBlockData={
                                    selectedCountry?.warnPackageBlockPropData
                                }
                                policyData={"4"}
                                id="Warn-Package"
                            />
                        </div>
                        <div className="col-span-2 md:col-span-1 md:ml-4 mb-6">
                            <MpowerBlockComponent
                                mpowerBlockData={
                                    selectedCountry?.massMediaBlockPropData
                                }
                                policyData={"3"}
                                id="Warn-MassMedia"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-4 mx-4 my-6">
                    <MpowerBlockHeaderComponent
                        mpowerBlockHeaderData={
                            selectedCountry?.enforceBlockPropData
                        }
                        id="enforce"
                    />
                    <MpowerBlockComponent
                        mpowerBlockData={selectedCountry?.enforceBlockPropData}
                        policyData={"5"}
                        id="Enforce"
                    />
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-12 md:col-span-8 mx-4 m-6">
                    <MpowerBlockHeaderComponent
                        mpowerBlockHeaderData={
                            selectedCountry?.raiseTaxBlockPropData
                        }
                        id="raise"
                    />
                    <div className="grid grid-cols-2">
                        <div className="col-span-2 md:col-span-1 md:me-4 mb-6 relative">
                            {!raiseTaxPercenatge ? (
                                <div></div>
                            ) : (
                                <div className="raisetax-percent">{`${raiseTaxPercenatge}%`}</div>
                            )}

                            <MpowerBlockComponent
                                mpowerBlockData={
                                    selectedCountry?.raiseTaxBlockPropData
                                }
                                policyData={"6"}
                                id="Raise"
                            />
                        </div>
                        <div className="col-span-2 md:col-span-1 md:ml-4 mb-6">
                            <div className="block shadow-lg flex flex-col justify-between h-full">
                                <p className="m-2 text-center text-xl">
                                    Have cigarettes become less affordable since
                                    2012?
                                </p>
                                <div
                                    className={`flex h-36 justify-center items-center ${raiseTaxBgColor}`}
                                >
                                    {!selectedCountry?.affordCat ? (
                                        <p className="sub-head raise-tax-text">
                                            N/A
                                        </p>
                                    ) : (
                                        <p className="sub-head raise-tax-text">
                                            {selectedCountry?.affordCat}
                                        </p>
                                    )}
                                </div>
                                <p className="text-gray-400 text-xs">
                                    (per capita GDP needed to buy 2000
                                    cigarettes of the most sold brand increased
                                    on average between 2012 and 2022.)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-4 m-6"></div>
            </div>
        </div>
    );
};

export default MpowerBlockViewComponent;
