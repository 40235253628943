import { useEffect, useRef, useState } from "react";

// Component import
import CountrySearchComponent from "./country-search.component";

// Interface import
import { ICountrySearchMainComponentProps } from "./country-search-main-interface";

const CountrySearchMainComponent: React.FC<
    ICountrySearchMainComponentProps
> = ({ onNextClick, buttonText, onFocus, onBlur, id }) => {
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!showSearchIcon && inputRef?.current) {
            inputRef?.current.focus();
        }
    }, [showSearchIcon]);

    const handleClick = () => {
        setShowSearchIcon((prev) => (prev ? !prev : prev));
    };

    return (
        <div className="grid grid-cols-8 align-middle justify-center mt-6 gap-4">
            <div className="mt-4 col-span-8 md:col-span-4 xl:col-span-4">
                <button
                    onClick={() => onNextClick()}
                    id={id}
                    className="text-sm bg-yellow-500 text-black font-semibold py-4 px-4 rounded-full w-full"
                >
                    {buttonText}
                </button>
            </div>
            <div
                className="mt-4 col-span-8 md:col-span-4 xl:col-span-4"
                onClick={handleClick}
                data-testid={`country-search-main-component`}
            >
                <div
                    className={`home-slider-search lg:ml-4 mt-4 md:mt-0 ${
                        showSearchIcon ? "" : "search-expand"
                    }`}
                    data-testid={`country-search-component`}
                >
                    <CountrySearchComponent
                        inputRef={inputRef}
                        IsShowSearchIconTrue={showSearchIcon}
                        widthClass={"w-full"}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        id={`${id}-searchbegin`}
                    />
                </div>
            </div>
        </div>
    );
};

export default CountrySearchMainComponent;
