import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { countryNameSelector } from "../../redux/country.selector";

//Image or Icon or logo imports
import ReverseArrowIcon from "../../../assets/icon/reverse-arrow-icon.png";
import { CountryNamePropType } from "./country-name-interface";
import { useAppDispatch } from "../../../redux/hooks";
import { setCarouselCurrentIndex } from "../../../shared/redux/shared.slice";

/**
 * CountryNameComponent is a React component responsible for displaying the country name and a search button.
 * @function CountryNameComponent
 * @returns {React.JSX.Element}
 */

const CountryNameComponent = (
    props: CountryNamePropType,
): React.JSX.Element => {
    const countryName = useSelector(countryNameSelector);

    const dispatch = useAppDispatch();
    const handleClick = () => {
        if (props.goToThirdCaraousel) {
            dispatch(setCarouselCurrentIndex(2));
        }
    };

    return (
        <div className="sm:flex my-8 mx-4">
            <div className="flex justify-center sm:justify-start sm:w-1/2" id="countrytext_01">
                <span className="hp-heading-color p-2 country-heading">
                    {countryName}
                </span>
            </div>
            <div className="flex justify-center sm:justify-end self-center sm:w-1/2 mt-3 sm:mt-0">
                <Link to={props.url ? props.url : ""}>
                    <button
                        className={`flex rounded-full ${
                            props.buttonWidth ? props.buttonWidth : "w-full"
                        } p-2 search-btn`}
                          id={props.id}
                       onClick={() => {
                            handleClick();  
                        }}
                    >
                        <span className="searchtext pr-2">
                            {props.contentElement}
                        </span>
                        <span className="rounded-full bg-slate-800 p-1.5">
                            <img
                                width="20"
                                src={ReverseArrowIcon}
                                alt="Reverse-Arrow-Icon"
                            />
                        </span>
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default CountryNameComponent;
