import React from "react";

//Image or Icon or logo imports
import CrossIcon from "../../../assets/icon/cross.png";
import CrossIconBlack from "../../../assets/icon/cross-black.png";

//Interface import
import { CompareDataprops } from "./countries-compare-block-interface";
import { deleteItem } from "../countries-compare/redux/country-compare.slice";
import { useAppDispatch } from "../../../redux/hooks";
import { getTextColorClass, getUniqueKey } from "../../utility/shared.utility";

/**
 * CountriesCompareBlockComponent is a functional component that displays and manages the categorization of countries
 * into different requirement categories and allows users to delete countries from each category.
 *
 * @component
 * @param {CompareDataprops} props
 */

const CountriesCompareBlockComponent = (props: CompareDataprops) => {
    const dispatch = useAppDispatch();

    const deleteCountryItem = (index: any, category: string) => {
        dispatch(deleteItem({ index, category }));
    };

    return (
        <>
            <div className="col-span-1 font-12 mobile-compare-block">
                {props.compareData.map((card) => (
                    <React.Fragment key={card?.id}>
                        <div className="h-24 countries-color-block">
                            <div
                                className={`shadow  ${card?.cardBgColor} ${getTextColorClass(
                                    card?.cardBgColor,
                                )}`}
                                id={`${card.cardBgColor}-cardBgColor`}
                            >
                                <p id={`${card.id}-paraTexts`}>{card?.paraText}</p>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="col-span-3 overflow-hidden right-block mobile-compare-block">
                {props.compareData.map((card) => (
                    <React.Fragment key={card?.id}>
                        <div className="col-span-5 h-24 md:col-span-1 countries-color-block flex justify-left">
                            <div className={`shadow`}></div>
                            <div className="flex items-start">
                                <ul className="flex flex-wrap">
                                    {card.countryList
                                        .filter(
                                            (item) =>
                                                item !== "" &&
                                                item !== null &&
                                                item !== undefined,
                                        )
                                        .map((item, index) => (
                                            <li
                                                key={getUniqueKey()}
                                                className={`${
                                                    index >= 2
                                                        ? "hidden md:flex"
                                                        : ""
                                                }`}
                                            >
                                                <span>{item}</span>
                                                <button
                                                    onClick={() => {
                                                        deleteCountryItem(
                                                            index,
                                                            card?.category,
                                                        );
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            card?.cardBgColor ===
                                                            "color-no-data"
                                                                ? CrossIconBlack
                                                                : CrossIcon
                                                        }
                                                        alt="delete-icon"
                                                        className={`w-3 h-3 rounded-full ${card?.cardBgColor} ${
                                                            card?.cardBgColor ===
                                                            "color-no-data"
                                                                ? "no-data-border"
                                                                : ""
                                                        }`}
                                                    />
                                                </button>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            {props.compareData.map((card) => (
                <React.Fragment key={card?.id}>
                    <div className="col-span-5 md:col-span-1 countries-color-block large-compare-block">
                        <div
                            className={`shadow px-4 ${card?.cardBgColor} ${getTextColorClass(
                                card?.cardBgColor,
                            )}`}
                        >
                            <p>{card?.paraText}</p>
                        </div>
                        <ul className="px-4 mt-4">
                            {card.countryList
                                .filter(
                                    (item) =>
                                        item !== "" &&
                                        item !== null &&
                                        item !== undefined,
                                )
                                .map((item, index) => (
                                    <li key={getUniqueKey()}>
                                        <span>{item}</span>
                                        <button
                                            onClick={() => {
                                                deleteCountryItem(
                                                    index,
                                                    card?.category,
                                                );
                                            }}
                                        >
                                            <img
                                                src={
                                                    card?.cardBgColor ===
                                                    "color-no-data"
                                                        ? CrossIconBlack
                                                        : CrossIcon
                                                }
                                                alt="delete-icon"
                                                className={`w-3 h-3 rounded-full ${card?.cardBgColor} ${
                                                    card?.cardBgColor ===
                                                    "color-no-data"
                                                        ? "no-data-border"
                                                        : ""
                                                }`}
                                            />
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

export default CountriesCompareBlockComponent;
