import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const raiseTaxInfoState = (state: RootState) => {
    return state.raiseTaxInfo;
};

export const raiseTaxInfoSelector = createSelector(
    [raiseTaxInfoState],
    (raiseTaxInfoState) => {
        return raiseTaxInfoState?.raiseTaxData || {};
    },
);
