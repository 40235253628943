// Components imports
import CountriesRegionComponent from "../shared/components/countries-compare-region/countries-compare-region.component";
import CountriesCompareComponent from "../shared/components/countries-compare/components/countries-compare.component";
import HelpfullResourcesComponent from "../shared/components/helpful-resources/components/helpful-resources.component";
import SharedActionComponent from "../shared/components/shared-action/shared-action.component";
import EnforceCoverComponent from "./components/enforce-cover/enforce-cover.component";
import EnforceCurrentStatusComponent from "./components/enforce-current-status/enforce-current-status.component";
import EnforceDetailedBreakdownComponent from "./components/enforce-detailed-breakdown/enforce-detailed-breakdown.component";
import EnforceSuccessStoryComponent from "./components/enforce-success-story/enforce-success-story.component";

/**
 * EnforceProtectBodyComponent is responsible for rendering the main body section of the Protect page.
 * @function ProtectBodyComponent
 * @returns {React.JSX.Element}
 */

const EnforceBodyComponent = (): React.JSX.Element => {
    return (
        <div className="enforce-page">
            <EnforceCoverComponent />
            <EnforceCurrentStatusComponent />
            <EnforceDetailedBreakdownComponent />
            <CountriesRegionComponent measureName="ENFORCE" />
            <CountriesCompareComponent />
            <EnforceSuccessStoryComponent />
            <SharedActionComponent />
            <HelpfullResourcesComponent />
        </div>
    );
};

export default EnforceBodyComponent;
