import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const warnPackageInfoState = (state: RootState) => {
    return state.warnPackageInfo;
};

export const warnPackageInfoSelector = createSelector(
    [warnPackageInfoState],
    (warnPackageInfoState) => {
        return warnPackageInfoState?.warnPackageData || {};
    },
);
