export const NAME_MONITOR = "monitor";
export const NAME_PROTECT = "protect";
export const NAME_OFFER = "offer";
export const NAME_WARNPACKAGE = "warnPackage";
export const NAME_WARNCAMPAIGN = "warncampaign";
export const NAME_ENFORCE = "enforce";
export const NAME_RAISE = "raise";

export const SELECTED_COUNTRY = {
    countryInfo: { dateUpdated: "", fctcStatus: "", incomeGroup: "" },
    enforce: "",
    monitoring: "",
    offer: "",
    progress: {},
    protect: "",
    affordCat: "",
    totalTaxEstimate: "",
    raiseTax: "",
    warnMassMedia: "",
    warnPackaging: "",
    indicators: [
        {
            code: "",
            title: "",
        },
    ],
    monitorBlockPropData: {
        name: "",
        img: "",
        alt: "",
        paraText: "",
        url: "",
        initial: "",
        finalWord: "",
        buttonText: "",
        buttonBgColor: "",
        countryGroup: null,
    },
    protectBlockPropData: {
        name: "",
        img: "",
        alt: "",
        paraText: "",
        url: "",
        initial: "",
        finalWord: "",
        buttonText: "",
        buttonBgColor: "",
        countryGroup: null,
    },
    offerBlockPropData: {
        name: "",
        img: "",
        alt: "",
        paraText: "",
        url: "",
        initial: "",
        finalWord: "",
        buttonText: "",
        buttonBgColor: "",
        countryGroup: null,
    },
    warnPackageBlockPropData: {
        name: "",
        img: "",
        alt: "",
        paraText: "",
        url: "",
        initial: "",
        finalWord: "",
        buttonText: "",
        buttonBgColor: "",
        countryGroup: null,
    },
    massMediaBlockPropData: {
        name: "",
        img: "",
        alt: "",
        paraText: "",
        url: "",
        initial: "",
        finalWord: "",
        buttonText: "",
        buttonBgColor: "",
        countryGroup: null,
    },
    enforceBlockPropData: {
        name: "",
        img: "",
        alt: "",
        paraText: "",
        url: "",
        initial: "",
        finalWord: "",
        buttonText: "",
        buttonBgColor: "",
        countryGroup: null,
    },
    raiseTaxBlockPropData: {
        name: "",
        img: "",
        alt: "",
        paraText: "",
        url: "",
        initial: "",
        finalWord: "",
        buttonText: "",
        buttonBgColor: "",
        countryGroup: null,
    },
};
