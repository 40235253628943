import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../rest-handler/rest-handler.service";
import * as COUNTRYCOMPAREREGIONINFO_SLICE from "./countries-compare-region-block.slice";
import { getProccessedCountryRegionInfo } from "./countries-compare-region-block.utilities";

export function* getCountryCompareRegionInfo(action: any): any {
    let url = `/RegionalData?countryCode=${
        action?.payload?.countryCode || ""
    }&policy=${action?.payload?.policyNumber}`;
    try {
        let countryCompareRegionInfo = yield call(
            RestHandlerService.get,
            url,
            {},
        );
        countryCompareRegionInfo = getProccessedCountryRegionInfo(
            countryCompareRegionInfo,
            action?.payload?.policyNumber,
        );

        yield put(
            COUNTRYCOMPAREREGIONINFO_SLICE.getCountryCompareRegionInfoSuccess(
                countryCompareRegionInfo,
            ),
        );
    } catch (err) {
        yield put(
            COUNTRYCOMPAREREGIONINFO_SLICE.getCountryCompareRegionInfoError(),
        );
    }
}

export function* countryCompareRegionInfoSaga() {
    yield takeLatest(
        "countryCompareRegionInfo/getCountryCompareRegionInfo",
        getCountryCompareRegionInfo,
    );
}
