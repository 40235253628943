import React from "react";

//Interface import
import { MonitorDBBlockProps } from "./monitor-detailed-bd-block-interface";

/**
 * MonitorDetailedBdBlockComponent is a functional component that displays a block of detailed breakdown data.
 *
 * @component
 * @param {MonitorDBBlockProps} props
 * @returns {React.JSX.Element}
 */
const MonitorDetailedBdBlockComponent = (
    props: MonitorDBBlockProps,
): React.JSX.Element => {
    return (
        <>
            {props.blockData.map((block) => (
                <React.Fragment key={block.id}>
                    <div className="grid grid-cols-5 table-data">
                        <div className="col-span-3 p-4 px-5 text-left mr-1 bg-white">
                            {block.category}
                        </div>
                        <div className="col-span-2 p-4 px-5 font-bold bg-white">
                            {block.text}
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};
export default MonitorDetailedBdBlockComponent;
