import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as types from "./offer.types";

export const initialState: types.InitialState = {
    offerData: {
        O_Group: "",
        indicators: [],
        O_Quitline: "",
        O_NRT_sold: "",
        O_Bup_sold: "",
        O_Varen_sold: "",
        O_NRT_EML: "",
        O_Varen_EML: ". . .",
        O_Cytisine_EML: ". . .",
        O_Bup_EML: ". . .",
        O_Cytisine_sold: "",
        O_Cytisine_where: "—",
        O_Cytisine_ins: "—",
        O_NRT_where: "",
        O_Bup_where: "",
        O_Varen_where: "",
        O_NRT_ins: "",
        O_BUP_ins: "",
        O_Varen_ins: "",
        O_Avail_hlth_clin: "",
        O_Avail_hosp: "",
        O_Avail_off_hlth_prof: "",
        O_Avail_community: "",
        O_Avail_other: "",
        O_Ins_hlth_clin: "",
        O_Ins_hosp: "",
        O_Ins_off_hlth_prof: "",
        O_Ins_community: "",
        O_Ins_other: "",
        offerPropData: [],
        offerstatusListData: [],
        offerstatusSupportData: [],
    },
};

const offerInfo = createSlice({
    name: "offerInfo",
    initialState,
    reducers: {
        getOfferInfo: (state, _action) => {
            return { ...state };
        },

        getOfferInfoSuccess: (
            state,
            action: PayloadAction<types.OfferDataInfoType>,
        ) => {
            return {
                ...state,
                offerData: action.payload,
            };
        },

        getOfferInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const { getOfferInfo, getOfferInfoSuccess, getOfferInfoError } =
    offerInfo.actions;

export default offerInfo.reducer;
