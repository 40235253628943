import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as types from "./enforce.types";

export const initialState: types.InitialState = {
    enforceData: {
        E_Group: "",
        indicators: [],
        E1_nat_tv_radio: true,
        E2_intl_tv_radio: true,
        E4_intl_print: true,
        E3_nat_print: true,
        E7_internet: true,
        E21a_direct_ad_fines: true,
        E5_billboards: true,
        E6a_pt_of_sale: true,
        E9_free_distrib: true,
        E10_promo_discounts: true,
        E11_brand_stretching: true,
        E12_brand_sharing: true,
        E13_brand_placement: true,
        E14a_prod_tv_films: true,
        E15b_sponsor_contribution: true,
        E15c_sponsor_publicity: true,
        E15a_sponsorship: true,
        E21b_indirect_ad_fines: true,
        E17a_csr_ban: true,
        E17b_csr_promo_self: true,
        E17c_csr_promo_others: true,
        E18_csr_anti_tobacco_media: true,
        E6b_ban_display_pt_of_sale: true,
        E23_vending_machines: true,
        E24_internet_sales_ban: true,
        E14b_movies_anti_tob_ads: true,
        year: "",
        enforcePropData: [],
        enforceStatusBlockPropData: [],
        enforceDBPropData: [],
    },
};

const enforceInfo = createSlice({
    name: "enforceInfo",
    initialState,
    reducers: {
        getEnforceInfo: (state, _action) => {
            return { ...state };
        },

        getEnforceInfoSuccess: (
            state,
            action: PayloadAction<types.EnforceDataInfoType>,
        ) => {
            return {
                ...state,
                enforceData: action.payload,
            };
        },

        getEnforceInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const { getEnforceInfo, getEnforceInfoSuccess, getEnforceInfoError } =
    enforceInfo.actions;

export default enforceInfo.reducer;
