// Components import
import CoverScoreKeyCardComponent from "../../../shared/components/cover-score-key-card/cover-score-key-card.component";
import RaiseTaxCurrentstatusDataViewComponent from "../raise-tax-current-status-data-view/raise-tax-current-status-data-view.component";

import { useSelector } from "react-redux";
import { raiseTaxInfoSelector } from "../../redux/raise-tax.selector";

/**
 * RaiseTaxCurrentStatusComponent is a functional component that displays current status information for raise tax page.
 * @component
 * @returns {React.JSX.Element}
 */

const RaiseTaxCurrentStatusComponent = (): React.JSX.Element => {
    const raiseTaxInfo = useSelector(raiseTaxInfoSelector);

    return (
        <div className="container mx-auto my-10">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    What has been achieved
                </h2>
            </div>
            <div className="grid grid-cols-3 justify-center mx-4 my-10">
                <div className="col-span-3 lg:col-span-1 lg:mr-8">
                    <p>
                        Countries are grouped according to the percentage
                        contribution of all tobacco taxes to the retail price of
                        a pack of 20 of the most popular brand of cigarettes.
                        Taxes assessed include excise tax, value added tax (or
                        sales taxes), import duty (when the cigarettes were
                        imported) and any other taxes levied.
                    </p>
                </div>
                <div className="col-span-3 lg:col-span-2 lg:ml-8">
                    <div className="current-status-diagram">
                        <div className="grid grid-cols-5 justify-center current-color-box mb-8">
                            <CoverScoreKeyCardComponent
                                scoreKeyCardData={
                                    raiseTaxInfo.raiseTaxCoverPropData
                                }
                            />
                        </div>
                    </div>
                    <RaiseTaxCurrentstatusDataViewComponent />
                </div>
            </div>
        </div>
    );
};
export default RaiseTaxCurrentStatusComponent;
