import { useSelector } from "react-redux";
import { enforceInfoSelector } from "../../redux/enforce.selector";

// Components imports
import EnforceCurrentStatusBlockComponent from "../enforce-current-status-block/enforce-current-status-block.component";

/**
 * EnforceCurrentstatusDataViewComponent is a functional component that displays status blocks and action buttons.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const EnforceCurrentstatusDataViewComponent = (): React.JSX.Element => {
    const enforceInfo = useSelector(enforceInfoSelector);

    return (
        <div className="rounded text-center border border-black p-8 mb-4">
            <EnforceCurrentStatusBlockComponent
                statusBlockData={enforceInfo.enforceStatusBlockPropData}
            />
        </div>
    );
};

export default EnforceCurrentstatusDataViewComponent;
