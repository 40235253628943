import { useEffect, useRef, useState } from "react";

// Components imports
import CountriesCompareBlockComponent from "../../countries-compare-block/countries-compare-block.component";

//Image or Icon or logo imports
import globalCircleIcon from "../../../../assets/icon/global-circle.png";

import {
    setCompareSearchData,
    getCountryCompareInfo,
    setIsCountryCompareNotFound,
} from "../redux/country-compare.slice";
import { useAppDispatch } from "../../../../redux/hooks";
import { useSelector } from "react-redux";
import { countryListSelector } from "../../../../country/redux/country.selector";
import {
    compareInputDataSelector,
    comparePolicySelector,
    isButtonClickedSelector,
    isCountryCompareNotFoundSelector,
} from "../redux/country-compare.selector";
import useCountryCompareData from "../redux/country-compare.utilities";
import {
    ERROR_MESSAGE_FOR_LESS_CHARACTER,
    KEYBOARD_ARROW_DOWN,
    KEYBOARD_ARROW_UP,
    KEYBOARD_ENTER,
    VALIDATION_MESSAGE,
} from "../../../constants/shared.contants";
import { scrollSuggestionIntoView } from "../../../utility/shared.utility";
import { CountryListInfoType } from "../../../../country/redux/country.types";
import { GRID_COLS_4, GRID_COLS_5 } from "../countries-comapre.constants";
import { toast } from "react-toastify";
import CountryCompareLoaderComponent from "./countries-compare-loader.component";

/**
 * CountriesCompareComponent is a functional component that allows users to compare selected countries
 * based on tobacco control requirements. Users can search for a country and categorize it into different
 * requirement categories.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const CountriesCompareComponent = (): React.JSX.Element => {
    const [suggestions, setSuggestions] = useState<CountryListInfoType[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const dispatch = useAppDispatch();
    const inputData = useSelector(compareInputDataSelector);
    const countryList = useSelector(countryListSelector);
    const policyNumber = useSelector(comparePolicySelector);
    const isCountryCompareNotFound = useSelector(
        isCountryCompareNotFoundSelector,
    );
    const comparePropData = [...useCountryCompareData(policyNumber)];
    const isButtonClicked = useSelector(isButtonClickedSelector);

    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleClick = () => {
        let foundCountry;
        if (inputData?.length <= 2) {
            toast.error(ERROR_MESSAGE_FOR_LESS_CHARACTER);
        }

        if (selectedIndex !== -1) {
            const selectedCountry = suggestions[selectedIndex];
            foundCountry = countryList?.find(
                (country: CountryListInfoType) =>
                    country?.normalizedTitle.toLowerCase() ===
                    selectedCountry?.normalizedTitle.toLowerCase(),
            );
        } else {
            foundCountry = countryList?.find(
                (country: CountryListInfoType) =>
                    country?.normalizedTitle?.toLowerCase() ===
                    inputData?.toLowerCase(),
            );
        }
        if (foundCountry) {
            dispatch(
                getCountryCompareInfo({
                    countryCode: foundCountry?.code.toLowerCase(),
                    policyNumber: policyNumber,
                }),
            );
            setSuggestions([]);
            dispatch(setCompareSearchData(foundCountry?.title));
        }
    };

    const checkAndDispatchCountryCompareNotFound = (suggestedCountries: []) => {
        if (suggestedCountries?.length === 0) {
            dispatch(setIsCountryCompareNotFound(true));
        } else {
            dispatch(setIsCountryCompareNotFound(false));
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const input = e.target.value;
        if (input.length >= 3) {
            const initialLetterMatch: any[] = [];
            const otherMatches: any[] = [];
            const uniqueCountries = new Set();

            countryList?.forEach((country: CountryListInfoType) => {
                const countryTitleLower =
                    country?.normalizedTitle?.toLowerCase();
                const inputLower = input?.toLowerCase();

                if (
                    countryTitleLower.startsWith(inputLower) &&
                    !uniqueCountries.has(countryTitleLower)
                ) {
                    initialLetterMatch.push(country);
                    uniqueCountries.add(countryTitleLower);
                } else if (
                    countryTitleLower.includes(inputLower) &&
                    !uniqueCountries.has(countryTitleLower)
                ) {
                    otherMatches.push(country);
                    uniqueCountries.add(countryTitleLower);
                }
            });

            const sortedInitialLetterMatch = [...initialLetterMatch].sort(
                (a, b) => a.normalizedTitle.localeCompare(b.normalizedTitle),
            );

            const sortedOtherMatches = [...otherMatches].sort((a, b) =>
                a.normalizedTitle.localeCompare(b.normalizedTitle),
            );

            const suggestedCountries: any = [
                ...sortedInitialLetterMatch,
                ...sortedOtherMatches,
            ];
            checkAndDispatchCountryCompareNotFound(suggestedCountries);
            setSuggestions(suggestedCountries);
        } else {
            setSuggestions([]);
            dispatch(setIsCountryCompareNotFound(false));
        }
        dispatch(setCompareSearchData(capitalizeFirstLetter(e.target.value)));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KEYBOARD_ENTER) {
            e.preventDefault();
            handleClick();
        } else if (e.key === KEYBOARD_ARROW_DOWN) {
            e.preventDefault();
            const nextIndex = Math.min(
                suggestions?.length - 1,
                selectedIndex + 1,
            );
            setSelectedIndex(nextIndex);
            scrollSuggestionIntoView(nextIndex);
        } else if (e.key === KEYBOARD_ARROW_UP) {
            e.preventDefault();
            const nextIndex = Math.max(0, selectedIndex - 1);
            setSelectedIndex(nextIndex);
            scrollSuggestionIntoView(nextIndex);
        }
    };

    const handleSuggestionClick = (selectedCountry: CountryListInfoType) => {
        dispatch(setCompareSearchData(selectedCountry?.normalizedTitle));
        setSuggestions([]);
        setSelectedIndex(-1);
        handleClick();
        if (inputRef?.current) {
            inputRef?.current.focus();
            inputRef.current.value = "";
            inputRef.current.value = selectedCountry.normalizedTitle;

            inputRef.current.setSelectionRange(
                selectedCountry.normalizedTitle.length,
                selectedCountry.normalizedTitle.length,
            );
            inputRef.current.scrollLeft = inputRef.current.scrollWidth;
        }
    };

    useEffect(() => {
        const clearInputValueOnRefresh = () => {
            dispatch(setCompareSearchData(""));
            dispatch(setIsCountryCompareNotFound(false));
        };

        window.addEventListener("beforeunload", clearInputValueOnRefresh);

        return () => {
            window.removeEventListener(
                "beforeunload",
                clearInputValueOnRefresh,
            );
        };
    }, [dispatch]);

    return (
        <div className="container mx-auto">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600" id="MPtext17">
                    How other countries compare
                </h2>
            </div>
            <div className="grid grid-cols-5 justify-center my-10 mx-4">
                <div className="col-span-5 lg:col-span-1">
                    <p id="MPtext18">
                        Use this panel to compare the selected country with
                        others. This information can be used to advocate for
                        improving the status of the measure in the country.
                    </p>

                    <form
                        data-testid={`compare-search-form`}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleClick();
                        }}
                    >
                        <div className="flex rounded-full overflow-hidden relative shadow shadow-md mt-10">
                            <input
                                ref={inputRef}
                                type="text"
                                value={inputData}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                className="w-full h-14 pl-6 text-base pr-3"
                                placeholder="Enter country..."
                                data-testid={`compare-search-input`}
                                disabled={isButtonClicked}
                            />
                            <button
                                type="submit"
                                data-testid={`compare-search-button`}
                                id="MPMonitorCtryBtn"
                            >
                                <img
                                    src={globalCircleIcon}
                                    alt="input search icon"
                                    className="right-1 top-1 h-12 w-12 absolute"
                                />
                            </button>
                        </div>
                        {isCountryCompareNotFound && (
                            <div className="validation-box">
                                {VALIDATION_MESSAGE}
                            </div>
                        )}
                    </form>
                    <div
                        className="suggestions compare-suggestion"
                        data-testid={`compare-search-suggestion-list`}
                    >
                        {suggestions?.length > 0 && (
                            <ul>
                                {suggestions.map((country, index) => (
                                    <li
                                        key={country?.code}
                                        id={`suggestion-${index}`}
                                        className={
                                            index === selectedIndex
                                                ? "selected"
                                                : ""
                                        }
                                        onClick={() =>
                                            handleSuggestionClick(country)
                                        }
                                        data-testid={`compare-search-suggestion-item`}
                                    >
                                        {country?.title}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <div className="col-span-5 lg:col-span-4 shadow-xl shadow-indigo-500/10 lg:ml-16 rounded-lg p-2 light-grey mt-5">
                    <CountryCompareLoaderComponent>
                        <div
                            className={
                                policyNumber === "0" ? GRID_COLS_4 : GRID_COLS_5
                            }
                        >
                            <CountriesCompareBlockComponent
                                compareData={comparePropData}
                            />
                        </div>
                    </CountryCompareLoaderComponent>
                </div>
            </div>
        </div>
    );
};

export default CountriesCompareComponent;
