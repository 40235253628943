import { useSelector } from "react-redux";
import { monitorInfoSelector } from "../../redux/monitor.selector";

// Components imports
import MonitorDetailedTableComponent from "../monitor-detailed-table/monitor-detailed-table.component";

//Interface import
import { MonitorDBPropType } from "./monitor-detailed-breakdown-interface";
import { getYesNoLabel } from "../../../shared/utility/get-yes-no-label.utility";

/**
 * MonitorDetailedBreakdownComponent is a functional component that displays a detailed breakdown data.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const MonitorDetailedBreakdownComponent = (): React.JSX.Element => {
    const monitorInfo = useSelector(monitorInfoSelector);

    const dbPropData: MonitorDBPropType[] = [
        {
            id: "01",
            tableHeading: "Most recent survey among adults",
            tablePropData: [
                {
                    id: "01",
                    category:
                        "The survey was carried out recently (in the past 5 years)",
                    text: getYesNoLabel(monitorInfo.Adult_svy_yr),
                },
                {
                    id: "02",
                    category:
                        "The survey was representative of the country’s adult population",
                    text: getYesNoLabel(monitorInfo.Adult_svy_national),
                },
                {
                    id: "03",
                    category: "The survey was periodic",
                    text: monitorInfo.Periodic_Adult,
                },
            ],
        },
        {
            id: "02",
            tableHeading: "Most recent survey among adolescents",
            tablePropData: [
                {
                    id: "01",
                    category:
                        "The survey was carried out recently (in the past 5 years)",
                    text: getYesNoLabel(monitorInfo.Youth_svy_yr),
                },
                {
                    id: "02",
                    category:
                        "The survey was representative of the country’s school-going adolescent population",
                    text: getYesNoLabel(monitorInfo.Youth_svy_national),
                },
                {
                    id: "03",
                    category: "The survey was periodic",
                    text: monitorInfo.Periodic_Youth,
                },
            ],
        },
    ];
    return (
        <div className="container mx-auto my-28">
            <div className="mx-4" id="MPtext13">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    Know your gaps
                </h2>
            </div>
            <div className="grid grid-cols-4 my-10 mx-4">
                <div className="col-span-4 lg:col-span-1 lg:mr-8" id="MPtext14">
                    Surveys were considered recent if conducted in the past 5
                    years. For this report, this means 2017 or later.
                    <br />
                    <br />
                    Surveys were considered representative only if a scientific
                    random sampling method was used to ensure nationally
                    representative results. (Although they provide useful
                    information, subnational surveys or national surveys of
                    specific population groups provide insufficient information
                    to enable tobacco control action for the total population.)
                    <br />
                    <br />
                    Surveys were considered periodic if the same survey or a
                    survey using the same or similar questions was run at least
                    once during the 5 year period prior to the most recent
                    survey.
                </div>
                <div className="col-span-4 lg:col-span-3">
                    <div className="grid grid-cols-2">
                        <MonitorDetailedTableComponent tableData={dbPropData} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MonitorDetailedBreakdownComponent;
