import { useAppDispatch } from "../redux/hooks";
import { useSelector } from "react-redux";
import { selectedCountrySelector } from "../country/redux/country.selector";
import { CountryInfoType } from "../country/redux/country.types";
import { useEffect } from "react";
import { genericActionDispatcher } from "../shared/redux/shared.slice";
import { showPageLoaderSelector } from "../shared/redux/shared.selector";

// Components imports
import HeaderComponent from "../shared/components/header/header.component";
import FooterComponent from "../shared/components/footer/footer.component";
import MonitorBodyComponent from "./components/monitor-body.component";
import LoaderComponent from "../shared/components/loader.component";

// Logo and image import
import mpowerLogoMonitor from "../assets/logo/mpower-logo-monitor.png";
import WhoBlueLogo from "../assets/logo/who-logo-blue.png";

//Interface import
import { HeaderPropType } from "../shared/components/header/header-interface";

/**
 * MonitorPage component represents the main page of the monitor section.
 * It includes a header, body, and footer components.
 * @function MonitorPage
 * @returns {React.JSX.Element}
 */

const headerPropData: HeaderPropType[] = [
    {
        id: "01",
        img: WhoBlueLogo,
        alt: "who-blue-logo",
        class: "whologo",
        url: "",
    },
    {
        id: "02",
        img: mpowerLogoMonitor,
        alt: "mpower-monitor-logo",
        class: "mpowerlogo",
        url: "/",
    },
];

const MonitorPage = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const selectedCountry: CountryInfoType = useSelector(
        selectedCountrySelector,
    );
    const showLoader = useSelector(showPageLoaderSelector);

    useEffect(() => {
        dispatch(
            genericActionDispatcher({
                selectedCountry: selectedCountry?.monitorBlockPropData?.name,
                policyData: "0",
            }),
        );
    }, []);

    return (
        <>
            {showLoader ? (
                <LoaderComponent></LoaderComponent>
            ) : (
                <>
                    <HeaderComponent imgData={headerPropData} />
                    <MonitorBodyComponent />
                    <FooterComponent />
                </>
            )}
        </>
    );
};

export default MonitorPage;
