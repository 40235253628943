import { MonitorCurrentStatusProptype } from "./monitor-current-status/monitor-current-status-interface";

export const monitorPropData: MonitorCurrentStatusProptype[] = [
    {
        id: "01",
        cardBgColor: "color-no-requirement",
        meets: false,
        lastExpanded: false,
    },
    {
        id: "02",
        cardBgColor: "color-minimal-requirement",
        meets: false,
        lastExpanded: false,
    },
    {
        id: "03",
        cardBgColor: "color-moderate-requirement",
        meets: false,
        lastExpanded: false,
    },
    {
        id: "04",
        cardBgColor: "color-complete-requirement",
        meets: false,
        lastExpanded: false,
    },
];

export const GRID_COLS_4_MONITOR = "grid grid-cols-4 monitor-current-color-box";
export const GRID_COLS_5_MONITOR = "grid grid-cols-5 current-color-box";
