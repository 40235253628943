/**
 * MonitorCurrentStatusDataCard is a functional component that displays information about the surveys.
 * @component
 * @returns {React.JSX.Element}
 */

//Interface import
import { MonitorCurrentStatusCardProps } from "./monitor-current-status-data-card-interface";

const MonitorCurrentStatusDataCard = (
    props: MonitorCurrentStatusCardProps,
): React.JSX.Element => {
    const { heading, yearText } = props;
    return (
        <div className="rounded border border-gray-300 p-4 bg-white flex justify-between flex-col">
            {heading}
            <div className="text-center bg-gray-100 self-center mt-4 mb-2 p-4 w-full">
                {yearText}
            </div>
        </div>
    );
};

export default MonitorCurrentStatusDataCard;
