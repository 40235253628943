import FooterComponent from "../shared/components/footer/footer.component";
import BodySectionComponent from "./components/body-section.component";
import HeaderComponent from "../shared/components/header/header.component";
import { useEffect } from "react";
import {
    getCountryListInfo,
    setIsCountryNotFound,
    setSearchData,
} from "../country/redux/country.slice";
import { useAppDispatch } from "../redux/hooks";

/**
 * The HomePage component represents the main page of the application.
 * @function HomePage
 * @returns {React.JSX.Element}
 */

const HomePage = (): React.JSX.Element => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getCountryListInfo());
        dispatch(setSearchData(""));
        dispatch(setIsCountryNotFound(false));
    }, []);

    return (
        <>
            <HeaderComponent />
            <BodySectionComponent />
            <FooterComponent />
        </>
    );
};

export default HomePage;
