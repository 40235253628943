import { useSelector } from "react-redux";
import { enforceInfoSelector } from "../../redux/enforce.selector";
import { useEffect, useState } from "react";

// Components imports
import ButtonComponent from "../../../shared/components/button/button.component";
import DetailedBreakdownTableHeaderComponent from "../../../shared/components/detailed-breakdown-table-header.component";
import NoDataMessageComponent from "../../../shared/components/no-data-message/no-data-message.component";

//Interface import
import { EnforceDetailedBreakdownPropType } from "./enforce-detailed-breakdown-interface";

/**
 * EnforceDetailedBreakdownComponent is a functional component that displays a detailed breakdown data.
 *
 * @component

 * @returns {React.JSX.Element}
 */

const EnforceDetailedBreakdownComponent = (): React.JSX.Element => {
    const enforceInfo = useSelector(enforceInfoSelector);

    const [propData, setPropData] = useState<
        EnforceDetailedBreakdownPropType[]
    >([]);
    const [improvedClass, setImprovedClass] = useState("bg-yellow");
    const [achievedClass, setAchievedClass] = useState("bg-primary-border");

    useEffect(() => {
        const initialData = enforceInfo?.enforceDBPropData.filter(
            (data) => !data?.meets,
        );
        setPropData(initialData);
    }, [enforceInfo?.enforceDBPropData]);

    const setImprovePropData = () => {
        const newData = enforceInfo?.enforceDBPropData.filter(
            (data) => !data?.meets,
        );
        setPropData(newData);
        setImprovedClass("bg-yellow");
        setAchievedClass("bg-primary-border");
    };

    const setAchievedPropData = () => {
        const newData = enforceInfo?.enforceDBPropData.filter(
            (data) => data?.meets,
        );
        setPropData(newData);
        setImprovedClass("bg-primary-border");
        setAchievedClass("bg-yellow");
    };

    return (
        <div className="container mx-auto my-28">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    Know your gaps
                </h2>
            </div>
            <div className="grid grid-cols-4 my-10 mx-4 enforce-details">
                <div className="col-span-4 lg:col-span-1 lg:mr-4 xl:mr-8">
                    <p>
                        Click on 'What has been achieved' and see the types of
                        bans on tobacco advertising, promotion and sponsorships
                        that have already been achieved in your country.
                        <br />
                        <br />
                        Click on 'Characteristics to improve' to see the policy
                        gaps in your countryand the characteristics that have
                        not reached best-practice yet.
                    </p>
                    <div className="flex flex-col gap-2 p-2 button-box shadow-inner mr-4 mt-8">
                        <div onClick={setImprovePropData}>
                            <ButtonComponent
                                contentElement={
                                    <div>Characteristics to improve</div>
                                }
                                variantClass={improvedClass}
                            />
                        </div>
                        <div onClick={setAchievedPropData}>
                            <ButtonComponent
                                contentElement={
                                    <div>What has been achieved</div>
                                }
                                variantClass={achievedClass}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-4 lg:col-span-3 border rounded lg:ml-8 detailed-table">
                    <div className="grid grid-cols-7 flex items-center justify-center overflow-hidden rounded-t-lg table-head">
                        <div className="col-span-6 text-white">
                            <div>
                                Ban on tobacco advertising, promotion and
                                sponsorship
                            </div>
                        </div>
                        <div className="grid grid-cols-1 text-center text-white enforce-year">
                            <DetailedBreakdownTableHeaderComponent
                                text={enforceInfo?.year}
                            />
                        </div>
                    </div>

                    <ul>
                        {propData?.length === 0 ? (
                            <NoDataMessageComponent />
                        ) : (
                            propData.map((item) => (
                                <li key={item?.id}>
                                    <div className="grid grid-cols-7 justify-center">
                                        <div className="col-span-6 bg-white mr-1 p-4 table-data">
                                            {item?.heading}
                                        </div>
                                        <div className="col-span-1 bg-white mr-1 p-4 table-data font-bold text-center">
                                            {item?.result}
                                        </div>
                                    </div>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default EnforceDetailedBreakdownComponent;
