/**
 * The CarouselSectionOne component represents the first slide of a carousel.
 * @function CarouselSectionOneComponent
 * @param {CarouselSectionOneProps} props
 * @returns {React.JSX.Element}
 */

// Components import
import CountrySearchMainComponent from "../country-search/country-search-main.component";

// Interface import
import { ICarouselSectionOneProps } from "./carousel-section-one-interface";

const CarouselSectionOneComponent: React.FC<ICarouselSectionOneProps> = ({
    onNextClick,
    onFocus,
    onBlur,
}) => {
    return (
        <div className="text-center p-4">
            <h2 className="text-6xl mb-2 hp-heading-color carousel-one" id="txtpath01">
                The pathway to a tobacco-free world
            </h2>
            <div className="h-0.5 hp-hr-bgcolor m-4 mr-10 ml-10"></div>
            <p className="text-xl text-black sub-head font-extrabold mb-2">
                An interactive guide to the 2023 global tobacco epidemic report.
            </p>
            <p className="text-lg text-black">
                This tool aims to help you identify the tobacco control
                achievements and gaps in countries. Please use this tool to
                explore the next steps toward stronger tobacco control and
                reducing the use of tobacco.
            </p>

            <CountrySearchMainComponent
                onNextClick={() => {
                    onNextClick();
                }}
                buttonText={"Click for background information"}
                onBlur={() => {
                    onBlur();
                }}
                onFocus={() => {
                    onFocus();
                }}
                id="carousbtn01"
            />
        </div>
    );
};

export default CarouselSectionOneComponent;
