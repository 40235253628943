// Components imports
import SuccessStoryComponent from "../../../shared/components/success-story/success-story.component";

import { ENFORCE_SUCCESS_PROP_DATA } from "../../../shared/constants/shared.contants";

/**
 * EnforceSuccessStoryComponent is a functional component that displays a success story section with a description and an image.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const EnforceSuccessStoryComponent = (): React.JSX.Element => {
    return <SuccessStoryComponent successData={ENFORCE_SUCCESS_PROP_DATA} />;
};

export default EnforceSuccessStoryComponent;
