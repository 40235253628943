// Redux import
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const monitorInfoState = (state: RootState) => {
    return state.monitorInfo;
};

export const monitorInfoSelector = createSelector(
    [monitorInfoState],
    (monitorInfoState) => {
        return monitorInfoState?.monitorData;
    },
);
