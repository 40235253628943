//Image or Icon or logo imports
import { useSelector } from "react-redux";
import upArrow from "../../../assets/icon/up-arrow.png";
import downArrow from "../../../assets/icon/down-arrow.png";
import noChange from "../../../assets/icon/no-change.png";
import na from "../../../assets/icon/n-a.png";
import { raiseTaxInfoSelector } from "../../redux/raise-tax.selector";
import { useEffect, useState } from "react";

/**
 * EnforceCurrentStatusBlockComponent is a functional component that displays a list of status blocks.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const RaiseTaxCurrentStatusBlockComponent = (): React.JSX.Element => {
    const raiseTaxInfo = useSelector(raiseTaxInfoSelector);

    const [imageSrc, setImageSrc] = useState<string | undefined>();
    const [imageAlt, setImageAlt] = useState<string | undefined>("");
    const [descriptortext, setDescriptortext] = useState<string | undefined>(
        "",
    );

    const totalTax = parseFloat(raiseTaxInfo.R_Total_tax_estimate).toFixed(1);

    useEffect(() => {
        switch (raiseTaxInfo.R_afford_cat) {
            case "Yes":
                setImageSrc(upArrow);
                setImageAlt("up-arrow");
                setDescriptortext(
                    `Cigarettes less affordable – per capita GDP needed to buy 2000 cigarettes of the most sold brand increased on average between 2012 and 2022`,
                );
                break;
            case "No":
                setImageSrc(downArrow);
                setImageAlt("down-arrow");
                setDescriptortext(
                    `Cigarettes more affordable – per capita GDP needed to buy 2000 cigarettes of the most sold brand declined on average between 2012 and 2022`,
                );
                break;
            case "No change":
                setImageSrc(noChange);
                setImageAlt("no-change");
                setDescriptortext(
                    `No trend change in affordability of cigarettes between 2012 and 2022`,
                );
                break;
            case "—":
                setImageSrc(na);
                setImageAlt("not-applicable");
                break;
        }
    }, [raiseTaxInfo.R_afford_cat]);
    return (
        <div className="grid grid-cols-2 justify-center border border-gray-200 shadow rounded bg-white mt-8 p-6">
            <div className="col-span-2 lg:col-span-1 border rounded shadow border-gray-300 p-10 text-left my-10 lg:mr-16 connected-line">
                <p>
                    Share of total taxes in the retail price of the most widely
                    sold brand of cigarettes
                </p>
            </div>
            {raiseTaxInfo.R_Group === "1" ? (
                <div className="col-span-2 lg:col-span-1 bg-gray-100 p-8 flex items-center justify-center gap-5">
                    <div className="font-bold text-center">N/A</div>
                </div>
            ) : (
                <div className="col-span-2 lg:col-span-1 bg-gray-100 p-7 flex justify-center xl:justify-end flex-col gap-7">
                    <div className="flex items-center gap-7">
                        <img
                            src={imageSrc}
                            alt={imageAlt}
                            className="w-16 h-16"
                        />
                        <div>
                            <p className="font-black text-xl xl:text-6xl text-indigo-900">
                                {totalTax}%
                            </p>
                        </div>
                    </div>
                    <div className="text-neutral-400 text-xs text-left font-bold">
                        <em>{descriptortext}</em>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RaiseTaxCurrentStatusBlockComponent;
