import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as types from "./warn-mass-media.types";

export const initialState: types.InitialState = {
    massMediaData: {
        "W-MM_Group": "",
        indicators: [
            {
                code: "",
                title: "",
            },
        ],
        Camp_nat: true,
        Camp_tv_radio: true,
        Camp_gov_prog: true,
        Camp_mat_tested: true,
        Camp_aud_research: true,
        Camp_media_planning: true,
        Camp_news: true,
        Camp_media_monitor: true,
        Camp_eval_impact: true,
        year: "",
        massMediaCoverPropData: [],
        massMediaCurrentPropData: [],
        massMediaDBPropData: [],
    },
};

const massMediaInfo = createSlice({
    name: "massMediaInfo",
    initialState,
    reducers: {
        getMassMediaInfo: (state, _action) => {
            return { ...state };
        },

        getMassMediaInfoSuccess: (
            state,
            action: PayloadAction<types.MassMediaDataInfoType>,
        ) => {
            return {
                ...state,
                massMediaData: action.payload,
            };
        },

        getMassMediaInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    getMassMediaInfo,
    getMassMediaInfoSuccess,
    getMassMediaInfoError,
} = massMediaInfo.actions;

export default massMediaInfo.reducer;
