import React from "react";

//Image or Icon or logo imports
import checkTick from "../../../assets/icon/check-box.png";
import emptyCheckBox from "../../../assets/icon/empty-check-box.png";

//Interface import
import { WarnMassStatusBlockProps } from "./warn-mass-current-status-block-interface";

/**
 * WarnMassCurrentStatusBlockComponent is a functional component that displays a list of status blocks.
 *
 * @component
 * @param {WarnMassStatusBlockProps} props
 * @returns {React.JSX.Element}
 */

const WarnMassCurrentStatusBlockComponent = (
    props: WarnMassStatusBlockProps,
): React.JSX.Element => {
    return (
        <div className="grid md:grid-cols-2 gap-2 mt-8 justify-center block-support">
            {props.statusBlockData.map((block) => (
                <React.Fragment key={block.id}>
                    <div
                        className="col-span-1 flex justify-between items-center border border-gray-300 rounded shadow"
                        data-testid={`protect-status-block`}
                    >
                        <img
                            src={block.img}
                            alt={block.alt}
                            className="w-24 h-24"
                        />
                        <div className="flex justify-between w-full bg-gray-100 p-2 pr-4">
                            <p className={block.result ? "font-bold" : ""}>
                                {block.title}
                            </p>
                            <img
                                src={block.result ? checkTick : emptyCheckBox}
                                alt={
                                    block.result
                                        ? "check-tick"
                                        : "empty-check-box"
                                }
                                className="w-8 h-8 self-center"
                            />
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default WarnMassCurrentStatusBlockComponent;
