import { useSelector } from "react-redux";

import { massMediaInfoSelector } from "../../redux/warn-mass-media.selector";

// Components imports
import WarnMassCurrentStatusBlockComponent from "../warn-mass-current-status-block/warn-mass-current-status-block.component";

/**
 * WarnMassCurrentstatusDataViewComponent is a functional component that displays status blocks and action buttons.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const WarnMassCurrentstatusDataViewComponent = (): React.JSX.Element => {
    const massMediaInfo = useSelector(massMediaInfoSelector);

    return (
        <div className="rounded-lg text-center border border-black p-8 mb-4">
            <WarnMassCurrentStatusBlockComponent
                statusBlockData={massMediaInfo.massMediaCurrentPropData}
            />
        </div>
    );
};

export default WarnMassCurrentstatusDataViewComponent;
