import { useSelector } from "react-redux";
import { monitorInfoSelector } from "../../redux/monitor.selector";

// Components import
import CoverScoreKeyCardComponent from "../../../shared/components/cover-score-key-card/cover-score-key-card.component";
import MonitorCurrentstatusDataViewComponent from "../monitor-current-status-data-view/monitor-current-status-data-view.component";
import { comparePolicySelector } from "../../../shared/components/countries-compare/redux/country-compare.selector";
import { GRID_COLS_4_MONITOR, GRID_COLS_5_MONITOR } from "../monitor.constants";

/**
 * MonitorCurrentStatusComponent is a functional component that displays current status information.
 * @component
 * @returns {React.JSX.Element}
 */

const MonitorCurrentStatusComponent = (): React.JSX.Element => {
    const monitorInfo = useSelector(monitorInfoSelector);
    let policyNumber = useSelector(comparePolicySelector);

    return (
        <div className="container mx-auto mt-10">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600" id="MPtext10">
                    What has been achieved
                </h2>
            </div>
            <div className="grid grid-cols-3 justify-center my-10 mx-4 current-status">
                <div className="col-span-3 lg:col-span-1 lg:mr-8" id="MPtext11">
                    <p>
                        The strength of a national tobacco surveillance system
                        is assessed by the frequency and periodicity of
                        nationally representative surveys among the adult and
                        adolescent population in the country.
                    </p>
                </div>
                <div className="col-span-3 lg:col-span-2 lg:ml-8">
                    <div className="current-status-diagram">
                        <div
                            className={`justify-center mt-10 mb-8 ${
                                policyNumber
                                    ? GRID_COLS_4_MONITOR
                                    : GRID_COLS_5_MONITOR
                            }`}
                        >
                            <CoverScoreKeyCardComponent
                                scoreKeyCardData={monitorInfo.monitorPropData}
                                divid="BlockExpand"
                            />
                        </div>
                    </div>
                    <MonitorCurrentstatusDataViewComponent />
                </div>
            </div>
        </div>
    );
};
export default MonitorCurrentStatusComponent;
