/**
 * ButtonComponent is a functional component that renders a button with a link to an external URL.
 *
 * @component
 * @returns {React.JSX.Element}
 */

import { Link } from "react-router-dom";
import { ButtonPropType } from "./button-interface";

const ButtonComponent = (props: ButtonPropType): React.JSX.Element => {
    return (
        <Link
            to={props.url ? props.url : ""}
            className="text-sm"
            data-testid={`read-button`}
            target={props.target ? props.target : ""}
        >
            <button
                className={`${props.variantClass} py-4 px-16 rounded-full md:ml-4`}
                id={props.id}
            >
                {props.contentElement}
            </button>
        </Link>
    );
};
export default ButtonComponent;
