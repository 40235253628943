import "./App.css";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import CountryPage from "./country/country.page";
import HomePage from "./home/home.page";
import MonitorPage from "./monitor/monitor.page";
import ProtectPage from "./protect/protect.page";
import OfferPage from "./offer/offer.page";
import WarnMassMediaPage from "./warn-mass-media/warn-mass-media.page";
import WarnPackagePage from "./warn-package/warn-package.page";
import EnforcePage from "./enforce/enforce.page";
import RaiseTaxPage from "./raise-tax/raise-tax.page";
import ScrollToTop from "./scrollToTop";
import ConnectionLostMessage from "./connection-lost-message";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CLARITY_TOKEN, DETECTING_TIME_INTERVAL } from "./shared/constants/config";
import { clarity } from 'react-microsoft-clarity';

const App = () => {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        const checkInternetAccess = async () => {
            try {
                await fetch("https://www.google.com", { mode: "no-cors" });
                setIsOnline(true);
            } catch (error) {
                setIsOnline(false);
            }
        };

        checkInternetAccess();
        clarity.init(''+CLARITY_TOKEN);
        const interval = setInterval(
            checkInternetAccess,
            DETECTING_TIME_INTERVAL,
        );

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {isOnline ? (
                <>
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/country" element={<CountryPage />} />
                        <Route path="/monitor" element={<MonitorPage />} />
                        <Route path="/protect" element={<ProtectPage />} />
                        <Route path="/offer" element={<OfferPage />} />
                        <Route
                            path="/massmedia"
                            element={<WarnMassMediaPage />}
                        />
                        <Route
                            path="/warnpackage"
                            element={<WarnPackagePage />}
                        />
                        <Route path="/enforce" element={<EnforcePage />} />
                        <Route path="/raisetax" element={<RaiseTaxPage />} />
                    </Routes>
                    <ToastContainer />
                </>
            ) : (
                <ConnectionLostMessage />
            )}
        </>
    );
};

export default App;
