import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../shared/components/rest-handler/rest-handler.service";
import * as MASSMEDIAINFO_SLICE from "./warn-mass-media.slice";
import { getProccessedMassMediaInfo } from "./warn-mass-media.utilities";

export function* getMassMediaInfo(action: any): any {
    let url = `/WarnMassMedia?countryCode=${action?.payload || ""}`;
    try {
        let massMediaInfo = yield call(RestHandlerService.get, url, {});

        massMediaInfo = getProccessedMassMediaInfo(massMediaInfo);

        yield put(MASSMEDIAINFO_SLICE.getMassMediaInfoSuccess(massMediaInfo));
    } catch (err) {
        yield put(MASSMEDIAINFO_SLICE.getMassMediaInfoError());
    }
}

export function* massMediaInfoSaga() {
    yield takeLatest("massMediaInfo/getMassMediaInfo", getMassMediaInfo);
}
