import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    DATA_NOT_REPORTED,
    NO_CRITERIA_ACHIEVED,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
} from "../../../constants/shared.contants";

import {
    completeRequirementsInfoSelector,
    minimalRequirementsInfoSelector,
    moderateRequirementsInfoSelector,
    noRequirementInfoSelector,
    notCategorizedInfoSelector,
} from "../redux/country-compare.selector";
import { ComparePropData } from "../components/countries-compare-interface";
import {
    DUPLICATE_COUNTRY_MESSAGE,
    ERROR_MESSAGE,
    LIST_SELECTOR,
} from "../countries-comapre.constants";
import { ErrorMessages, IValidItem } from "./country-compare.types";

export const useCountryCompareData = (policyNumber: string) => {
    const [processedData, setProcessedData] = useState<ComparePropData[]>([]);

    const notCategorizedInfo = useSelector(notCategorizedInfoSelector);
    const noRequirementInfo = useSelector(noRequirementInfoSelector);
    const minimalRequirementsInfo = useSelector(
        minimalRequirementsInfoSelector,
    );
    const moderateRequirementsInfo = useSelector(
        moderateRequirementsInfoSelector,
    );
    const completeRequirementsInfo = useSelector(
        completeRequirementsInfoSelector,
    );

    const getCategoryData = (
        paraText: string,
        infoArray: string[],
        sliceStart: number,
        category: string,
        cardBgColor: string,
        id: string,
    ) => {
        return {
            id: id,
            paraText,
            countryList: Array.isArray(infoArray)
                ? Array.from(
                      new Set<string>(
                          infoArray?.slice(sliceStart, sliceStart + 5),
                      ),
                  )
                : [],
            category,
            cardBgColor,
        };
    };

    useEffect(() => {
        let data;
        if (policyNumber === "0") {
            data = [
                getCategoryData(
                    NO_CRITERIA_ACHIEVED,
                    noRequirementInfo,
                    0,
                    "noRequirementCountryList",
                    BG_COLOR_NO_REQUIREMENT,
                    "01",
                ),
                getCategoryData(
                    STATUS_MINIMAL,
                    minimalRequirementsInfo,
                    0,
                    "minimalRequirementsCountryList",
                    BG_COLOR_MINIMAL,
                    "02",
                ),
                getCategoryData(
                    STATUS_MODERATE,
                    moderateRequirementsInfo,
                    0,
                    "moderateRequirementsCountryList",
                    BG_COLOR_MODERATE,
                    "03",
                ),
                getCategoryData(
                    STATUS_COMPLETE,
                    completeRequirementsInfo,
                    0,
                    "completeRequirementsCountryList",
                    BG_COLOR_COMPLETE,
                    "04",
                ),
            ];
        } else {
            data = [
                getCategoryData(
                    DATA_NOT_REPORTED,
                    notCategorizedInfo,
                    0,
                    "notCategorizedCountryList",
                    BG_COLOR_NO_DATA,
                    "01",
                ),
                getCategoryData(
                    NO_CRITERIA_ACHIEVED,
                    noRequirementInfo,
                    0,
                    "noRequirementCountryList",
                    BG_COLOR_NO_REQUIREMENT,
                    "02",
                ),
                getCategoryData(
                    STATUS_MINIMAL,
                    minimalRequirementsInfo,
                    0,
                    "minimalRequirementsCountryList",
                    BG_COLOR_MINIMAL,
                    "03",
                ),
                getCategoryData(
                    STATUS_MODERATE,
                    moderateRequirementsInfo,
                    0,
                    "moderateRequirementsCountryList",
                    BG_COLOR_MODERATE,
                    "04",
                ),
                getCategoryData(
                    STATUS_COMPLETE,
                    completeRequirementsInfo,
                    0,
                    "completeRequirementsCountryList",
                    BG_COLOR_COMPLETE,
                    "05",
                ),
            ];
        }

        setProcessedData(data);
    }, [
        notCategorizedInfo,
        noRequirementInfo,
        minimalRequirementsInfo,
        moderateRequirementsInfo,
        completeRequirementsInfo,
    ]);

    return processedData;
};
export default useCountryCompareData;

const listSelectors: Record<number, string> = LIST_SELECTOR;

export const isZeroPolicy = (_policyNumber: string): boolean =>
    _policyNumber === "0";

export const getCategoryCountryList = (
    _policyNumber: string,
    _countryCategory: number,
    _countryCompareInfo: any,
) => {
    let _categoryCountryList: string[] = isZeroPolicy(_policyNumber)
        ? _countryCompareInfo[listSelectors[_countryCategory + 1]]
        : _countryCompareInfo[listSelectors[_countryCategory]];

    return _categoryCountryList;
};

export const isValidItem = (
    _categoryCountryList: string[],
    formattedInputData: string,
): IValidItem => {
    const result: IValidItem = {
        isDuplicateCountry: false,
        limitExeed: false,
        isItemValid: true,
    };

    result.isDuplicateCountry = _categoryCountryList.every(
        (item: any) => item !== formattedInputData,
    );

    if (_categoryCountryList.length >= 5) result.limitExeed = true;
    result.isItemValid = !(!result.isDuplicateCountry || result.limitExeed);
    return result;
};

export const getErrorMessage = (
    _policyNumber: string,
    _countryCategory: number,
    _validationResult: IValidItem,
) => {
    const errorMessages: ErrorMessages = ERROR_MESSAGE;
    if (!_validationResult.isDuplicateCountry) {
        return DUPLICATE_COUNTRY_MESSAGE;
    } else if (_validationResult.limitExeed) {
        return isZeroPolicy(_policyNumber)
            ? errorMessages[_policyNumber]?.[_countryCategory]
            : errorMessages["common"]?.[_countryCategory];
    }
};
