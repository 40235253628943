import { createSlice } from "@reduxjs/toolkit";
import * as types from "./shared.types";

export const initialState: types.InitialState = {
    carousel: { currentIndex: 0 },
    showPageLoader: false,
};

const sharedSlice = createSlice({
    name: "shared",
    initialState,
    reducers: {
        setCarouselCurrentIndex: (state, _action) => {
            let newCarousel = {
                ...state.carousel,
                ...{ currentIndex: _action.payload },
            };

            return { ...state, ...{ carousel: newCarousel } };
        },
        setShowPageLoader: (state, action) => {
            return { ...state, showPageLoader: action.payload };
        },

        genericActionDispatcher: (state, _action) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    setCarouselCurrentIndex,
    setShowPageLoader,

    genericActionDispatcher,
} = sharedSlice.actions;

export default sharedSlice.reducer;
