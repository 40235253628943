// Components imports
import CoverLeftCardComponent from "../cover-left-card/cover-left-card.component";
import CoverRightCardComponent from "../cover-right-card/cover-right-card.component";

//Interface import
import { CoverCardViewProps } from "./cover-card-view-interface";

/**
 * CoverCardViewComponent is a functional component that displays a grid with two card components: CoverLeftCardComponent and CoverRightCardComponent.
 *
 * @component
 * @param {CoverCardViewProps} props
 * @returns {React.JSX.Element}
 */

const CoverCardViewComponent = (
    props: CoverCardViewProps,
): React.JSX.Element => {
    return (
        <div className="grid grid-cols-2 justify-center text-center">
            <CoverLeftCardComponent
                coverLeftCardData={props.coverCardViewData}
                coverLeft="Lefttext"
            />
            <CoverRightCardComponent
                coverRightCardData={props.coverCardViewData}
                CoverRight="Righttext"
            />
        </div>
    );
};

export default CoverCardViewComponent;
