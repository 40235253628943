import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_DATA,
    STATUS_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";
import { useSelector } from "react-redux";
import { offerInfoSelector } from "../../redux/offer.selector";

// Components imports
import CountryNameComponent from "../../../country/components/country-name/country-name.component";
import MpowerScoreColorKeyComponent from "../../../country/components/mpower-score-key/mpower-score-key.component";
import CoverCardViewComponent from "../../../shared/components/cover-card-view/cover-card-view.component";

//Image or Icon or logo imports
import OfferImage from "../../../assets/icon/o-offer.png";

//Interface Import
import { CoverCardViewPropType } from "../../../shared/components/cover-card-view/cover-card-view-interface";
import TechnicalNoteLinkComponent from "../../../shared/components/technical-note-link-component";

/**
 * OfferCoverComponent is a functional component that displays Treatment of tobacco dependence information.
 * It includes a country name component, a cover card view component, and an Mpower score color key component.
 * @component
 * @returns {React.JSX.Element}
 */

const OfferCoverComponent = (): React.JSX.Element => {
    const offerInfo = useSelector(offerInfoSelector);

    let bgColor, statusText;

    switch (offerInfo.O_Group) {
        case "1":
            bgColor = BG_COLOR_NO_DATA;
            statusText = STATUS_NO_DATA;
            break;
        case "2":
            bgColor = BG_COLOR_NO_REQUIREMENT;
            statusText = STATUS_NO_REQUIREMENT;
            break;
        case "3":
            bgColor = BG_COLOR_MINIMAL;
            statusText = STATUS_MINIMAL;
            break;
        case "4":
            bgColor = BG_COLOR_MODERATE;
            statusText = STATUS_MODERATE;
            break;
        case "5":
            bgColor = BG_COLOR_COMPLETE;
            statusText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    const coverCardPropData: CoverCardViewPropType = {
        img: OfferImage,
        alt: "offer-Image",
        headingText: "Offer help to quit tobacco use",
        statusText: statusText,
        coverCardPropData: offerInfo.offerPropData,
        bgColor: bgColor,
        rightCardText: `Article 14 of the WHO FCTC states: “Each Party shall … take effective measures to promote cessation of tobacco use and adequate treatment for tobacco dependence…. Each Party shall … design and implement effective programmes aimed at promoting the cessation of tobacco use”.`,
    };

    return (
        <div className="container mx-auto">
            <CountryNameComponent
                contentElement={"Return to country results"}
                url={"/country"}
            />
            <CoverCardViewComponent coverCardViewData={coverCardPropData} />
            <MpowerScoreColorKeyComponent
                headingText={
                    <div>
                        The groupings for the offer to help quit tobacco-use
                        indicator are listed here. For more information on the
                        criteria for each measure please see the Technical Note:{" "}
                        <TechnicalNoteLinkComponent />
                    </div>
                }
                measureName="OFFER"
            />
        </div>
    );
};

export default OfferCoverComponent;
