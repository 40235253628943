//Component imports
import React from "react";
import ProgressColorBlockComponent from "../progress-color-block/progress-color-block.component";

//interface import
import { ProgressColorBlockProps } from "./progress-color-block-div-interface";
import { getUniqueKey } from "../../../shared/utility/shared.utility";

/**
 * ProgressColorBlockDivComponent component for rendering a set of color blocks.
 *
 * @Component
 * @param {ProgressColorBlockProps} props
 * @returns {React.JSX.Element}
 */

const ProgressColorBlockDivComponent = (
    props: ProgressColorBlockProps,
): React.JSX.Element => {
    return (
        <div className="grid grid-cols-11 my-2">
            <div className="col-span-2 flex border border-black mr-5 initial-block">
                <div className="rounded-full bg-slate-800 w-8 h-8 m-2 text-white flex justify-center items-center">
                    {props?.colorData?.nameInitial}
                </div>
                <div className="self-center text-left">
                    <p>{props?.colorData?.nameFinal}</p>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                >
                    <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z" />
                </svg>
            </div>
            {props?.colorData?.colorPalette?.map((colorPaletteData) => (
                <React.Fragment key={getUniqueKey()}>
                    <ProgressColorBlockComponent
                        colorClass={colorPaletteData}
                        nameFinal={props?.colorData?.nameFinal}
                    />
                </React.Fragment>
            ))}
        </div>
    );
};

export default ProgressColorBlockDivComponent;
