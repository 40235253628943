/**
 * The CarouselSectionTwo component represents the second slide of a carousel.
 * @function CarouselSectionTwoComponent
 * @param {CarouselSectionTwoProps} props
 * @returns {React.JSX.Element}
 */

// Components import
import { FCTC_BASE_URL } from "../../../shared/constants/url.constants";
import CountrySearchMainComponent from "../country-search/country-search-main.component";

// Interface import
import { ICarouselSectionTwoProps } from "./carousel-section-two-interface";

const CarouselSectionTwoComponent: React.FC<ICarouselSectionTwoProps> = ({
    onNextClick,
    onBlur,
    onFocus,
}) => {
    return (
        <div className="text-center py-4 pb-20">
            <h5 className="text-4xl text-center mb-2 hp-heading-color" id="txt07" >
                What is <span className="head-medium">MPOWER</span>?
            </h5>
            <p>
                MPOWER is a technical package of demand-reduction measures to
                help countries implement the{" "}
                <a
                    href={FCTC_BASE_URL}
                    target="_blank"
                    className="text-blue-500 font-bold"
                    rel="noopener noreferrer"
                >
                    WHO Framework Convention on Tobacco Control
                </a>{" "}
                and reduce tobacco use globally.
            </p>
            <div className="grid grid-cols-12 mt-8 mb-6 justify-center items-center text-center">
                <div className="col-span-12 sm:col-span-6 lg:col-span-4 shadow-md shadow-slate-200 m-2" id="txt01">
                    <h6 className="text-white text-lg hp-hr-bgcolor head-light" >
                        <span className="head-medium">M </span>– Monitor
                    </h6>
                    <p className="text-base card-content p-2 carousel-two">
                        Monitor tobacco use and prevention policies
                    </p>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-4 shadow-md shadow-slate-200 m-2" id="txt02">
                    <h6 className="text-white text-lg hp-hr-bgcolor head-light">
                        <span className="head-medium">P </span>– Protect
                    </h6>
                    <p className="text-base card-content">
                        Protect people from tobacco smoke
                    </p>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-4 shadow-md shadow-slate-200 m-2" id="txt03">
                    <h6 className="text-white text-lg hp-hr-bgcolor head-light">
                        <span className="head-medium">O </span>– Offer
                    </h6>
                    <p className="text-base card-content">
                        Offer help to quit tobacco use
                    </p>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-4 shadow-md shadow-slate-200 m-2" id="txt04">
                    <h6 className="text-white text-lg hp-hr-bgcolor head-light">
                        <span className="head-medium">W </span>– Warn
                    </h6>
                    <p className="text-base card-content p-2">
                        Warn about the dangers of tobacco
                    </p>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-4 shadow-md shadow-slate-200 m-2" id="txt05">
                    <h6 className="text-white text-lg hp-hr-bgcolor head-light">
                        <span className="head-medium">E </span>– Enforce
                    </h6>
                    <p className="text-base card-content">
                        Enforce bans on tobacco advertising, promotion and
                        sponsorship
                    </p>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-4 shadow-md shadow-slate-200 m-2" id="txt06">
                    <h6 className="text-white text-lg hp-hr-bgcolor head-light">
                        <span className="head-medium">R </span>– Raise
                    </h6>
                    <p className="text-base card-content">
                        Raise taxes on tobacco
                    </p>
                </div>
            </div>

            <CountrySearchMainComponent
                onNextClick={() => {
                    onNextClick();
                }}
                buttonText={"Click for background information"}
                onBlur={() => {
                    onBlur();
                }}
                onFocus={() => {
                    onFocus();
                }}
                id="carousbtn02"
            />
        </div>
    );
};

export default CarouselSectionTwoComponent;
