// Components imports
import SuccessStoryComponent from "../../../shared/components/success-story/success-story.component";

import { PROTECT_SUCCESS_PROP_DATA } from "../../../shared/constants/shared.contants";

/**
 * ProtectSuccessStoryComponent is a functional component that displays a success story section with a description and an image.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const ProtectSuccessStoryComponent = () => {
    return <SuccessStoryComponent successData={PROTECT_SUCCESS_PROP_DATA} />;
};

export default ProtectSuccessStoryComponent;
