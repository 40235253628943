// Components imports
import CountriesRegionComponent from "../../shared/components/countries-compare-region/countries-compare-region.component";
import CountriesCompareComponent from "../../shared/components/countries-compare/components/countries-compare.component";
import HelpfullResourcesComponent from "../../shared/components/helpful-resources/components/helpful-resources.component";
import SharedActionComponent from "../../shared/components/shared-action/shared-action.component";
import WarnMassCoverComponent from "./warn-mass-cover/warn-mass-cover.component";
import WarnMassCurrentStatusComponent from "./warn-mass-current-status/warn-mass-current-status.component";
import WarnMassSuccessStoryComponent from "./warn-mass-success-story/warn-mass-success-story.component";
import WarnMassMediaDetailedBreakdownComponent from "./warn-mass-media-detailed-breakdown/warn-mass-media-detailed-breakdown.component";

/**
 * WarnMassMediaBodyComponent is responsible for rendering the main body section of the mass media page.
 * @function MonitorBodyComponent
 * @returns {React.JSX.Element}
 */

const WarnMassMediaBodyComponent = (): React.JSX.Element => {
    return (
        <div className="warn-massmedia-page">
            <WarnMassCoverComponent />
            <WarnMassCurrentStatusComponent />
            <WarnMassMediaDetailedBreakdownComponent />
            <CountriesRegionComponent measureName="MASS MEDIA" />
            <CountriesCompareComponent />
            <WarnMassSuccessStoryComponent />
            <SharedActionComponent />
            <HelpfullResourcesComponent />
        </div>
    );
};

export default WarnMassMediaBodyComponent;
