import { API_KEY_VALUE, BASE_URL } from "../../constants/config";

class RestHandlerService {
    get = async (url: string, options: any) => {
        url = BASE_URL + url;

        options.headers = options.headers || {};

        options.headers["x-api-key"] = API_KEY_VALUE;
        options.headers["Content-Type"] = "application/json";

        try {
            let res = await fetch(url, options);
            return await res.json();
        } catch (error) {
            return error;
        }
    };
}

const RestHandlerInstance = new RestHandlerService();

export default RestHandlerInstance;
