import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as types from "./protect.types";

export const initialState: types.InitialState = {
    protectData: {
        P_Group: "",
        indicators: [],
        P1_healthcare: true,
        P2_education: true,
        P3_universities: true,
        P4_govt: true,
        P5_indoor_off: true,
        P6_restaurants: false,
        P7_pubs: false,
        P8_pub_transp: true,
        P11_fines_any: true,
        P12_fines_establishment: true,
        P13_fines_smoker: true,
        P14_funds_for_enforce: true,
        P15_complaints: true,
        protectDBPropData: [],
        protectPropData: [],
    },
};

const protectInfo = createSlice({
    name: "protectInfo",
    initialState,
    reducers: {
        getProtectInfo: (state, _action) => {
            return { ...state };
        },

        getProtectInfoSuccess: (
            state,
            action: PayloadAction<types.ProtectDataInfoType>,
        ) => {
            return {
                ...state,
                protectData: action.payload,
            };
        },

        getProtectInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const { getProtectInfo, getProtectInfoSuccess, getProtectInfoError } =
    protectInfo.actions;

export default protectInfo.reducer;
