import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../shared/components/rest-handler/rest-handler.service";
import * as ENFORCEINFO_SLICE from "./enforce.slice";
import { getProccessedEnforceInfo } from "./enforce.utilities";

export function* getEnforceInfo(action: any): any {
    let url = `/Enforce?countryCode=${action?.payload || ""}`;
    try {
        let enforceInfo = yield call(RestHandlerService.get, url, {});

        enforceInfo = getProccessedEnforceInfo(enforceInfo);
        yield put(ENFORCEINFO_SLICE.getEnforceInfoSuccess(enforceInfo));
    } catch (err) {
        yield put(ENFORCEINFO_SLICE.getEnforceInfoError());
    }
}

export function* enforceInfoSaga() {
    yield takeLatest("enforceInfo/getEnforceInfo", getEnforceInfo);
}
