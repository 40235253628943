import "../../styles.css";
import "../../responsive.css";
import ActionsComponent from "./actions.component";
import HomeSliderComponent from "./home-slider-component";
import React from "react";

/**
 * The BodySection component represents the main content section of the homepage.
 * @function BodySectionComponent
 * @extends {React.Component}
 */

const BodySectionComponent: React.FC = () => {
    return (
        <div className="grid grid-cols-10 md:grid-cols-10 hp-bg">
            <div className="col-span-1 lg:col-span-2 sm:col-span-1"></div>
            <div className="col-span-10 lg:col-span-6 sm:col-span-8 p-4 xl:p-6 shadow-lg mt-28 mb-10 shadow-slate-200 bg-slate-100">
                <HomeSliderComponent />
            </div>
            <ActionsComponent />
        </div>
    );
};

export default BodySectionComponent;
