import _ from "lodash";

import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MODERATE,
    TEXT_BLACK,
    TEXT_WHITE,
} from "../constants/shared.contants";

export const scrollSuggestionIntoView = (index: number) => {
    const suggestionElement = document.getElementById(`suggestion-${index}`);
    if (suggestionElement) {
        suggestionElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
        });
    }
};

export const getUniqueKey = () => {
    const array = new Uint32Array(1);
    const randomNumber = crypto.getRandomValues(array);
    return randomNumber[0];
};

export const getTextColorClass = (bgColor: string | undefined): string => {
    if (bgColor === BG_COLOR_COMPLETE || bgColor === BG_COLOR_MODERATE) {
        return TEXT_WHITE;
    } else {
        return TEXT_BLACK;
    }
};

export const normalizeString = (str: string) => _.deburr(str);
