import { useState } from "react";

// Components imports
import MpowerScoreCard from "../mpower-score-card/mpower-score-card.component";

//Image or Icon or logo imports
import DropDownIcon from "../../../assets/icon/drop-down.png";
import CrossIcon from "../../../assets/icon/cross.png";
import { useSelector } from "react-redux";
import { mpowerScoreKeyDataSelector } from "../../redux/country.selector";

/**
 * MpowerScoreColorKeyComponent displays the MPOWER score color key.
 * It includes a collapsible section that shows different color-coded score cards.
 * @function MpowerScoreColorKeyComponent
 * @returns {React.JSX.Element}
 */

const MpowerScoreColorKeyComponent = (props: {
    expandData?: boolean;
    headingText?: React.ReactNode;
    footNoteText?: React.ReactNode;
    measureName: string;
    id?:string;
}): React.JSX.Element => {
    const { expandData, headingText, footNoteText, measureName,id} = props;
    const [expanded, setExpanded] = useState(expandData ?? false);

    const mpowerScoreKeyData = useSelector(mpowerScoreKeyDataSelector);

    const toggleExpansion = () => {
        setExpanded((prevState) => !prevState);
    };

    let indexValue;
    mpowerScoreKeyData?.forEach((scoreKeyData, index) => {
        if (scoreKeyData.lowestLevel === true) {
            indexValue = index + 1;
        }
    });

    let styleClassForLowerText: string;
    let styleClassForUpperText: string;
    if (indexValue === 2) {
        styleClassForLowerText = "dottedline";
        styleClassForUpperText = "style-class-for-monitor";
    } else {
        styleClassForLowerText = "defaultline";
        styleClassForUpperText = "";
    }

    return (
        <div className="grid grid-cols-12 p-4">
            <div
                className={`flex justify-between lg:justify-start col-span-12 items-center color-key-block ${
                    expanded ? "expanded" : "collapsed" 
                }`}
            >
                <p className="font-bold pr-4 pb-2" id={`${id}-MptxtKey`}>
                    {measureName} score colour key
                </p>
                <button
                    onClick={toggleExpansion}
                    className="text-blue-500 rounded-full bg-slate-800 p-1.5"
                    id={`${id}-Mpbtn`}
                >
                    <img
                        src={expanded ? CrossIcon : DropDownIcon}
                        alt={expanded ? "Cross-Icon" : "Drop-Down-Icon"}
                        className="w-6 h-6"
                    />
                </button>
            </div>
            {expanded && (
                <div className="col-span-12 justify-center items-center mt-4 color-def-block">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 lg:col-span-12 pr-8" id="MPinfoText">
                            {headingText}
                        </div>
                        <div className="col-span-12 lg:col-span-12 color-key-diagram">
                            <div className="py-8">
                                <div
                                    className={`flex justify-center gap-x-12 ${styleClassForLowerText}`}
                                >
                                    <MpowerScoreCard
                                        cardData={mpowerScoreKeyData}
                                    />
                                </div>
                                <div className="flex justify-between items-center pt-10">
                                    <div
                                        className={`flex pl-20 h-6 rounded w-2/10 text-center lowertext ${styleClassForLowerText}`}
                                    >
                                        <p>Lowest level</p>
                                    </div>
                                    <div
                                        className={`flex xl:pr-20 h-6 rounded w-2/10 text-center ${styleClassForUpperText}`}
                                    >
                                        <p>Best practice</p>
                                    </div>
                                </div>
                                {footNoteText}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MpowerScoreColorKeyComponent;
