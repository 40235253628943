import { ThreeDots } from "react-loader-spinner";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { ShowCountriesCompareLoaderSelector } from "../redux/country-compare.selector";

interface LoaderPropType {
    children?: ReactElement;
}

const CountryCompareLoaderComponent = (props: LoaderPropType) => {
    const { children } = props;
    const showLoader = useSelector(ShowCountriesCompareLoaderSelector);
    return (
        <div style={{ position: "relative" }}>
            {children}
            {showLoader && (
                <div className="country-compare-loader">
                    <ThreeDots
                        color="#0086ad"
                        height={60}
                        width={60}
                        radius={6}
                        ariaLabel="three-dots-loading"
                        visible={true}
                    />
                </div>
            )}
        </div>
    );
};

export default CountryCompareLoaderComponent;
