// Components import
import CountryNameComponent from "../../../country/components/country-name/country-name.component";
import MpowerScoreColorKeyComponent from "../../../country/components/mpower-score-key/mpower-score-key.component";
import CoverCardViewComponent from "../../../shared/components/cover-card-view/cover-card-view.component";

//Interface import
import { CoverCardViewPropType } from "../../../shared/components/cover-card-view/cover-card-view-interface";

//Image or Icon or logo imports
import RaiseTaxImage from "../../../assets/icon/raise-tax.png";
import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_DATA,
    STATUS_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";
import { useSelector } from "react-redux";
import { raiseTaxInfoSelector } from "../../redux/raise-tax.selector";
import TechnicalNoteLinkComponent from "../../../shared/components/technical-note-link-component";

/**
 * RaiseTaxCoverComponent is a functional component that displays Taxation data.
 * @component
 * @returns {React.JSX.Element}
 */

const RaiseTaxCoverComponent = (): React.JSX.Element => {
    const raiseTaxInfo = useSelector(raiseTaxInfoSelector);

    let bgColor, statusText;

    switch (raiseTaxInfo.R_Group) {
        case "1":
            bgColor = BG_COLOR_NO_DATA;
            statusText = STATUS_NO_DATA;
            break;
        case "2":
            bgColor = BG_COLOR_NO_REQUIREMENT;
            statusText = STATUS_NO_REQUIREMENT;
            break;
        case "3":
            bgColor = BG_COLOR_MINIMAL;
            statusText = STATUS_MINIMAL;
            break;
        case "4":
            bgColor = BG_COLOR_MODERATE;
            statusText = STATUS_MODERATE;
            break;
        case "5":
            bgColor = BG_COLOR_COMPLETE;
            statusText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    const coverCardPropData: CoverCardViewPropType = {
        img: RaiseTaxImage,
        alt: "raise-tax",
        headingText: "Raise taxes on tobacco",
        statusText: statusText,
        coverCardPropData: raiseTaxInfo.raiseTaxCoverPropData,
        bgColor: bgColor,
        rightCardText: `Article 6 of the WHO FCTC states: “...[P]rice and tax measures are an effective and important means of reducing tobacco consumption...[Parties should adopt]...measures which may include:...tax policies and...price policies on tobacco products so as to contribute to the health objectives aimed at reducing tobacco consumption”`,
    };

    return (
        <div className="container mx-auto">
            <CountryNameComponent
                contentElement={"Return to country results"}
                url={"/country"}
            />
            <CoverCardViewComponent coverCardViewData={coverCardPropData} />
            <MpowerScoreColorKeyComponent
                headingText={
                    <div>
                        The groupings for the tobacco tax indicator are listed
                        here. For more information on the criteria for each
                        measure please see the Technical Note:{" "}
                        <TechnicalNoteLinkComponent />
                    </div>
                }
                measureName="RAISE TAXES"
            />
        </div>
    );
};

export default RaiseTaxCoverComponent;
