import { useEffect, useState } from "react";
import { massMediaInfoSelector } from "../../redux/warn-mass-media.selector";

// Components imports
import { useSelector } from "react-redux";
import ButtonComponent from "../../../shared/components/button/button.component";
import DetailedBreakdownTableHeaderComponent from "../../../shared/components/detailed-breakdown-table-header.component";
import NoDataMessageComponent from "../../../shared/components/no-data-message/no-data-message.component";

//Interface import
import { WarnMassMediaDetailedBreakdownPropType } from "./warn-mass-media-detailed-breakdown-interface";

/**
 * WarnMassMediaDetailedBreakdownComponent is a functional component that displays a detailed breakdown data.
 *
 * @component

 * @returns {React.JSX.Element}
 */

const WarnMassMediaDetailedBreakdownComponent = (): React.JSX.Element => {
    const massMediaInfo = useSelector(massMediaInfoSelector);

    const [propData, setPropData] = useState<
        WarnMassMediaDetailedBreakdownPropType[]
    >([]);
    const [improvedClass, setImprovedClass] = useState("bg-yellow");
    const [achievedClass, setAchievedClass] = useState("bg-primary-border");

    useEffect(() => {
        const initialData = massMediaInfo?.massMediaDBPropData?.filter(
            (data) => !data?.meets,
        );
        setPropData(initialData);
    }, [massMediaInfo?.massMediaDBPropData]);

    const setImprovePropData = () => {
        const newData = massMediaInfo?.massMediaDBPropData?.filter(
            (data) => !data?.meets,
        );
        setPropData(newData);
        setImprovedClass("bg-yellow");
        setAchievedClass("bg-primary-border");
    };

    const setAchievedPropData = () => {
        const newData = massMediaInfo?.massMediaDBPropData?.filter(
            (data) => data?.meets,
        );
        setPropData(newData);
        setImprovedClass("bg-primary-border");
        setAchievedClass("bg-yellow");
    };

    return (
        <div className="container mx-auto my-16">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    Know your gaps
                </h2>
            </div>
            <div className="grid grid-cols-4 my-10 mx-4">
                <div className="col-span-4 lg:col-span-1 lg:mr-4 xl:mr-8">
                    <p>
                        Click on 'What has been achieved' and see the
                        characteristics of health warnings on cigarettes
                        packages that have already been achieved in your
                        country.
                        <br />
                        <br />
                        Click on 'Characteristics to improve' to see the policy
                        gaps in your country and the characteristics that have
                        not reached best-practice yet.
                    </p>
                    <div className="flex flex-col gap-2 p-2 button-box shadow-inner mr-4 mt-8">
                        <div onClick={setImprovePropData}>
                            <ButtonComponent
                                contentElement={
                                    <div>Characteristics to improve</div>
                                }
                                variantClass={improvedClass}
                            />
                        </div>

                        <div onClick={setAchievedPropData}>
                            <ButtonComponent
                                contentElement={
                                    <div>What has been achieved</div>
                                }
                                variantClass={achievedClass}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-4 lg:col-span-3 lg:ml-8 detailed-table">
                    <div className="detailed-whole-table">
                        <div>
                            <div className="grid grid-cols-4 justify-center overflow-hidden">
                                <div className="col-span-3"></div>
                                <div className="col-span-1 grid grid-cols-1 justify-center table-head text-center rounded-t-lg text-white">
                                    <DetailedBreakdownTableHeaderComponent
                                        text={massMediaInfo?.year}
                                    />
                                </div>
                            </div>

                            <ul className="shadow rounded-lg">
                                {propData?.length === 0 ? (
                                    <NoDataMessageComponent />
                                ) : (
                                    propData?.map((item) => (
                                        <li key={item?.id}>
                                            <div className="grid grid-cols-4 justify-center">
                                                <div className="col-span-3 mr-1 p-4 table-data bg-white">
                                                    {item?.heading}
                                                </div>
                                                <div className="col-span-1 p-4 bg-white table-data font-bold text-center">
                                                    {item?.text}
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WarnMassMediaDetailedBreakdownComponent;
