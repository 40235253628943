import { useSelector } from "react-redux";
import { warnPackageInfoSelector } from "../../redux/warn-package.selector";

// Components imports
import WarnMassCurrentStatusBlockComponent from "../warn-package-current-status-block/warn-package-current-status-block.component";

//Image or Icon or logo imports
import exclamationMarkImg from "../../../assets/icon/exclamation-mark.png";

/**
 * WarnProtectCurrentstatusDataViewComponent is a functional component that displays status blocks and action buttons.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const WarnProtectCurrentstatusDataViewComponent = (): React.JSX.Element => {
    const warnPackageInfo = useSelector(warnPackageInfoSelector);

    return (
        <div className="rounded-lg text-center border border-black p-4 lg:p-8 mb-4">
            <div className="lg:flex justify-center items-center gap-32 mx-2 lg:mx-4 bg-white mt-10 p-10 warning-box">
                <div className="warning-img">
                    <img
                        src={exclamationMarkImg}
                        alt="exclamation-mark"
                        className="w-31 h-32 m-auto"
                    />
                </div>
                <div className="border border-gray-300 p-4 rounded shadow warning-content">
                    <p className="lg:mx-8 mb-4">
                        Average warning size of front and back of the package is
                    </p>
                    <div className="bg-gray-100 rounded">
                        {warnPackageInfo.W2_pc_front_back_A}%
                    </div>
                </div>
            </div>
            <WarnMassCurrentStatusBlockComponent
                statusBlockData={warnPackageInfo.warnPackageCurrentPropData}
            />
        </div>
    );
};

export default WarnProtectCurrentstatusDataViewComponent;
