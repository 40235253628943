import { useSelector } from "react-redux";

// Components imports
import CoverScoreKeyCardComponent from "../cover-score-key-card/cover-score-key-card.component";

//Interface import
import { CoverLeftCardProps } from "./cover-left-card-interface";
import { comparePolicySelector } from "../countries-compare/redux/country-compare.selector";
import { getTextColorClass } from "../../utility/shared.utility";

import {
    GRID_COLS_4_MONITOR_COVER_LEFT,
    GRID_COLS_5_MONITOR_COVER_LEFT,
} from "../../constants/shared.contants";

/**
 * CoverLeftCardComponent is a functional component that displays a card with the left portion of the cover card view, ehich consists of the header, current status and color key block.
 *
 * @component
 * @param {CoverLeftCardProps} props
 * @returns {React.JSX.Element}
 */

const CoverLeftCardComponent = (
    props: CoverLeftCardProps,
): React.JSX.Element => {
    let policyNumber = useSelector(comparePolicySelector);

    return (
        <div className="block col-span-2 lg:col-span-1 bg-white m-4 cover-left">
            <div className="rounded p-8">
                <p className="text-2xl sm:text-4xl text-slate-950 p-4 md:p-8" id={`${props.coverLeft}-CoverLeftP1`}>
                    {props?.coverLeftCardData?.headingText}
                </p>
                <div
                    className={`${props?.coverLeftCardData?.bgColor} h-32 flex justify-center items-center p-4 rounded`}
                    id={`${props.coverLeft}-CoverLeftP2`}
                >
                    <p
                        className={`sm:text-2xl ${getTextColorClass(
                            props?.coverLeftCardData?.bgColor,
                        )}`}
                    >
                        <span className="head-light">Current status: </span>
                        <span className="sub-head">
                            {props?.coverLeftCardData?.statusText}
                        </span>
                    </p>
                    <div className="dotted"></div>
                </div>
            </div>
            <div className="cover-left-diagram">
                <div
                    className={`justify-center mt-10 ${
                        policyNumber === "0"
                            ? GRID_COLS_4_MONITOR_COVER_LEFT
                            : GRID_COLS_5_MONITOR_COVER_LEFT
                    }`}
                >
                    <CoverScoreKeyCardComponent
                        scoreKeyCardData={
                            props?.coverLeftCardData?.coverCardPropData
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default CoverLeftCardComponent;
