import React from "react";
import {
    blockDataSelector,
    progressDataSelector,
} from "../../redux/country.selector";
import { useSelector } from "react-redux";

//Component Imports
import MpowerScoreColorKeyComponent from "../mpower-score-key/mpower-score-key.component";
import ProgressColorBlockDivComponent from "../progress-color-block-div/progress-color-block-div.component";
import ProgressYearBlockComponent from "../progress-year-block/progress-year-block.component";
import TechnicalNoteLinkComponent from "../../../shared/components/technical-note-link-component";

//Constants
import { yearData } from "./mpower-progress.constants";

/**
 * MpowerProgressMainComponent component for rendering the MPOWER progress data from 2007 to 2020.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const MpowerProgressMainComponent = (): React.JSX.Element => {
    const blockData = useSelector(blockDataSelector);
    const progressData = useSelector(progressDataSelector);

    return (
        <>
            {Object.keys(progressData)?.length === 0 ? (
                <div></div>
            ) : (
                <>
                    <div className="m-6">
                        <h2 className="text-4xl hp-heading-color border-b border-cyan-600" id="MPtext_13">
                            Progress from 2007 to 2022
                        </h2>
                    </div>
                    <div className="m-6" id="MPtext_14">
                        <p>
                            WHO analyses MPOWER status of countries every two
                            years. Data are re-analysed in each biennial report,
                            and revisions are made when necessary. For more
                            information on the criteria for each measure please
                            see the Technical Note:{" "}
                            <TechnicalNoteLinkComponent />
                        </p>
                    </div>
                    <div className="progress-block-section mx-4">
                        <div className="color-key-block mt-10">
                            <MpowerScoreColorKeyComponent
                                expandData={true}
                                headingText={
                                    <div>
                                        The{" "}
                                        <span className="font-bold italic">
                                            method
                                        </span>{" "}
                                        used to determine the MPOWER score is
                                        described in the Technical Note I :{" "}
                                        <TechnicalNoteLinkComponent />
                                    </div>
                                }
                                measureName="MPOWER"
                                id="MPtextkey"
                            />
                        </div>
                        <div className="rounded progress-color-section text-center m-6 progress-color-diagram">
                            <div>
                                <div className="grid grid-cols-11">
                                    <div className="col-span-2"></div>
                                    <ProgressYearBlockComponent
                                        yearData={yearData}
                                    />
                                </div>
                                {blockData?.map((blockData) => (
                                    <React.Fragment key={blockData?.id}>
                                        <ProgressColorBlockDivComponent
                                            colorData={blockData}
                                        />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default MpowerProgressMainComponent;
