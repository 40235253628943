const HeaderImageComponent = ({
    imageSrc,
    imageAlt,
    imageClass,
}: {
    imageSrc?: string;
    imageAlt?: string;
    imageClass?: string;
}) => {
    return (
        <img src={imageSrc} alt={imageAlt} className={`w-48 ${imageClass}`} />
    );
};

export default HeaderImageComponent;
