import { useSelector } from "react-redux";
import { offerInfoSelector } from "../../redux/offer.selector";

// Components imports
import DetailedBreakdownTableHeaderComponent from "../../../shared/components/detailed-breakdown-table-header.component";

//Interface import
import { OfferDetailedBreakdownPropType } from "./offer-detailed-breakdown-interface";

/**
 * OfferDetailedBreakdownComponent is a functional component that displays a detailed breakdown data.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const OfferDetailedBreakdownComponent = (): React.JSX.Element => {
    const offerInfo = useSelector(offerInfoSelector);

    const OfferDataFirst: OfferDetailedBreakdownPropType[] = [
        {
            id: "01",
            heading: "Is this product legally sold in the country?",
            nicotineBlockText: offerInfo.O_NRT_sold,
            bupropionBlockText: offerInfo.O_Bup_sold,
            vareniclineBlockText: offerInfo.O_Varen_sold,
            cytisineBlockText: offerInfo.O_Cytisine_sold,
        },
        {
            id: "02",
            heading:
                "Where and how can this product be legally purchased in your country?",
            nicotineBlockText: offerInfo.O_NRT_where,
            bupropionBlockText: offerInfo.O_Bup_where,
            vareniclineBlockText: offerInfo.O_Varen_where,
            cytisineBlockText: offerInfo.O_Cytisine_where,
        },
        {
            id: "03",
            heading:
                "Does the national/federal health insurance or the national health service cover the cost of this product?",
            nicotineBlockText: offerInfo.O_NRT_ins,
            bupropionBlockText: offerInfo.O_BUP_ins,
            vareniclineBlockText: offerInfo.O_Varen_ins,
            cytisineBlockText: offerInfo.O_Cytisine_ins,
        },
        {
            id: "04",
            heading: "Is this product on the country’s essential drugs list?",
            nicotineBlockText: offerInfo.O_NRT_EML,
            bupropionBlockText: offerInfo.O_Bup_EML,
            vareniclineBlockText: offerInfo.O_Varen_EML,
            cytisineBlockText: offerInfo.O_Cytisine_EML,
        },
    ];

    const OfferDataSecond: OfferDetailedBreakdownPropType[] = [
        {
            id: "01",
            heading:
                "Is smoking cessation support available in the following places  in your country?",
            healthClinicsBlockText: offerInfo.O_Avail_hlth_clin,
            hospitalsBlockText: offerInfo.O_Avail_hosp,
            officeBlockText: offerInfo.O_Avail_off_hlth_prof,
            communityBlockText: offerInfo.O_Avail_community,
            otherBlockText: offerInfo.O_Avail_other,
        },
        {
            id: "02",
            heading:
                "Does the national/ federal health insurance or the national health service cover the cost  of this support?",
            healthClinicsBlockText: offerInfo.O_Ins_hlth_clin,
            hospitalsBlockText: offerInfo.O_Ins_hosp,
            officeBlockText: offerInfo.O_Ins_off_hlth_prof,
            communityBlockText: offerInfo.O_Ins_community,
            otherBlockText: offerInfo.O_Ins_other,
        },
    ];

    return (
        <div className="container mx-auto">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    Know your gaps
                </h2>
            </div>
            <div className="grid grid-cols-4 my-16 mx-4">
                <div className="col-span-4 xl:col-span-1 xl:mr-8 mb-4">
                    <p>
                        These tables provide details of the services available
                        and those that are not or that can be strengthened.
                    </p>
                </div>

                <div className="col-span-4 xl:col-span-3 xl:ml-8 detailed-table">
                    <div className="detailed-whole-table">
                        <div>
                            <div className="grid grid-cols-12 justify-center overflow-hidden">
                                <div className="col-span-4"></div>
                                <div className="col-span-8 grid grid-cols-4 table-head text-center rounded-t-lg bg-black text-white ">
                                    <DetailedBreakdownTableHeaderComponent
                                        text={"Nicotine  replacement therapy"}
                                    />
                                    <DetailedBreakdownTableHeaderComponent
                                        text={"Bupropion"}
                                    />
                                    <DetailedBreakdownTableHeaderComponent
                                        text={"Varenicline"}
                                    />
                                    <DetailedBreakdownTableHeaderComponent
                                        text={"Cytisine"}
                                    />
                                </div>
                            </div>

                            <ul>
                                {OfferDataFirst.filter(
                                    (item) =>
                                        item !== null && item !== undefined,
                                ).map((item) => (
                                    <li key={item?.id}>
                                        <div className="grid grid-cols-12 justify-center">
                                            <div className="col-span-4 bg-white mr-1 p-4 table-data">
                                                {item?.heading}
                                            </div>
                                            <div className="col-span-2 bg-white mr-1 p-4 table-data font-bold text-center">
                                                {item?.nicotineBlockText}
                                            </div>
                                            <div className="col-span-2 bg-white mr-1 p-4 table-data font-bold text-center">
                                                {item?.bupropionBlockText}
                                            </div>
                                            <div className="col-span-2 bg-white mr-1 p-4 table-data font-bold text-center">
                                                {item?.vareniclineBlockText}
                                            </div>
                                            <div className="col-span-2 bg-white p-4 table-data font-bold text-center">
                                                {item?.cytisineBlockText}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="detailed-whole-table">
                        <div>
                            <div className="grid grid-cols-7 justify-center overflow-hidden mt-16">
                                <div className="col-span-2"></div>
                                <div className="col-span-5 grid grid-cols-5 table-head text-center rounded-t-lg bg-black text-white">
                                    <DetailedBreakdownTableHeaderComponent
                                        text={
                                            "Health clinics  or other primary care facilities"
                                        }
                                    />
                                    <DetailedBreakdownTableHeaderComponent
                                        text={"Hospitals "}
                                    />
                                    <DetailedBreakdownTableHeaderComponent
                                        text={"Office of a health professional"}
                                    />
                                    <DetailedBreakdownTableHeaderComponent
                                        text={"In the community"}
                                    />
                                    <DetailedBreakdownTableHeaderComponent
                                        text={"Other"}
                                    />
                                </div>
                            </div>

                            <ul>
                                {OfferDataSecond.filter(
                                    (item) =>
                                        item !== null && item !== undefined,
                                ).map((item) => (
                                    <li key={item?.id}>
                                        <div className="grid grid-cols-7 justify-center">
                                            <div className="col-span-2 bg-white mr-1 p-4 table-data">
                                                {item?.heading}
                                            </div>
                                            <div className="col-span-1 bg-white mr-1 p-4 table-data font-bold text-center">
                                                {item?.healthClinicsBlockText}
                                            </div>
                                            <div className="col-span-1 bg-white mr-1 p-4 table-data font-bold text-center">
                                                {item?.hospitalsBlockText}
                                            </div>
                                            <div className="col-span-1 bg-white mr-1 p-4 table-data font-bold text-center">
                                                {item?.officeBlockText}
                                            </div>
                                            <div className="col-span-1 bg-white mr-1 p-4 table-data font-bold text-center">
                                                {item?.communityBlockText}
                                            </div>
                                            <div className="col-span-1 bg-white p-4 table-data font-bold text-center">
                                                {item?.otherBlockText}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="detailed-whole-table">
                        <div>
                            <ul>
                                <li>
                                    <div className="grid grid-cols-7 justify-center overflow-hidden mt-16">
                                        <div className="col-span-2 bg-white mr-1 p-4 table-data">
                                            Is there a toll-free telephone quit
                                            line/help line with a live person
                                            available to discuss cessation with
                                            callers?
                                        </div>
                                        <div className="col-span-5 bg-white mr-1 p-4 table-data font-bold text-center flex items-center justify-center">
                                            {offerInfo.O_Quitline}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OfferDetailedBreakdownComponent;
