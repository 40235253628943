import { useSelector } from "react-redux";

// Components imports
import HelpfullResourcesBlockComponent from "../../helpfull-resources-block/helpfull-resources-block.component";
import ButtonComponent from "../../button/button.component";

import {
    INITIATIVES_MPOWER_URL,
    IRIS_REPORT_URL,
} from "../../../constants/url.constants";

import { helpfulResourcesDataSelector } from "../redux/helpful-resources.selector";

/**
 * HelpfullResourcesComponent is a functional component that displays helpful resources.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const HelpfullResourcesComponent = (): React.JSX.Element => {
    const helpfulResourcesData = useSelector(helpfulResourcesDataSelector);

    return (
        <div className="container mx-auto helpful-resources my-16">
            <div className="mx-4">
                <p className="hp-heading-color pb-2 text-4xl border-b border-cyan-600" id="MPtext21">
                    Helpful resources
                </p>
            </div>
            <div className="grid grid-cols-4 my-12 mx-4">
                <div className="col-span-4 lg:col-span-1">
                    <p id="MPtext22">
                        There is plenty more information for you to explore if
                        you would like to learn more about warnings on tobacco
                        packaging. You can find some useful links here, or visit
                        the WHO website using the link below to continue your
                        journey.
                    </p>
                    <div className="mt-10">
                        <ButtonComponent
                            contentElement={
                                <div>
                                    Learn more about{" "}
                                    <span className="font-semibold">
                                        mpower
                                    </span>
                                </div>
                            }
                            variantClass={"bg-primary"}
                            url={INITIATIVES_MPOWER_URL}
                            target={"_blank"}
                            id="MPHelpbtn01"
                        />
                        <ButtonComponent
                            contentElement={
                                <div>
                                    Read the full{" "}
                                    <span className="font-semibold">
                                        mpower
                                    </span>{" "}
                                    report
                                </div>
                            }
                            variantClass={"bg-primary"}
                            url={IRIS_REPORT_URL}
                            target={"_blank"}
                            id="MPHelpbtn02"
                        />
                    </div>
                </div>
                <div className="col-span-4 lg:col-span-3 m-2">
                    <div className="grid grid-cols-1 gap-6 justify-center lg:ml-16">
                        <HelpfullResourcesBlockComponent
                            blockData={helpfulResourcesData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HelpfullResourcesComponent;
