import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as types from "./monitor.types";

export const initialState: types.InitialState = {
    monitorData: {
        M_Group: "",
        Adult_svy_yr: true,
        Youth_svy_yr: true,
        Adult_svy_national: true,
        Youth_svy_national: true,
        Periodic_Adult: "",
        Periodic_Youth: "",
        Adult_svy_age_range: 0,
        Youth_svy_age_range: 0,
        monitorPropData: [],
    },
};

const monitorInfo = createSlice({
    name: "monitorInfo",
    initialState,
    reducers: {
        getMonitorInfo: (state, _action) => {
            return { ...state };
        },

        getMonitorInfoSuccess: (
            state,
            action: PayloadAction<types.MonitorDataInfoType>,
        ) => {
            return {
                ...state,
                monitorData: action.payload,
            };
        },

        getMonitorInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const { getMonitorInfo, getMonitorInfoSuccess, getMonitorInfoError } =
    monitorInfo.actions;

export default monitorInfo.reducer;
