import { useAppDispatch } from "../redux/hooks";
import { useEffect } from "react";
import { setMpowerScoreKeyData } from "./redux/country.slice";
import { useSelector } from "react-redux";
import { showPageLoaderSelector } from "../shared/redux/shared.selector";

// Components imports
import HeaderComponent from "../shared/components/header/header.component";
import FooterComponent from "../shared/components/footer/footer.component";
import CountryBodyComponent from "./components/country-body.component";
import LoaderComponent from "../shared/components/loader.component";

//Interface import
import { HeaderPropType } from "../shared/components/header/header-interface";

//Image import
import WhoBlueLogo from "../assets/logo/who-logo-blue.png";
import mpowerLogo from "../assets/logo/mpower-logo-gradient.png";

/**
 * CountryPage component represents the main page of the country section.
 * It includes a header, body, and footer components.
 * @function CountryPage
 * @returns {React.JSX.Element}
 */

const headerPropData: HeaderPropType[] = [
    {
        id: "01",
        img: WhoBlueLogo,
        alt: "who-blue-logo",
        class: "whologo",
        url: "",
    },
    {
        id: "02",
        img: mpowerLogo,
        alt: "mpower-logo",
        class: "mpowerlogo",
        url: "/",
        headerId: "MPLogo"
    },
];

const CountryPage = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setMpowerScoreKeyData("-"));
    }, []);
    const showLoader = useSelector(showPageLoaderSelector);

    return (
        <>
            {showLoader ? (
                <LoaderComponent></LoaderComponent>
            ) : (
                <>
                    <HeaderComponent imgData={headerPropData} />
                    <CountryBodyComponent />
                    <FooterComponent />
                </>
            )}
        </>
    );
};

export default CountryPage;
