import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const countryInfoState = (state: RootState) => {
    return state.countryInfo;
};

export const blockDataSelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.blockData;
    },
);

export const inputDataSelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.inputData;
    },
);

export const countryListSelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.countryList;
    },
);

export const selectedCountrySelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.selectedCountry;
    },
);

export const countryNameSelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.countryName;
    },
);

export const matchedCountryCodeSelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.matchedCountryCode;
    },
);

export const isCountryNotFoundSelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.isCountryNotFound;
    },
);

export const progressDataSelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.selectedCountry?.progress;
    },
);

export const mpowerScoreKeyDataSelector = createSelector(
    [countryInfoState],
    (countryInfoState) => {
        return countryInfoState?.mpowerScoreKeyData;
    },
);
