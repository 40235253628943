import React from "react";

//Image or Icon or logo imports
import checkTick from "../../../assets/icon/check-box.png";
import emptyCheckBox from "../../../assets/icon/empty-check-box.png";

//Interface Import
import { OfferStatusBlockListProps } from "./offer-current-status-block-list-interface";

/**
 * OfferCurrentStatusBlockListComponent is a React component that renders a list of status blocks.
 *
 * @component
 * @param {OfferStatusBlockListProps} props
 * @returns {React.JSX.Element}
 */

const OfferCurrentStatusBlockListComponent = (
    props: OfferStatusBlockListProps,
): React.JSX.Element => {
    return (
        <>
            {props.blockData.map((block) => (
                <React.Fragment key={block.id}>
                    <div className="col-span-1 m-4 rounded border shadow">
                        <div className="flex items-center flex-col">
                            <img
                                src={block.img}
                                alt={block.alt}
                                className="w-40 h-40"
                            />
                        </div>
                        <div className="flex gap-4 bg-gray-100 justify-between items-center p-2 w-full">
                            <p
                                className={`w-11/12 ${
                                    block.result ? "font-bold" : ""
                                }`}
                                data-testid={`offer-status-block`}
                            >
                                {block.text}
                            </p>
                            <img
                                src={block.result ? checkTick : emptyCheckBox}
                                alt={
                                    block.result
                                        ? "check-tick"
                                        : "empty-check-box"
                                }
                                className="w-6 h-6"
                            />
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

export default OfferCurrentStatusBlockListComponent;
