import { Link } from "react-router-dom";

//Interface import
import { MpowerBlockProps } from "./mpower-block-interface";

/**
 * MpowerBlockComponent, component for rendering Mpower blocks.
 *
 * @component
 * @param {MpowerBlockProps} props
 * @returns {React.JSX.Element}
 */

const MpowerBlockComponent = (props: MpowerBlockProps): React.JSX.Element => {
    /**
     * Determine the text color class based on button background color.
     *
     * @function
     * @returns {string} The text color class (either 'text-white' or 'text-black').
     */

    const getTextColorClass = (): string => {
        if (
            props.mpowerBlockData.buttonBgColor ===
                "color-complete-requirement" ||
            props.mpowerBlockData.buttonBgColor === "color-moderate-requirement"
        ) {
            return "text-white";
        } else {
            return "text-black";
        }
    };

    return (
        <>
            {!props?.mpowerBlockData?.countryGroup ? (
                <div className="block shadow-lg text-center font-bold">N/A</div>
            ) : (
                <Link to={props?.mpowerBlockData?.url} data-testid="test-link">
                    <div className="block shadow-lg" id={`${props.id}-Mpbtn`}>
                        <div className="block-img">
                            <img
                                src={props?.mpowerBlockData?.img}
                                alt={props?.mpowerBlockData?.alt}
                                className="m-auto"
                            />
                        </div>
                        <div className="bg-white block-content">
                            <p className="m-2">
                                {props?.mpowerBlockData?.paraText}
                            </p>
                            <div
                                className={`m-2 p-1 ${props?.mpowerBlockData?.buttonBgColor}`}
                            >
                                <button className={`${getTextColorClass()}`}>
                                    {props?.mpowerBlockData?.buttonText}
                                </button>
                            </div>
                        </div>
                    </div>
                </Link>
            )}
        </>
    );
};

export default MpowerBlockComponent;
