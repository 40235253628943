import React from "react";
import Slider from "react-slick";
import "../../../styles.css";
import "../../../responsive.css";

//Image or Icon or logo imports
import OpenBook from "../../../assets/icon/home-openbook.png";
import RightArrow from "../../../assets/icon/right-arrow.png";

//Interface import
import { ResourcesBlockProps } from "./helpfull-resources-block-interface";

/**
 * HelpfullResourcesBlockComponent is a functional component that displays a block of helpful resources like links to other resources.
 *
 * @component
 * @param {ResourcesBlockProps} props
 * @param {Array} props.blockData
 * @returns {React.JSX.Element}
 */

const HelpfullResourcesBlockComponent = (
    props: ResourcesBlockProps,
): React.JSX.Element => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Slider {...settings}>
            {props.blockData.map((block) => (
                <React.Fragment key={block.id}>
                    <div
                        className="light-grey shadow-xl shadow-slate-200 rounded-xl py-3"
                        data-testid={`resources-block`}
                    >
                        <div className="grid grid-cols-3 m-4">
                            <div className="col-span-3 xl:col-span-1 bg-white rounded-full m-auto xl:m-6 w-32 h-32 p-5 pt-6">
                                <img src={OpenBook} alt="Open-Book" />
                            </div>
                            <div className="col-span-3 xl:col-span-2 ml-10 flex justify-center flex-col">
                                <p className="font-bold slick-content">
                                    {block.linkText}
                                </p>
                                <div className="flex">
                                    <a
                                        href={block.url}
                                        className="text-amber-600 border-b border-amber-600 pr-1"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {`Read here`}
                                    </a>
                                    <img
                                        src={RightArrow}
                                        alt="Right-Arrow"
                                        className="w-4 border-b border-amber-600"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </Slider>
    );
};

export default HelpfullResourcesBlockComponent;
