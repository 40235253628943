//Interface import
import { CoverRightCardPropType } from "./cover-right-card-interface";

/**
 * CoverRightCardComponent is a functional component that displays a card with the right portion of the cover card view, which consits of an image and some text.
 *
 * @component
 * @param {CoverRightCardPropType} props
 * @returns {React.JSX.Element}
 */

const CoverRightCardComponent = (
    props: CoverRightCardPropType,
): React.JSX.Element => {
    return (
        <div className="block col-span-2 lg:col-span-1 bg-white m-4">
            <img
                className="p-8"
                src={props?.coverRightCardData?.img}
                alt={props?.coverRightCardData?.alt}
                id={`${props.CoverRight}-CoverRightimg`}
            />
            <p className="p-8 text-left pt-0" id={`${props.CoverRight}-CoverRighttext`}>
                {props?.coverRightCardData?.rightCardText}
            </p>
        </div>
    );
};

export default CoverRightCardComponent;
