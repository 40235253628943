import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    DATA_NOT_REPORTED,
    NO_CRITERIA_ACHIEVED,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
} from "../../../constants/shared.contants";
import { CountryCompareRegionInfoType } from "./countries-compare-region-block.types";

export const getProccessedCountryRegionInfo = (
    countryCompareRegionInfo: CountryCompareRegionInfoType,
    policyNumber: string,
) => {
    let data = [];
    const setCountZeroIfNull = (policyValue: string) => {
        return policyValue || "0";
    };
    if (policyNumber === "0") {
        data = [
            {
                id: "1",
                paraText: NO_CRITERIA_ACHIEVED,
                cardBgColor: BG_COLOR_NO_REQUIREMENT,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue1,
                ),
            },
            {
                id: "2",
                paraText: STATUS_MINIMAL,
                cardBgColor: BG_COLOR_MINIMAL,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue2,
                ),
            },
            {
                id: "3",
                paraText: STATUS_MODERATE,
                cardBgColor: BG_COLOR_MODERATE,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue3,
                ),
            },
            {
                id: "4",
                paraText: STATUS_COMPLETE,
                cardBgColor: BG_COLOR_COMPLETE,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue4,
                ),
            },
        ];
    } else {
        data = [
            {
                id: "1",
                paraText: DATA_NOT_REPORTED,
                cardBgColor: BG_COLOR_NO_DATA,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue1,
                ),
            },
            {
                id: "2",
                paraText: NO_CRITERIA_ACHIEVED,
                cardBgColor: BG_COLOR_NO_REQUIREMENT,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue2,
                ),
            },
            {
                id: "3",
                paraText: STATUS_MINIMAL,
                cardBgColor: BG_COLOR_MINIMAL,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue3,
                ),
            },
            {
                id: "4",
                paraText: STATUS_MODERATE,
                cardBgColor: BG_COLOR_MODERATE,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue4,
                ),
            },
            {
                id: "5",
                paraText: STATUS_COMPLETE,
                cardBgColor: BG_COLOR_COMPLETE,
                CountryCount: setCountZeroIfNull(
                    countryCompareRegionInfo?.policyValue5,
                ),
            },
        ];
    }

    countryCompareRegionInfo.countryRegionPropData = data;

    return countryCompareRegionInfo;
};
