import React from "react";

//Image or Icon or logo imports
import checkTick from "../../../assets/icon/check-box.png";
import emptyCheckBox from "../../../assets/icon/empty-check-box.png";

//Interface import
import { WarnPackageStatusBlockProps } from "./warn-package-current-status-block-interface";

/**
 * WarnPackageCurrentStatusBlockComponent is a functional component that displays a list of status blocks.
 *
 * @component
 * @param {WarnPackageStatusBlockProps} props
 * @returns {React.JSX.Element}
 */

const WarnPackageCurrentStatusBlockComponent = (
    props: WarnPackageStatusBlockProps,
): React.JSX.Element => {
    return (
        <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 justify-center m-2">
            {props.statusBlockData.map((block) => (
                <React.Fragment key={block.id}>
                    <div
                        className="col-span-1 m-2 rounded bg-white flex flex-col justify-between"
                        data-testid={`warn-package-status-block`}
                    >
                        <img src={block.img} alt={block.alt} />
                        <div className="flex gap-2 border rounded m-2 p-1 status-grey-content">
                            <p
                                className={`text-sm ${
                                    block.result ? "font-bold" : ""
                                }`}
                            >
                                {block.text}
                            </p>
                            <img
                                src={block.result ? checkTick : emptyCheckBox}
                                alt={
                                    block.result
                                        ? "check-tick"
                                        : "empty-check-box"
                                }
                                className="w-6 h-6 self-center"
                            />
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default WarnPackageCurrentStatusBlockComponent;
