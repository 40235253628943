import React from "react";

//Interface import
import { MpowerScoreCardProps } from "./mpower-score-card-interface";

/**
 * MpowerScoreCard renders score key cards or blocks which represents the color key for the mpower.
 *
 * @component
 * @param {Object} props
 * @param {Array} props.cardData
 * @returns {React.JSX.Element}
 */

const MpowerScoreCard = (props: MpowerScoreCardProps): React.JSX.Element => {
    const getTextColorClass = (cardBgColor: string) => {
        if (
            cardBgColor === "color-complete-requirement" ||
            cardBgColor === "color-moderate-requirement"
        ) {
            return "text-white";
        } else {
            return "text-black";
        }
    };

    return (
        <>
            {props?.cardData?.map((card) => (
                <React.Fragment key={card?.id}>
                    <div
                        className={`p-4 score-card-height rounded w-1/5 text-center border border-black color-key-box ${card?.cardBgColor}`}
                    >
                        <p
                            className={`${getTextColorClass(
                                card?.cardBgColor,
                            )}`}
                        >
                            {card?.cardText}
                        </p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 256 512"
                        >
                            <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                        </svg>
                        <div className="dotted"></div>
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

export default MpowerScoreCard;
