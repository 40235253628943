// Components import
import CoverScoreKeyCardComponent from "../../../shared/components/cover-score-key-card/cover-score-key-card.component";
import WarnMassCurrentstatusDataViewComponent from "../warn-mass-current-status-data-view/warn-mass-current-status-data-view.component";

import { useSelector } from "react-redux";
import { massMediaInfoSelector } from "../../redux/warn-mass-media.selector";

/**
 * ProtectCurrentStatusComponent is a functional component that displays current status information for protect page.
 * @component
 * @returns {React.JSX.Element}
 */

const WarnMassCurrentStatusComponent = (): React.JSX.Element => {
    const massMediaInfo = useSelector(massMediaInfoSelector);

    return (
        <div className="container mx-auto">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    What has been achieved
                </h2>
            </div>
            <div className="grid grid-cols-3 justify-center my-10 mx-4">
                <div className="col-span-3 lg:col-span-1 lg:mr-8">
                    <p>
                        To be eligible for the mass media assessment the country
                        must have run at least one national mass media campaign
                        that lasted for at least three weeks. The groupings for
                        the mass media indicator are based on characteristics
                        associated with the development, implementation and
                        evaluation of the campaign.
                    </p>
                </div>
                <div className="col-span-3 lg:col-span-2 lg:ml-8">
                    <div className="current-status-diagram">
                        <div className="grid grid-cols-5 justify-center current-color-box mb-8">
                            <CoverScoreKeyCardComponent
                                scoreKeyCardData={
                                    massMediaInfo.massMediaCoverPropData
                                }
                            />
                        </div>
                    </div>
                    <WarnMassCurrentstatusDataViewComponent />
                </div>
            </div>
        </div>
    );
};
export default WarnMassCurrentStatusComponent;
