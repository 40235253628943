/**
 * DetailedBreakdownTableHeaderComponent is a React component that displays a table header for an offer table.
 *
 * @component
 * @param {Object} props
 * @returns {React.JSX.Element}
 */

const DetailedBreakdownTableHeaderComponent = (props: {
    text: string;
}): React.JSX.Element => {
    const { text } = props;
    return (
        <div className="col-span-1 flex p-4 justify-center items-center text-white">
            {text}
        </div>
    );
};

export default DetailedBreakdownTableHeaderComponent;
