import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../rest-handler/rest-handler.service";
import * as HELPFULRESOURCES_SLICE from "./helpful-resources.slice";
import { setShowPageLoader } from "../../../redux/shared.slice";

export function* getHelpfulResourcesInfoSaga(action: any): any {
    let url = `/HelpFullResourcesByType?type=${action?.payload}`;
    try {
        let helpfulResources = yield call(RestHandlerService.get, url, {});
        yield put(
            HELPFULRESOURCES_SLICE.getHelpfulResourcesInfoSuccess(
                helpfulResources,
            ),
        );
        yield put(setShowPageLoader(false));
    } catch (err) {
        yield put(HELPFULRESOURCES_SLICE.getHelpfulResourcesInfoError());
        yield put(setShowPageLoader(false));
    }
}

export function* getHelpfulResourcesSaga() {
    yield takeLatest(
        "helpfulResources/getHelpfulResourcesInfo",
        getHelpfulResourcesInfoSaga,
    );
}
