// Components imports
import SuccessStoryComponent from "../../../shared/components/success-story/success-story.component";

import { WARN_MASS_MEDIA_SUCCESS_PROP_DATA } from "../../../shared/constants/shared.contants";

/**
 * WarnMassSuccessStoryComponent is a functional component that displays a success story section with a description and an image.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const WarnMassSuccessStoryComponent = (): React.JSX.Element => {
    return (
        <SuccessStoryComponent
            successData={WARN_MASS_MEDIA_SUCCESS_PROP_DATA}
        />
    );
};

export default WarnMassSuccessStoryComponent;
