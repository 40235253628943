import { protectInfoSelector } from "../../redux/protect.selector";
import { useSelector } from "react-redux";

// Components imports
import ProtectCurrentStatusBlockComponent from "../protect-current-status-block/protect-current-status-block.component";

//Interface import
import { ProtectStatusBlockPropType } from "./protect-current-status-data-view-interface";

//Image or Icon or logo imports
import healthCare from "../../../assets/icon/health-care.png";
import education from "../../../assets/icon/education.png";
import universities from "../../../assets/icon/universities.png";
import governmentFacilities from "../../../assets/icon/government-facilities.png";
import indoorOffices from "../../../assets/icon/indoor-offices.png";
import restaurants from "../../../assets/icon/restaurants.png";
import pubAndBars from "../../../assets/icon/pubs-and-bars.png";
import publicTransport from "../../../assets/icon/public-transport.png";

/**
 * ProtectCurrentstatusDataViewComponent is a functional component that displays status blocks and action buttons.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const ProtectCurrentstatusDataViewComponent = (): React.JSX.Element => {
    const protectInfo = useSelector(protectInfoSelector);

    const statusBlockPropData: ProtectStatusBlockPropType[] = [
        {
            id: "01",
            img: healthCare,
            alt: "health-care",
            heading: "Smoke-free health-care facilities",
            result: protectInfo.P1_healthcare,
        },
        {
            id: "02",
            img: education,
            alt: "education",
            heading: "Smoke-free education facilities except universities",
            result: protectInfo.P2_education,
        },
        {
            id: "03",
            img: universities,
            alt: "universities",
            heading: "Smoke-free universities",
            result: protectInfo.P3_universities,
        },
        {
            id: "04",
            img: governmentFacilities,
            alt: "government-facilities",
            heading: "Smoke-free government facilities",
            result: protectInfo.P4_govt,
        },
        {
            id: "05",
            img: indoorOffices,
            alt: "indoor-offices",
            heading: "Smoke-free workplaces",
            result: protectInfo.P5_indoor_off,
        },
        {
            id: "06",
            img: restaurants,
            alt: "restaurants",
            heading: "Smoke-free restaurants",
            result: protectInfo.P6_restaurants,
        },
        {
            id: "07",
            img: pubAndBars,
            alt: "pub-and-bar",
            heading: "Smoke-free cafés, pubs and bars",
            result: protectInfo.P7_pubs,
        },

        {
            id: "08",
            img: publicTransport,
            alt: "public-transport",
            heading: "Smoke-free public transport",
            result: protectInfo.P8_pub_transp,
        },
    ];
    return (
        <div className="rounded text-center border border-black p-8 mb-4">
            <ProtectCurrentStatusBlockComponent
                statusBlockData={statusBlockPropData}
            />
        </div>
    );
};

export default ProtectCurrentstatusDataViewComponent;
