import { createSlice } from "@reduxjs/toolkit";
import * as types from "./helpful-resources.types";

export const initialState: types.InitialState = {
    helpfulResourcesData: [
        {
            id: "",
            linkText: "",
            url: "",
        },
    ],
};

const helpfulResources = createSlice({
    name: "helpfulResources",
    initialState,
    reducers: {
        getHelpfulResourcesInfo: (state, _action) => {
            return { ...state };
        },

        getHelpfulResourcesInfoSuccess: (state, action) => {
            return {
                ...state,
                helpfulResourcesData: action.payload,
            };
        },

        getHelpfulResourcesInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    getHelpfulResourcesInfo,
    getHelpfulResourcesInfoSuccess,
    getHelpfulResourcesInfoError,
} = helpfulResources.actions;

export default helpfulResources.reducer;
