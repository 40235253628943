import WhoWhiteLogo from "../../../assets/logo/who-logo-white.png";

/**
 * The Footer component represents the footer section of a web page.
 *
 * @function FooterComponent
 * @returns {React.JSX.Element}
 */

const FooterComponent = (): React.JSX.Element => {
    return (
        <div className="footer p-8">
            <div className="container mx-auto flex">
                <div className="text-white w-1/2">
                    <img
                        src={WhoWhiteLogo}
                        alt="who white logo"
                        className="w-48"
                    />
                </div>
                <div className="self-center text-white w-1/2 text-right">
                    ©&nbsp;<span className="head-medium">2024 WHO</span>
                </div>
            </div>
        </div>
    );
};

export default FooterComponent;
