const TechnicalNoteLinkComponent = () => {
    return (
        <a
            href={`${process.env.PUBLIC_URL}/pdf/Technical-Notes.pdf`}
            target="_blank"
            title="Technical Note"
            rel="noopener noreferrer"
            className="text-blue-500 font-bold"
        >
            Click here
        </a>
    );
};

export default TechnicalNoteLinkComponent;
