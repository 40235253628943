import { commonCoverPropData } from "../../shared/constants/shared.contants";
import { RaiseTaxDataInfoType } from "./raise-tax.types";

export const setRaiseTaxCoverPropData = (
    raiseTaxInfo: RaiseTaxDataInfoType,
) => {
    let groupNumber = parseInt(raiseTaxInfo.R_Group);
    const raiseTaxData = [...commonCoverPropData];

    for (let i = 0; i < groupNumber; i++) {
        raiseTaxData[i] = { ...raiseTaxData[i], meets: true };
    }

    for (let i = 1; i <= groupNumber; i++) {
        if (i === groupNumber) {
            raiseTaxData[i - 1] = {
                ...raiseTaxData[i - 1],
                lastExpanded: true,
            };
        }
    }

    return raiseTaxData;
};

export const getProccessedRaiseTaxInfo = (
    raiseTaxInfo: RaiseTaxDataInfoType,
) => {
    raiseTaxInfo.raiseTaxCoverPropData = setRaiseTaxCoverPropData(raiseTaxInfo);

    return raiseTaxInfo;
};
