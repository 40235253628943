import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as types from "./raise-tax.types";

export const initialState: types.InitialState = {
    raiseTaxData: {
        R_Group: "",
        indicators: [
            {
                code: "",
                title: "",
            },
        ],
        R_afford_cat: "",
        R_Total_tax_estimate: "",
        raiseTaxCoverPropData: [],
    },
};

const raiseTaxInfo = createSlice({
    name: "raiseTaxInfo",
    initialState,
    reducers: {
        getRaiseTaxInfo: (state, _action) => {
            return { ...state };
        },

        getRaiseTaxInfoSuccess: (
            state,
            action: PayloadAction<types.RaiseTaxDataInfoType>,
        ) => {
            return {
                ...state,
                raiseTaxData: action.payload,
            };
        },

        getRaiseTaxInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const { getRaiseTaxInfo, getRaiseTaxInfoSuccess, getRaiseTaxInfoError } =
    raiseTaxInfo.actions;

export default raiseTaxInfo.reducer;
