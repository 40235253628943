// Components imports
import CountriesCompareComponent from "../shared/components/countries-compare/components/countries-compare.component";
import HelpfullResourcesComponent from "../shared/components/helpful-resources/components/helpful-resources.component";
import SharedActionComponent from "../shared/components/shared-action/shared-action.component";
import ProtectDetailedBreakdownComponent from "./components/protect-detailed-breakdown/protect-detailed-breakdown.component";
import ProtectCoverComponent from "./components/protect-cover/protect-cover.component";
import ProtectCurrentStatusComponent from "./components/protect-current-status/protect-current-status.component";
import ProtectSuccessStoryComponent from "./components/protect-success-story/protect-success-story.component";
import CountriesRegionComponent from "../shared/components/countries-compare-region/countries-compare-region.component";

/**
 * ProtectBodyComponent is responsible for rendering the main body section of the Protect page.
 * @function ProtectBodyComponent
 * @returns {React.JSX.Element}
 */

const ProtectBodyComponent = (): React.JSX.Element => {
    return (
        <div className="protect-page">
            <ProtectCoverComponent />
            <ProtectCurrentStatusComponent />
            <ProtectDetailedBreakdownComponent />
            <CountriesRegionComponent measureName="PROTECT" />
            <CountriesCompareComponent />
            <ProtectSuccessStoryComponent />
            <SharedActionComponent />
            <HelpfullResourcesComponent />
        </div>
    );
};

export default ProtectBodyComponent;
