import { commonCoverPropData } from "../../shared/constants/shared.contants";
import { WarnPackageDataInfoType } from "./warn-package.types";

//Image or Icon or logo imports
import mandatedFontImg from "../../assets/icon/mandated-font.png";
import rotatedWarningImg from "../../assets/icon/rotated-warning.png";
import principleLanguageImg from "../../assets/icon/principle-language.png";
import photographImg from "../../assets/icon/photograph.png";
import labelImg from "../../assets/icon/label.png";
import dangerSkullImg from "../../assets/icon/dangerSkull.png";
import healthWarningImg from "../../assets/icon/health-warning.png";

export const setWarnPackageCoverPropData = (
    warnPackageInfo: WarnPackageDataInfoType,
) => {
    let groupNumber = parseInt(warnPackageInfo.W_Group);
    const warnPackageData = [...commonCoverPropData];

    for (let i = 0; i < groupNumber; i++) {
        warnPackageData[i] = { ...warnPackageData[i], meets: true };
    }

    for (let i = 1; i <= groupNumber; i++) {
        if (i === groupNumber) {
            warnPackageData[i - 1] = {
                ...warnPackageData[i - 1],
                lastExpanded: true,
            };
        }
    }

    return warnPackageData;
};

const setIndicatortitle = (
    indicatorCode: string,
    warnPackageInfo: WarnPackageDataInfoType,
) => {
    const indicatorData = warnPackageInfo.indicators.find(
        (indicator: { code: string }) => {
            return indicator.code === indicatorCode;
        },
    );
    return indicatorData ? indicatorData.title : "";
};

export const setWarnPackageStatusPropData = (
    warnPackageInfo: WarnPackageDataInfoType,
) => {
    const setResult = (resultInfo: string) => {
        if (resultInfo.toLowerCase() === "yes") {
            return true;
        } else {
            return false;
        }
    };

    return [
        {
            id: "01",
            img: mandatedFontImg,
            alt: "mandated-font",
            text: setIndicatortitle("W6_font_A", warnPackageInfo),
            result: setResult(warnPackageInfo.W6_font_A),
        },
        {
            id: "02",
            img: rotatedWarningImg,
            alt: "rotated-warning",
            text: setIndicatortitle("W7_rotation_A", warnPackageInfo),
            result: setResult(warnPackageInfo.W7_rotation_A),
        },
        {
            id: "03",
            img: principleLanguageImg,
            alt: "princliple-language",
            text: setIndicatortitle("W8_princ_langs_A", warnPackageInfo),
            result: setResult(warnPackageInfo.W8_princ_langs_A),
        },
        {
            id: "04",
            img: photographImg,
            alt: "photograph",
            text: setIndicatortitle("W10_graphic_A", warnPackageInfo),
            result: setResult(warnPackageInfo.W10_graphic_A),
        },
        {
            id: "05",
            img: labelImg,
            alt: "label",
            text: setIndicatortitle("W11_outside_pack_A", warnPackageInfo),
            result: setResult(warnPackageInfo.W11_outside_pack_A),
        },
        {
            id: "06",
            img: dangerSkullImg,
            alt: "danger-skull",
            text: setIndicatortitle("W14_harm_effects_A", warnPackageInfo),
            result: setResult(warnPackageInfo.W14_harm_effects_A),
        },
        {
            id: "07",
            img: healthWarningImg,
            alt: "health-warning",
            text: setIndicatortitle("W15_specific_hw_A", warnPackageInfo),
            result: setResult(warnPackageInfo.W15_specific_hw_A),
        },
    ];
};

export const setWarnPackageDBPropData = (
    warnPackageInfo: WarnPackageDataInfoType,
) => {
    const setMeets = (meetsInfo: string) => {
        if (meetsInfo.toLowerCase() === "yes") {
            return true;
        } else if (parseInt(meetsInfo) > 50) {
            return true;
        } else {
            return false;
        }
    };
    return [
        {
            id: 1,
            heading: setIndicatortitle("W1_HW_law_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W1_HW_law_A,
            smokedText: warnPackageInfo.W1_HW_law_B,
            smokelessText: warnPackageInfo.W1_HW_law_C,
            meets: setMeets(warnPackageInfo.W1_HW_law_A),
        },
        {
            id: 2,
            heading: setIndicatortitle("W2_pc_front_back_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W2_pc_front_back_A,
            smokedText: warnPackageInfo.W2_pc_front_back_B,
            smokelessText: warnPackageInfo.W2_pc_front_back_C,
            meets: setMeets(warnPackageInfo.W2_pc_front_back_A),
        },
        {
            id: 3,
            heading: setIndicatortitle("W3_pc_front_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W3_pc_front_A,
            smokedText: warnPackageInfo.W3_pc_front_B,
            smokelessText: warnPackageInfo.W3_pc_front_C,
            meets: setMeets(warnPackageInfo.W3_pc_front_A),
        },
        {
            id: 4,
            heading: setIndicatortitle("W4_pc_back_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W4_pc_back_A,
            smokedText: warnPackageInfo.W4_pc_back_B,
            smokelessText: warnPackageInfo.W4_pc_back_C,
            meets: setMeets(warnPackageInfo.W4_pc_back_A),
        },
        {
            id: 5,
            heading: setIndicatortitle("W15_specific_hw_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W15_specific_hw_A,
            smokedText: warnPackageInfo.W15_specific_hw_B,
            smokelessText: warnPackageInfo.W15_specific_hw_C,
            meets: setMeets(warnPackageInfo.W15_specific_hw_A),
        },
        {
            id: 6,
            heading: setIndicatortitle("W7_rotation_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W7_rotation_A,
            smokedText: warnPackageInfo.W7_rotation_B,
            smokelessText: warnPackageInfo.W7_rotation_C,
            meets: setMeets(warnPackageInfo.W7_rotation_A),
        },
        {
            id: 7,
            heading: setIndicatortitle("W11_outside_pack_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W11_outside_pack_A,
            smokedText: warnPackageInfo.W11_outside_pack_B,
            smokelessText: warnPackageInfo.W11_outside_pack_C,
            meets: setMeets(warnPackageInfo.W11_outside_pack_A),
        },
        {
            id: 8,
            heading: setIndicatortitle("W6_font_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W6_font_A,
            smokedText: warnPackageInfo.W6_font_B,
            smokelessText: warnPackageInfo.W6_font_C,
            meets: setMeets(warnPackageInfo.W6_font_A),
        },
        {
            id: 9,
            heading: setIndicatortitle("W8_princ_langs_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W8_princ_langs_A,
            smokedText: warnPackageInfo.W8_princ_langs_B,
            smokelessText: warnPackageInfo.W8_princ_langs_C,
            meets: setMeets(warnPackageInfo.W8_princ_langs_A),
        },
        {
            id: 10,
            heading: setIndicatortitle("W10_graphic_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W10_graphic_A,
            smokedText: warnPackageInfo.W10_graphic_B,
            smokelessText: warnPackageInfo.W10_graphic_C,
            meets: setMeets(warnPackageInfo.W10_graphic_A),
        },
        {
            id: 11,
            heading: setIndicatortitle("W14_harm_effects_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W14_harm_effects_A,
            smokedText: warnPackageInfo.W14_harm_effects_B,
            smokelessText: warnPackageInfo.W14_harm_effects_C,
            meets: setMeets(warnPackageInfo.W14_harm_effects_A),
        },
        {
            id: 12,
            heading: setIndicatortitle("W5_top_side_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W5_top_side_A,
            smokedText: warnPackageInfo.W5_top_side_B,
            smokelessText: warnPackageInfo.W5_top_side_C,
            meets: setMeets(warnPackageInfo.W5_top_side_A),
        },
        {
            id: 13,
            heading: setIndicatortitle("W13_ind_liability_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W13_ind_liability_A,
            smokedText: warnPackageInfo.W13_ind_liability_B,
            smokelessText: warnPackageInfo.W13_ind_liability_C,
            meets: setMeets(warnPackageInfo.W13_ind_liability_A),
        },
        {
            id: 14,
            heading: setIndicatortitle(
                "W12_imports_dutyfree_A",
                warnPackageInfo,
            ),
            cigarettesText: warnPackageInfo.W12_imports_dutyfree_A,
            smokedText: warnPackageInfo.W12_imports_dutyfree_B,
            smokelessText: warnPackageInfo.W12_imports_dutyfree_C,
            meets: setMeets(warnPackageInfo.W12_imports_dutyfree_A),
        },
        {
            id: 15,
            heading: setIndicatortitle("W9_no_obscure_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W9_no_obscure_A,
            smokedText: warnPackageInfo.W9_no_obscure_B,
            smokelessText: warnPackageInfo.W9_no_obscure_C,
            meets: setMeets(warnPackageInfo.W9_no_obscure_A),
        },
        {
            id: 16,
            heading: setIndicatortitle("W17_fines_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W17_fines_A,
            smokedText: warnPackageInfo.W17_fines_B,
            smokelessText: warnPackageInfo.W17_fines_C,
            meets: setMeets(warnPackageInfo.W17_fines_A),
        },
        {
            id: 17,
            heading: setIndicatortitle(
                "W18_misleading_terms_A",
                warnPackageInfo,
            ),
            cigarettesText: warnPackageInfo.W18_misleading_terms_A,
            smokedText: warnPackageInfo.W18_misleading_terms_B,
            smokelessText: warnPackageInfo.W18_misleading_terms_C,
            meets: setMeets(warnPackageInfo.W18_misleading_terms_A),
        },
        {
            id: 18,
            heading: setIndicatortitle(
                "W19_colours_numbers_A",
                warnPackageInfo,
            ),
            cigarettesText: warnPackageInfo.W19_colours_numbers_A,
            smokedText: warnPackageInfo.W19_colours_numbers_B,
            smokelessText: warnPackageInfo.W19_colours_numbers_C,
            meets: setMeets(warnPackageInfo.W19_colours_numbers_A),
        },
        {
            id: 19,
            heading: setIndicatortitle("W20_flavours_A", warnPackageInfo),
            cigarettesText: warnPackageInfo.W20_flavours_A,
            smokedText: warnPackageInfo.W20_flavours_B,
            smokelessText: warnPackageInfo.W20_flavours_C,
            meets: setMeets(warnPackageInfo.W20_flavours_A),
        },
        {
            id: 20,
            heading: setIndicatortitle(
                "W21_emissions_quant_A",
                warnPackageInfo,
            ),
            cigarettesText: warnPackageInfo.W21_emissions_quant_A,
            smokedText: warnPackageInfo.W21_emissions_quant_B,
            smokelessText: warnPackageInfo.W21_emissions_quant_C,
            meets: setMeets(warnPackageInfo.W21_emissions_quant_A),
        },
        {
            id: 21,
            heading: setIndicatortitle(
                "W25_quitline_number_A",
                warnPackageInfo,
            ),
            cigarettesText: warnPackageInfo.W25_quitline_number_A,
            smokedText: warnPackageInfo.W25_quitline_number_B,
            smokelessText: warnPackageInfo.W25_quitline_number_C,
            meets: setMeets(warnPackageInfo.W25_quitline_number_A),
        },
        {
            id: 22,
            heading: setIndicatortitle(
                "W26_plain_packaging_A",
                warnPackageInfo,
            ),
            cigarettesText: warnPackageInfo.W26_plain_packaging_A,
            smokedText: warnPackageInfo.W26_plain_packaging_B,
            smokelessText: warnPackageInfo.W26_plain_packaging_C,
            meets: setMeets(warnPackageInfo.W26_plain_packaging_A),
        },
    ];
};

export const getProccessedWarnPackageInfo = (
    warnPackageInfo: WarnPackageDataInfoType,
) => {
    warnPackageInfo.warnPackageCoverPropData =
        setWarnPackageCoverPropData(warnPackageInfo);
    warnPackageInfo.warnPackageCurrentPropData =
        setWarnPackageStatusPropData(warnPackageInfo);
    warnPackageInfo.warnPackageDBPropData =
        setWarnPackageDBPropData(warnPackageInfo);

    return warnPackageInfo;
};
