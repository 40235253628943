import { useAppDispatch } from "../redux/hooks";
import { CountryInfoType } from "../country/redux/country.types";
import { useSelector } from "react-redux";
import { selectedCountrySelector } from "../country/redux/country.selector";
import { showPageLoaderSelector } from "../shared/redux/shared.selector";
import { useEffect } from "react";
import { genericActionDispatcher } from "../shared/redux/shared.slice";

// Components imports
import HeaderComponent from "../shared/components/header/header.component";
import FooterComponent from "../shared/components/footer/footer.component";
import WarnMassMediaBodyComponent from "./components/warn-mass-media-body.component";
import LoaderComponent from "../shared/components/loader.component";

// Logo and image import
import mpowerLogoWarn from "../assets/logo/mpower-logo-warn.png";
import WhoBlueLogo from "../assets/logo/who-logo-blue.png";

//Interface import
import { HeaderPropType } from "../shared/components/header/header-interface";

/**
 * WarnMassMediaPage component represents the main page of the warn mass media section.
 * It includes a header, body, and footer components.
 * @function CountryPage
 * @returns {React.JSX.Element}
 */

const headerPropData: HeaderPropType[] = [
    {
        id: "01",
        img: WhoBlueLogo,
        alt: "who-blue-logo",
        class: "whologo",
        url: "",
    },
    {
        id: "02",
        img: mpowerLogoWarn,
        alt: "mpower-warn-logo",
        class: "mpowerlogo",
        url: "/",
    },
];

const WarnMassMediaPage = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const selectedCountry: CountryInfoType = useSelector(
        selectedCountrySelector,
    );
    const showLoader = useSelector(showPageLoaderSelector);

    useEffect(() => {
        dispatch(
            genericActionDispatcher({
                selectedCountry: selectedCountry?.massMediaBlockPropData?.name,
                policyData: "3",
            }),
        );
    }, []);

    return (
        <>
            {showLoader ? (
                <LoaderComponent></LoaderComponent>
            ) : (
                <>
                    <HeaderComponent imgData={headerPropData} />
                    <WarnMassMediaBodyComponent />
                    <FooterComponent />
                </>
            )}
        </>
    );
};

export default WarnMassMediaPage;
