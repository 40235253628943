const ConnectionLostMessage = () => {
    return (
        <div className="connection-lost">
            <p>Lost Connection to the Server.</p>
            <p>Verify your connection and try refreshing the page.</p>
        </div>
    );
};

export default ConnectionLostMessage;
