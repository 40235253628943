// Components import
import CountryNameComponent from "../../../country/components/country-name/country-name.component";
import MpowerScoreColorKeyComponent from "../../../country/components/mpower-score-key/mpower-score-key.component";
import CoverCardViewComponent from "../../../shared/components/cover-card-view/cover-card-view.component";

//Interface import
import { CoverCardViewPropType } from "../../../shared/components/cover-card-view/cover-card-view-interface";

//Image or Icon or logo imports
import enforceImage from "../../../assets/icon/e-enforce.png";

import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_DATA,
    STATUS_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";
import { useSelector } from "react-redux";
import { enforceInfoSelector } from "../../redux/enforce.selector";
import TechnicalNoteLinkComponent from "../../../shared/components/technical-note-link-component";

/**
 * EnforceCoverComponent is a functional component that displays Enforce data.
 * @component
 * @returns {React.JSX.Element}
 */

const EnforceCoverComponent = (): React.JSX.Element => {
    const enforceInfo = useSelector(enforceInfoSelector);

    let bgColor, statusText;

    switch (enforceInfo.E_Group) {
        case "1":
            bgColor = BG_COLOR_NO_DATA;
            statusText = STATUS_NO_DATA;
            break;
        case "2":
            bgColor = BG_COLOR_NO_REQUIREMENT;
            statusText = STATUS_NO_REQUIREMENT;
            break;
        case "3":
            bgColor = BG_COLOR_MINIMAL;
            statusText = STATUS_MINIMAL;
            break;
        case "4":
            bgColor = BG_COLOR_MODERATE;
            statusText = STATUS_MODERATE;
            break;
        case "5":
            bgColor = BG_COLOR_COMPLETE;
            statusText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    const coverCardPropData: CoverCardViewPropType = {
        img: enforceImage,
        alt: "enforce-Image",
        headingText: "Bans on advertising, promotion and sponsorship",
        statusText: statusText,
        coverCardPropData: enforceInfo.enforcePropData,
        bgColor: bgColor,
        rightCardText: `Article 13 of the WHO FCTC: "[A] comprehensive ban on advertising, promotion and sponsorship would reduce the consumption of tobacco products. Each Party shall ... undertake a comprehensive ban of all tobacco advertising, promotion and sponsorship."`,
    };

    return (
        <div className="container mx-auto">
            <CountryNameComponent
                contentElement={"Return to country results"}
                url={"/country"}
            />
            <CoverCardViewComponent coverCardViewData={coverCardPropData} />
            <MpowerScoreColorKeyComponent
                headingText={
                    <div>
                        The groupings for the bans on tobacco advertising,
                        promotion and sponsorship indicator are listed here. For
                        more information on the criteria for each measure please
                        see the Technical Note: <TechnicalNoteLinkComponent />
                    </div>
                }
                footNoteText={
                    <div className="pt-10">
                        <ol>
                            <li>
                                1. Direct advertising bans:
                                <ul className="pl-5">
                                    <li>- national television and radio;</li>
                                    <li>- local magazines and newspapers;</li>
                                    <li>
                                        - billboards and outdoor advertising;
                                    </li>
                                    <li>- point of sale (indoor).</li>
                                </ul>
                            </li>
                            <li>
                                2. Indirect advertising bans:
                                <ul className="pl-5">
                                    <li>
                                        - free distribution of tobacco products
                                        in the mail or through other means;
                                    </li>
                                    <li>- promotional discounts;</li>
                                    <li>
                                        - non-tobacco goods or services
                                        identified with tobacco brand names
                                        (brand stretching);
                                    </li>
                                    <li>
                                        - brand names of non-tobacco products
                                        used for tobacco products (brand
                                        sharing);
                                    </li>
                                    <li>
                                        - appearance of tobacco brands (product
                                        placement) or tobacco products in
                                        television and/or films;
                                    </li>
                                    <li>
                                        - sponsorship (contributions and/or
                                        publicity of contributions).
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                }
                measureName={"ENSURE TAPS"}
            />
        </div>
    );
};

export default EnforceCoverComponent;
