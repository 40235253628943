import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as types from "./country-compare.types";

export const initialState: types.InitialState = {
    compareInputData: "",
    countryId: "",
    policyNumber: "",
    notCategorizedCountryList: [],
    noRequirementCountryList: [],
    minimalRequirementsCountryList: [],
    moderateRequirementsCountryList: [],
    completeRequirementsCountryList: [],
    countryCompareData: [],
    isCountryCompareNotFound: false,
    isButtonClicked: false,
    showCountriesCompareLoader: false,
};

const countryCompareInfo = createSlice({
    name: "countryCompareInfo",
    initialState,
    reducers: {
        getCountryCompareInfo: (state, _action) => {
            return { ...state };
        },

        getCountryCompareInfoSuccess: (state, action) => {
            return {
                ...state,
                countryId: action.payload,
            };
        },

        getCountryCompareInfoError: (state) => {
            return {
                ...state,
            };
        },

        setCompareSearchData: (state, action) => {
            return { ...state, compareInputData: action.payload };
        },

        setComparePolicyData: (state, action) => {
            return { ...state, policyNumber: action.payload };
        },

        setNotCategorizedCountryList: (state, action) => {
            return {
                ...state,
                notCategorizedCountryList: [
                    ...state.notCategorizedCountryList,
                    action.payload,
                ],
            };
        },

        setNoRequirementCountryList: (state, action) => {
            return {
                ...state,
                noRequirementCountryList: [
                    ...state.noRequirementCountryList,
                    action.payload,
                ],
            };
        },

        setMinimalRequirementsCountryList: (state, action) => {
            return {
                ...state,
                minimalRequirementsCountryList: [
                    ...state.minimalRequirementsCountryList,
                    action.payload,
                ],
            };
        },

        setModerateRequirementsCountryList: (state, action) => {
            return {
                ...state,
                moderateRequirementsCountryList: [
                    ...state.moderateRequirementsCountryList,
                    action.payload,
                ],
            };
        },

        setCompleteRequirementsCountryList: (state, action) => {
            return {
                ...state,
                completeRequirementsCountryList: [
                    ...state.completeRequirementsCountryList,
                    action.payload,
                ],
            };
        },

        deleteItem: (
            state: types.InitialState,
            action: PayloadAction<{ index: number; category: string }>,
        ) => {
            const { index, category } = action.payload;

            const newList = state[category].slice();
            newList.splice(index, 1);

            return {
                ...state,
                [category]: [...newList],
            };
        },

        clearCountryCompareData: (state) => {
            return {
                ...state,
                notCategorizedCountryList: [],
                noRequirementCountryList: [],
                minimalRequirementsCountryList: [],
                moderateRequirementsCountryList: [],
                completeRequirementsCountryList: [],
            };
        },

        setIsCountryCompareNotFound: (state, action) => {
            return { ...state, isCountryCompareNotFound: action.payload };
        },

        setIsButtonClicked: (state, action) => {
            return { ...state, isButtonClicked: action.payload };
        },
        setShowCountriesCompareLoader: (state, action) => {
            return { ...state, showCountriesCompareLoader: action.payload };
        },
    },
});

export const {
    getCountryCompareInfo,
    getCountryCompareInfoSuccess,
    getCountryCompareInfoError,

    setCompareSearchData,

    setComparePolicyData,

    setNotCategorizedCountryList,
    setNoRequirementCountryList,
    setMinimalRequirementsCountryList,
    setModerateRequirementsCountryList,
    setCompleteRequirementsCountryList,

    deleteItem,
    clearCountryCompareData,

    setIsCountryCompareNotFound,
    setIsButtonClicked,
    setShowCountriesCompareLoader,
} = countryCompareInfo.actions;

export default countryCompareInfo.reducer;
