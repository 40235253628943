import { useSelector } from "react-redux";
import { enforceInfoSelector } from "../../redux/enforce.selector";

// Components import
import CoverScoreKeyCardComponent from "../../../shared/components/cover-score-key-card/cover-score-key-card.component";
import EnforceCurrentstatusDataViewComponent from "../enforce-current-status-data-view/enforce-current-status-data-view.component";

/**
 * EnforceCurrentStatusComponent is a functional component that displays current status information for enforce page.
 * @component
 * @returns {React.JSX.Element}
 */

const EnforceCurrentStatusComponent = (): React.JSX.Element => {
    const enforceInfo = useSelector(enforceInfoSelector);

    return (
        <div className="container mx-auto">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    What has been achieved
                </h2>
            </div>
            <div className="grid grid-cols-3 justify-center my-10 mx-4">
                <div className="col-span-3 lg:col-span-1 lg:mr-8">
                    <p>
                        The groupings for the bans on tobacco advertising
                        promotion and sponsorship indicator are based on the
                        number of these following types of advertising that are
                        completely prohibited under legislation.
                    </p>
                </div>
                <div className="col-span-3 lg:col-span-2 lg:ml-8">
                    <div className="current-status-diagram">
                        <div className="grid grid-cols-5 justify-center current-color-box mb-8">
                            <CoverScoreKeyCardComponent
                                scoreKeyCardData={enforceInfo.enforcePropData}
                                divid="BlockCompress"
                            />
                        </div>
                    </div>
                    <EnforceCurrentstatusDataViewComponent />
                </div>
            </div>
        </div>
    );
};
export default EnforceCurrentStatusComponent;
