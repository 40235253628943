import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { useSelector } from "react-redux";
import {
    clearCountryInfoData,
    getCountryInfo,
    setCountryNameData,
    setIsCountryNotFound,
    setMatchedCountryCode,
    setSearchData,
} from "../../../country/redux/country.slice";
import { scrollSuggestionIntoView } from "../../../shared/utility/shared.utility";
import { CountryListInfoType } from "../../../country/redux/country.types";
import {
    countryListSelector,
    inputDataSelector,
    isCountryNotFoundSelector,
} from "../../../country/redux/country.selector";
import {
    ERROR_MESSAGE_FOR_LESS_CHARACTER,
    KEYBOARD_ARROW_DOWN,
    KEYBOARD_ARROW_UP,
    KEYBOARD_ENTER,
    VALIDATION_MESSAGE,
} from "../../../shared/constants/shared.contants";

import homeSearch from "../../../assets/icon/vector-search.svg";
import { toast } from "react-toastify";

interface CountrySearchComponentProps {
    inputRef?: React.RefObject<HTMLInputElement>;
    IsShowSearchIconTrue?: boolean;
    showFirstTimeErrorMessage?: boolean;
    widthClass?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    id?:string
    }

const CountrySearchComponent: React.FC<CountrySearchComponentProps> = ({
    inputRef,
    IsShowSearchIconTrue,
    showFirstTimeErrorMessage,
    widthClass,
    onFocus,
    onBlur,
    id,
}) => {
    const [suggestions, setSuggestions] = useState<CountryListInfoType[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [isShowErrorMessage, setIsShowErrorMessage] = useState<
        boolean | undefined
    >(false);

    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const inputData = useSelector(inputDataSelector);
    const countryList = useSelector(countryListSelector);
    const isCountryNotFound = useSelector(isCountryNotFoundSelector);

    const handleClick = (_selectedCountryTitle: string) => {
        let foundCountry;
        if (inputData?.length <= 2 && !IsShowSearchIconTrue) {
            if (isShowErrorMessage || showFirstTimeErrorMessage) {
                toast.error(ERROR_MESSAGE_FOR_LESS_CHARACTER);
            }
            setIsShowErrorMessage(true);
        }

        if (selectedIndex !== -1) {
            const _selectedCountryTitle =
                suggestions[selectedIndex].normalizedTitle;
            foundCountry = countryList?.find(
                (country: CountryListInfoType) =>
                    country?.normalizedTitle.toLowerCase() ===
                    _selectedCountryTitle?.toLowerCase(),
            );
        } else {
            foundCountry = countryList?.find(
                (country: CountryListInfoType) =>
                    country?.normalizedTitle?.toLowerCase() ===
                    inputData?.toLowerCase(),
            );
        }

        if (foundCountry) {
            dispatch(clearCountryInfoData());
            dispatch(getCountryInfo(foundCountry?.code.toLowerCase()));
            dispatch(setCountryNameData(foundCountry?.title));
            navigate("/country");
            dispatch(setSearchData(""));
            dispatch(setMatchedCountryCode(foundCountry?.code.toLowerCase()));
        }
    };

    const checkAndDispatchCountryNotFound = (suggestedCountries: []) => {
        if (suggestedCountries?.length === 0) {
            dispatch(setIsCountryNotFound(true));
        } else {
            dispatch(setIsCountryNotFound(false));
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const input = e.target.value;
        if (input?.length >= 3) {
            const initialLetterMatch: any[] = [];
            const otherMatches: any[] = [];
            const uniqueCountries = new Set();

            countryList?.forEach((country: CountryListInfoType) => {
                const countryTitleLower =
                    country?.normalizedTitle?.toLowerCase();
                const inputLower = input?.toLowerCase();

                if (
                    countryTitleLower.startsWith(inputLower) &&
                    !uniqueCountries.has(countryTitleLower)
                ) {
                    initialLetterMatch.push(country);
                    uniqueCountries.add(countryTitleLower);
                } else if (
                    countryTitleLower.includes(inputLower) &&
                    !uniqueCountries.has(countryTitleLower)
                ) {
                    otherMatches.push(country);
                    uniqueCountries.add(countryTitleLower);
                }
            });

            const sortedInitialLetterMatch = [...initialLetterMatch].sort(
                (a, b) => a.normalizedTitle.localeCompare(b.normalizedTitle),
            );

            const sortedOtherMatches = [...otherMatches].sort((a, b) =>
                a.normalizedTitle.localeCompare(b.normalizedTitle),
            );

            const suggestedCountries: any = [
                ...sortedInitialLetterMatch,
                ...sortedOtherMatches,
            ];

            checkAndDispatchCountryNotFound(suggestedCountries);
            setSuggestions(suggestedCountries);
        } else {
            setSuggestions([]);
            dispatch(setIsCountryNotFound(false));
        }
        dispatch(setSearchData(e.target.value));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KEYBOARD_ENTER) {
            e.preventDefault();
            handleClick(inputData);
        } else if (e.key === KEYBOARD_ARROW_DOWN) {
            e.preventDefault();
            const nextIndex = Math.min(
                suggestions?.length - 1,
                selectedIndex + 1,
            );
            setSelectedIndex(nextIndex);
            scrollSuggestionIntoView(nextIndex);
        } else if (e.key === KEYBOARD_ARROW_UP) {
            e.preventDefault();
            const nextIndex = Math.max(0, selectedIndex - 1);
            setSelectedIndex(nextIndex);
            scrollSuggestionIntoView(nextIndex);
        }
    };

    const handleSuggestionClick = (selectedCountry: CountryListInfoType) => {
        dispatch(setSearchData(selectedCountry?.normalizedTitle));
        setSuggestions([]);
        setSelectedIndex(-1);
        handleClick(selectedCountry?.normalizedTitle);
        if (inputRef?.current) {
            inputRef.current.focus();
            inputRef.current.value = "";
            inputRef.current.value = selectedCountry.normalizedTitle;

            inputRef.current.setSelectionRange(
                selectedCountry.normalizedTitle.length,
                selectedCountry.normalizedTitle.length,
            );
            inputRef.current.scrollLeft = inputRef.current.scrollWidth;
        }
    };

    useEffect(() => {
        if (inputData?.length === 0) {
            setSuggestions([]);
        }
    }, [inputData]);

    return (
        <>
            <form
                data-testid={`home-form`}
                onFocus={onFocus}
                onBlur={onBlur}
                onSubmit={(e) => {
                    e.preventDefault();
                    handleClick(inputData);
                }}
                className="form-control"
            >
                <div className="flex justify-center lg:mx-20 search slider-search rounded-full">
                    <input
                        ref={inputRef}
                        type="text"
                        className="w-full home-input-custom-h px-6 text-base"
                        value={inputData}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Search a country to begin"
                        data-testid={`home-input`}
                        id={`${id}-searchtext`}
                    />
                    <button
                        type="submit"
                        className={`p-2 rounded-md bg-white ${
                            IsShowSearchIconTrue ? "w-full" : ""
                        }`}
                        data-testid={`home-button`}
                        id={id}
                    >
                        <div className="flex justify-between text-sm font-semibold">
                            <div
                                className={`w-full
                                    ${IsShowSearchIconTrue ? "" : "seach-lable"}
                                    `}
                            >
                                Search a country to begin
                            </div>
                            <div className="self-center">
                                <img
                                    src={homeSearch}
                                    alt="search icon"
                                    className={`w-5  ${
                                        IsShowSearchIconTrue ? "mr-4" : "ml-4"
                                    }`}
                                />
                            </div>
                        </div>
                    </button>
                </div>
                {isCountryNotFound && (
                    <div className={`validation-box ${widthClass}`}  id="Ctryvalidation">
                        {VALIDATION_MESSAGE}
                    </div>
                )}
            </form>
            <div className="suggestions" data-testid={`suggestion-list`}>
                {suggestions?.length > 0 && (
                    <ul>
                        {suggestions.map((country, index) => (
                            <li
                                key={country?.code}
                                id={`suggestion-${index}`}
                                className={
                                    index === selectedIndex ? "selected" : ""
                                }
                                onClick={() => handleSuggestionClick(country)}
                                data-testid={`suggestion-item`}
                            >
                                {country?.title}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
};

export default CountrySearchComponent;
