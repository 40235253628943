// Components imports
import CountryNameComponent from "../../../country/components/country-name/country-name.component";
import MpowerScoreColorKeyComponent from "../../../country/components/mpower-score-key/mpower-score-key.component";
import CoverCardViewComponent from "../../../shared/components/cover-card-view/cover-card-view.component";

//Image or Icon or logo imports
import warnMassMediaImage from "../../../assets/icon/w-warn-campaign.png";

//Interface Import
import { CoverCardViewPropType } from "../../../shared/components/cover-card-view/cover-card-view-interface";

import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_DATA,
    STATUS_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";
import { useSelector } from "react-redux";
import { massMediaInfoSelector } from "../../redux/warn-mass-media.selector";
import TechnicalNoteLinkComponent from "../../../shared/components/technical-note-link-component";

/**
 * WarnMassCoverComponent is a functional component that displays Mass media antitobacco campaigns related information.
 * It includes a country name component, a cover card view component, and an Mpower score color key component.
 * @component
 * @returns {React.JSX.Element}
 */

const WarnMassCoverComponent = (): React.JSX.Element => {
    const massMediaInfo = useSelector(massMediaInfoSelector);

    let bgColor, statusText;

    switch (massMediaInfo["W-MM_Group"]) {
        case "1":
            bgColor = BG_COLOR_NO_DATA;
            statusText = STATUS_NO_DATA;
            break;
        case "2":
            bgColor = BG_COLOR_NO_REQUIREMENT;
            statusText = STATUS_NO_REQUIREMENT;
            break;
        case "3":
            bgColor = BG_COLOR_MINIMAL;
            statusText = STATUS_MINIMAL;
            break;
        case "4":
            bgColor = BG_COLOR_MODERATE;
            statusText = STATUS_MODERATE;
            break;
        case "5":
            bgColor = BG_COLOR_COMPLETE;
            statusText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    const coverCardPropData: CoverCardViewPropType = {
        img: warnMassMediaImage,
        alt: "mass-media-image",
        headingText: "Mass media anti-tobacco campaigns",
        coverCardPropData: massMediaInfo.massMediaCoverPropData,
        statusText: statusText,
        bgColor: bgColor,
        rightCardText: `Article 12 of the WHO FCTC states: “Each Party shall promote and strengthen public awareness of tobacco control issues, using all available communication tools, as appropriate. … each Party shall … promote … broad access to effective and comprehensive educational and public awareness programmes on the health risks including the addictive characteristics of tobacco consumption and exposure to tobacco smoke; … [Each party shall promote] public awareness about the risks of tobacco consumption and exposure to tobacco smoke, and about the benefits of the cessation of tobacco use and tobacco-free lifestyles; … [each party shall promote] public awareness of and access to information regarding the adverse health, economic, and environmental consequences of tobacco production and consumption”`,
    };

    return (
        <div className="container mx-auto">
            <CountryNameComponent
                contentElement={"Return to country results"}
                url={"/country"}
            />
            <CoverCardViewComponent coverCardViewData={coverCardPropData} />
            <MpowerScoreColorKeyComponent
                headingText={
                    <div>
                        The groupings for the mass media campaign indicator are
                        listed here. For more information on the criteria for
                        each measure please see the Technical Note:{" "}
                        <TechnicalNoteLinkComponent />
                    </div>
                }
                measureName="MASS MEDIA"
            />
        </div>
    );
};

export default WarnMassCoverComponent;
