// Components import
import CoverScoreKeyCardComponent from "../../../shared/components/cover-score-key-card/cover-score-key-card.component";
import ProtectCurrentstatusDataViewComponent from "../protect-current-status-data-view/protect-current-status-data-view.component";

import { useSelector } from "react-redux";
import { protectInfoSelector } from "../../redux/protect.selector";

/**
 * ProtectCurrentStatusComponent is a functional component that displays current status information for protect page.
 * @component
 * @returns {React.JSX.Element}
 */

const ProtectCurrentStatusComponent = (): React.JSX.Element => {
    const protectInfo = useSelector(protectInfoSelector);

    return (
        <div className="container mx-auto my-10">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    What has been achieved
                </h2>
            </div>
            <div className="grid grid-cols-3 justify-center my-10 mx-4 current-status">
                <div className="col-span-3 lg:col-span-1 lg:mr-8">
                    <p>
                        The groupings for the smoke-free legislation indicator
                        are based on the number of these eight places where
                        indoor smoking is completely prohibited. These eight
                        places are
                        <br />
                        1) Health care facilities
                        <br />
                        2) Educational facilities other than universities
                        <br />
                        3) Universities
                        <br />
                        4) Governmental facilities
                        <br />
                        5) Indoor offices and workplaces
                        <br />
                        6) Restaurants
                        <br />
                        7) Cafes, pubs and bars
                        <br />
                        8) Public transport
                        <br />
                        <br />
                        “Complete” is used to mean that smoking is not
                        permitted, with no exemptions allowed. Laws that allow
                        for the provision of smoking areas or designated smoking
                        rooms (DSRs) in public places and workplaces are not
                        effective at protecting people from second-hand smoke,
                        so where they are allowed, the places are not considered
                        smoke-free environments.
                    </p>
                </div>
                <div className="col-span-3 lg:col-span-2 lg:ml-8">
                    <div className="current-status-diagram">
                        <div className="grid grid-cols-5 justify-center current-color-box mb-8 ">
                            <CoverScoreKeyCardComponent
                                scoreKeyCardData={protectInfo.protectPropData}
                            />
                        </div>
                    </div>
                    <ProtectCurrentstatusDataViewComponent />
                </div>
            </div>
        </div>
    );
};
export default ProtectCurrentStatusComponent;
