import { useSelector } from "react-redux";
import { monitorInfoSelector } from "../../redux/monitor.selector";

// Components imports
import CountryNameComponent from "../../../country/components/country-name/country-name.component";
import MpowerScoreColorKeyComponent from "../../../country/components/mpower-score-key/mpower-score-key.component";
import CoverCardViewComponent from "../../../shared/components/cover-card-view/cover-card-view.component";

//Image or Icon or logo imports
import MonitorImage from "../../../assets/icon/m-monitor.png";

//Interface Import
import { CoverCardViewPropType } from "../../../shared/components/cover-card-view/cover-card-view-interface";

import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";
import TechnicalNoteLinkComponent from "../../../shared/components/technical-note-link-component";

/**
 * MonitorCoverComponent is a functional component that displays monitoring data.
 * It includes a country name component, a cover card view component, and an Mpower score color key component.
 * @component
 * @returns {React.JSX.Element}
 */

const MonitorCoverComponent = (): React.JSX.Element => {
    const monitorInfo = useSelector(monitorInfoSelector);

    let bgColor, statusText;

    switch (monitorInfo?.M_Group) {
        case "1":
            bgColor = BG_COLOR_NO_REQUIREMENT;
            statusText = STATUS_NO_REQUIREMENT;
            break;
        case "2":
            bgColor = BG_COLOR_MINIMAL;
            statusText = STATUS_MINIMAL;
            break;
        case "3":
            bgColor = BG_COLOR_MODERATE;
            statusText = STATUS_MODERATE;
            break;
        case "4":
            bgColor = BG_COLOR_COMPLETE;
            statusText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    const coverCardPropData: CoverCardViewPropType = {
        img: MonitorImage,
        alt: "Monitor-Image",
        headingText: "Monitoring tobacco use",
        statusText: statusText,
        bgColor: bgColor,
        coverCardPropData: monitorInfo.monitorPropData,
        rightCardText: `Article 20 of the WHO FCTC: " ... Parties shall establish ... surveillance of the magnitude, patterns, determinants and consequences of tobacco consumption and exposure to tobacco smoke ... Parties should integrate tobacco surveillance programmes into national, regional and global health surveillance programmes so that data are comparable and can be analysed at the regional and international levels ...”.`,
    };

    return (
        <div className="container mx-auto">
            <CountryNameComponent
                contentElement={"Return to country results"}
                url={"/country"}
                id="MPReturnbtn"
            />
            <CoverCardViewComponent coverCardViewData={coverCardPropData} />
            <MpowerScoreColorKeyComponent
                id="MonitorScore"
                headingText={
                    <div id="MPtext12">
                        For more information on the criteria for each measure
                        please see the Technical Note:{" "}
                        <TechnicalNoteLinkComponent />
                    </div>
                }
                footNoteText={
                    <div className="pt-10">
                        <ol>
                            <li>1.Data from the past 5 years</li>
                            <li>
                                2.Survey sample representative of the national
                                population.
                            </li>
                            <li>3.Collected at least every 5 years.</li>
                        </ol>
                    </div>
                }
                measureName="MONITOR"
            />
        </div>
    );
};

export default MonitorCoverComponent;
