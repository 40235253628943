import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../shared/components/rest-handler/rest-handler.service";
import * as WARNPACKAGEINFO_SLICE from "./warn-package.slice";
import { getProccessedWarnPackageInfo } from "./warn-package.utilities";

export function* getWarnPackageInfo(action: any): any {
    let url = `/WarnPackaging?countryCode=${action?.payload || ""}`;
    try {
        let warnPackageInfo = yield call(RestHandlerService.get, url, {});

        warnPackageInfo = getProccessedWarnPackageInfo(warnPackageInfo);

        yield put(
            WARNPACKAGEINFO_SLICE.getWarnPackageInfoSuccess(warnPackageInfo),
        );
    } catch (err) {
        yield put(WARNPACKAGEINFO_SLICE.getWarnPackageInfoError());
    }
}

export function* warnPackageInfoSaga() {
    yield takeLatest("warnPackageInfo/getWarnPackageInfo", getWarnPackageInfo);
}
