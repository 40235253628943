import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";

const countryCompareRegionInfoState = (state: RootState) => {
    return state.countryCompareRegionInfo;
};

export const countryCompareRegionInfoSelector = createSelector(
    [countryCompareRegionInfoState],
    (countryCompareRegionInfoState) => {
        return countryCompareRegionInfoState?.countryCompareRegionData || {};
    },
);
