import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../shared/components/rest-handler/rest-handler.service";
import * as OFFERINFO_SLICE from "./offer.slice";
import { getProccessedOfferInfo } from "./offer.utilities";

export function* getOfferInfo(action: any): any {
    let url = `/Offer?countryCode=${action?.payload || ""}`;
    try {
        let offerInfo = yield call(RestHandlerService.get, url, {});

        offerInfo = getProccessedOfferInfo(offerInfo);
        yield put(OFFERINFO_SLICE.getOfferInfoSuccess(offerInfo));
    } catch (err) {
        yield put(OFFERINFO_SLICE.getOfferInfoError());
    }
}

export function* offerInfoSaga() {
    yield takeLatest("offerInfo/getOfferInfo", getOfferInfo);
}
