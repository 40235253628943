import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootSagas from "./root.saga";
import { rootReducer } from "./root.reducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: ["sharedSlice"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [];

const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);

middleware.push(logger);

const enhancers = [...middleware];

export const store = configureStore({
    reducer: persistedReducer,
    middleware: enhancers,
});
sagaMiddleware.run(rootSagas);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
