import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";

const countryCompareInfoState = (state: RootState) => {
    return state.countryCompareInfo;
};

export const countryCompareDataSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.countryCompareData || {};
    },
);

export const countryCompareIdInfoSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.countryId || {};
    },
);

export const compareInputDataSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.compareInputData;
    },
);

export const comparePolicySelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.policyNumber;
    },
);

export const notCategorizedInfoSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.notCategorizedCountryList;
    },
);

export const noRequirementInfoSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.noRequirementCountryList;
    },
);

export const minimalRequirementsInfoSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.minimalRequirementsCountryList;
    },
);

export const moderateRequirementsInfoSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.moderateRequirementsCountryList;
    },
);

export const completeRequirementsInfoSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.completeRequirementsCountryList;
    },
);

export const isCountryCompareNotFoundSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.isCountryCompareNotFound;
    },
);

export const isButtonClickedSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.isButtonClicked;
    },
);

export const ShowCountriesCompareLoaderSelector = createSelector(
    [countryCompareInfoState],
    (countryCompareInfoState) => {
        return countryCompareInfoState?.showCountriesCompareLoader;
    },
);
