/**
 * The ActionsComponent is a React component that displays a set of action buttons.
 * @function ActionsComponent
 * @returns {React.JSX.Element}
 */

// Components imports
import ButtonComponent from "../../shared/components/button/button.component";

import {
    INITIATIVES_MPOWER_URL,
    IRIS_REPORT_URL,
} from "../../shared/constants/url.constants";

const ActionsComponent = (): React.JSX.Element => {
    return (
        <div className="col-span-10 md:col-span-10 text-center mb-20">
            <ButtonComponent
                contentElement={
                    <div>
                        Visit the <span className="font-semibold">MPOWER</span>{" "}
                        website
                    </div>
                }
                variantClass={"bg-primary"}
                url={INITIATIVES_MPOWER_URL}
                target={"_blank"}
                id={"btnHome01"}
            />

            <ButtonComponent
                contentElement={
                    <div>
                        Read the latest full{" "}
                        <span className="font-semibold">MPOWER</span> report
                    </div>
                }
                variantClass={"bg-primary"}
                url={IRIS_REPORT_URL}
                target={"_blank"}
                id={"btnHome02"}
            />
        </div>
    );
};

export default ActionsComponent;
