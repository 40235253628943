import { useSelector } from "react-redux";
import { protectInfoSelector } from "../../redux/protect.selector";

// Components import
import CountryNameComponent from "../../../country/components/country-name/country-name.component";
import MpowerScoreColorKeyComponent from "../../../country/components/mpower-score-key/mpower-score-key.component";
import CoverCardViewComponent from "../../../shared/components/cover-card-view/cover-card-view.component";

//Interface import
import { CoverCardViewPropType } from "../../../shared/components/cover-card-view/cover-card-view-interface";

//Image or Icon or logo imports
import ProtectImage from "../../../assets/icon/p-protect.png";
import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_DATA,
    STATUS_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";
import TechnicalNoteLinkComponent from "../../../shared/components/technical-note-link-component";

/**
 * ProtectCoverComponent is a functional component that displays Protect data.
 * @component
 * @returns {React.JSX.Element}
 */

const ProtectCoverComponent = (): React.JSX.Element => {
    const protectInfo = useSelector(protectInfoSelector);

    let bgColor, statusText;

    switch (protectInfo?.P_Group) {
        case "1":
            bgColor = BG_COLOR_NO_DATA;
            statusText = STATUS_NO_DATA;
            break;
        case "2":
            bgColor = BG_COLOR_NO_REQUIREMENT;
            statusText = STATUS_NO_REQUIREMENT;
            break;
        case "3":
            bgColor = BG_COLOR_MINIMAL;
            statusText = STATUS_MINIMAL;
            break;
        case "4":
            bgColor = BG_COLOR_MODERATE;
            statusText = STATUS_MODERATE;
            break;
        case "5":
            bgColor = BG_COLOR_COMPLETE;
            statusText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    const coverCardPropData: CoverCardViewPropType = {
        img: ProtectImage,
        alt: "Protect-Image",
        headingText: "Protect people from tobacco smoke",
        coverCardPropData: protectInfo.protectPropData,
        statusText: statusText,
        bgColor: bgColor,
        rightCardText: `Article 8 of the WHO FCTC: "... [S]cientific evidence has unequivocally established that exposure to tobacco smoke causes death, disease and disability... [Parties] shall adopt and implement ... measures providing for protection from exposure to tobacco smoke in indoor workplaces, public transport, indoor public places and, as appropriate, other public places”.`,
    };

    return (
        <div className="container mx-auto">
            <CountryNameComponent
                contentElement={"Return to country results"}
                url={"/country"}
            />
            <CoverCardViewComponent coverCardViewData={coverCardPropData} />
            <MpowerScoreColorKeyComponent
                headingText={
                    <div>
                        The groupings for the smoke-free legislation indicator
                        are listed here. For more information on the criteria
                        for each measure please see the Technical Note:{" "}
                        <TechnicalNoteLinkComponent />
                    </div>
                }
                measureName="PROTECT"
            />
        </div>
    );
};

export default ProtectCoverComponent;
