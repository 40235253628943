// Components imports
import RaiseTaxCurrentStatusBlockComponent from "../raise-tax-current-status-block/raise-tax-current-status-block.component";

/**
 * RaiseTaxCurrentstatusDataViewComponent is a functional component that displays status blocks and action buttons.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const RaiseTaxCurrentstatusDataViewComponent = (): React.JSX.Element => {
    return (
        <div className="rounded text-center border border-black p-8 mb-4">
            <RaiseTaxCurrentStatusBlockComponent />
        </div>
    );
};

export default RaiseTaxCurrentstatusDataViewComponent;
