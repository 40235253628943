// Components imports
import CountryNameComponent from "./country-name/country-name.component";
import CountryIndicatorComponent from "./country-indicator/country-indicator.component";
import MpowerScoreColorKeyComponent from "./mpower-score-key/mpower-score-key.component";
import MpowerBlockViewComponent from "./mpower-block-view/mpower-block-view.component";
import MpowerProgressMainComponent from "./mpower-progress-main/mpower-progress-main.component";
import WaterMarkComponent from "./water-mark/water-mark.component";
import TechnicalNoteLinkComponent from "../../shared/components/technical-note-link-component";

/**
 * CountryBodyComponent is responsible for rendering the main body section of the country page.
 * It includes the CountryNameComponent and CountryIndicatorComponent.
 * @function CountryBodyComponent
 * @returns {React.JSX.Element}
 */

const CountryBodyComponent = (): React.JSX.Element => {
    return (
        <div>
            <div className="container mx-auto">
                <CountryNameComponent
                    contentElement={"Search again for another country"}
                    url={"/"}
                    buttonWidth={"w-80"}
                    goToThirdCaraousel={true}
                    id={"countrybtns_01"}
                />
                <CountryIndicatorComponent />
                <MpowerScoreColorKeyComponent
                   id={"Mpowetext"}
                    headingText={
                        <div>
                            For more information on the criteria for each
                            measure please see the Technical Note:{" "}
                            <TechnicalNoteLinkComponent />
                        </div>
                    }
                    measureName="MPOWER"
                />
                <MpowerBlockViewComponent />
                <MpowerProgressMainComponent />
            </div>
            <WaterMarkComponent />
        </div>
    );
};

export default CountryBodyComponent;
