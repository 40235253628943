import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const massMediaInfoState = (state: RootState) => {
    return state.massMediaInfo;
};

export const massMediaInfoSelector = createSelector(
    [massMediaInfoState],
    (massMediaInfoState) => {
        return massMediaInfoState?.massMediaData || {};
    },
);
