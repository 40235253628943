//Image or Icon or logo imports
import MonitorImage from "../../assets/icon/m-monitor.png";
import ProtectImage from "../../assets/icon/p-protect.png";
import offerImage from "../../assets/icon/o-offer.png";
import WarnPackageImage from "../../assets/icon/w-warn-package.png";
import WarnCampaignImage from "../../assets/icon/w-warn-campaign.png";
import EnforceImage from "../../assets/icon/e-enforce.png";
import RaiseImage from "../../assets/icon/r-raise.png";

import { CountryIndicatorType } from "./country.types";

import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_DATA,
    STATUS_NO_REQUIREMENT,
} from "../../shared/constants/shared.contants";
import {
    NAME_ENFORCE,
    NAME_MONITOR,
    NAME_OFFER,
    NAME_PROTECT,
    NAME_RAISE,
    NAME_WARNCAMPAIGN,
    NAME_WARNPACKAGE,
} from "../country.constants";

export const setIndicatortitle = (
    indicatorCode: string,
    indicatorInfo: CountryIndicatorType[],
) => {
    const indicatorData = indicatorInfo.find((indicator: { code: string }) => {
        return indicator.code === indicatorCode;
    });
    return indicatorData ? indicatorData.title : "";
};

export function determineBgclass(scoreKey: string) {
    let bgClass;

    switch (scoreKey) {
        case "1":
            bgClass = BG_COLOR_NO_DATA;
            break;
        case "2":
            bgClass = BG_COLOR_NO_REQUIREMENT;
            break;
        case "3":
            bgClass = BG_COLOR_MINIMAL;
            break;
        case "4":
            bgClass = BG_COLOR_MODERATE;
            break;
        case "5":
            bgClass = BG_COLOR_COMPLETE;
            break;
        default:
            break;
    }

    return bgClass;
}

export function determineButtonText(scoreKey: string) {
    let buttonText;

    switch (scoreKey) {
        case "1":
            buttonText = STATUS_NO_DATA;
            break;
        case "2":
            buttonText = STATUS_NO_REQUIREMENT;
            break;
        case "3":
            buttonText = STATUS_MINIMAL;
            break;
        case "4":
            buttonText = STATUS_MODERATE;
            break;
        case "5":
            buttonText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    return buttonText;
}

export function determineMonitorBgclass(scoreKey: string) {
    let bgClass;

    switch (scoreKey) {
        case "1":
            bgClass = BG_COLOR_NO_REQUIREMENT;
            break;
        case "2":
            bgClass = BG_COLOR_MINIMAL;
            break;
        case "3":
            bgClass = BG_COLOR_MODERATE;
            break;
        case "4":
            bgClass = BG_COLOR_COMPLETE;
            break;
        default:
            break;
    }

    return bgClass;
}

export function determineMonitorButtonText(scoreKey: string) {
    let buttonText;

    switch (scoreKey) {
        case "1":
            buttonText = STATUS_NO_REQUIREMENT;
            break;
        case "2":
            buttonText = STATUS_MINIMAL;
            break;
        case "3":
            buttonText = STATUS_MODERATE;
            break;
        case "4":
            buttonText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    return buttonText;
}

export const setMpowerMonitorData = (
    countryGroup: string,
    indicatorInfo: CountryIndicatorType[],
) => {
    return {
        name: NAME_MONITOR,
        img: MonitorImage,
        alt: "M-monitor",
        paraText: setIndicatortitle("M_Group", indicatorInfo),
        url: "/monitor",
        initial: "M",
        finalWord: "Monitor",
        buttonText: determineMonitorButtonText(countryGroup),
        buttonBgColor: determineMonitorBgclass(countryGroup),
        countryGroup: countryGroup,
    };
};

export const setMpowerProtectData = (
    countryGroup: string,
    indicatorInfo: CountryIndicatorType[],
) => {
    return {
        name: NAME_PROTECT,
        img: ProtectImage,
        alt: "P-protect",
        paraText: setIndicatortitle("P_Group", indicatorInfo),
        url: "/protect",
        initial: "P",
        finalWord: "Protect",
        buttonText: determineButtonText(countryGroup),
        buttonBgColor: determineBgclass(countryGroup),
        countryGroup: countryGroup,
    };
};

export const setMpowerOfferData = (
    countryGroup: string,
    indicatorInfo: CountryIndicatorType[],
) => {
    return {
        name: NAME_OFFER,
        img: offerImage,
        alt: "O-offer",
        paraText: setIndicatortitle("O_Group", indicatorInfo),
        url: "/offer",
        initial: "O",
        finalWord: "Offer",
        buttonText: determineButtonText(countryGroup),
        buttonBgColor: determineBgclass(countryGroup),
        countryGroup: countryGroup,
    };
};

export const setMpowerWarnPackageData = (
    countryGroup: string,
    indicatorInfo: CountryIndicatorType[],
) => {
    return {
        name: NAME_WARNPACKAGE,
        img: WarnPackageImage,
        alt: "W-warn-package",
        paraText: setIndicatortitle("W_Group", indicatorInfo),
        url: "/warnpackage",
        initial: "W",
        finalWord: "Warn",
        buttonText: determineButtonText(countryGroup),
        buttonBgColor: determineBgclass(countryGroup),
        countryGroup: countryGroup,
    };
};

export const setMpowerWarnCampaignData = (
    countryGroup: string,
    indicatorInfo: CountryIndicatorType[],
) => {
    return {
        name: NAME_WARNCAMPAIGN,
        img: WarnCampaignImage,
        alt: "W-warn-campaign",
        paraText: setIndicatortitle("W-MM_Group", indicatorInfo),
        url: "/massmedia",
        initial: "W",
        finalWord: "Warn",
        buttonText: determineButtonText(countryGroup),
        buttonBgColor: determineBgclass(countryGroup),
        countryGroup: countryGroup,
    };
};

export const setMpowerEnforceData = (
    countryGroup: string,
    indicatorInfo: CountryIndicatorType[],
) => {
    return {
        name: NAME_ENFORCE,
        img: EnforceImage,
        alt: "E-enforce",
        paraText: setIndicatortitle("E_Group", indicatorInfo),
        url: "/enforce",
        initial: "E",
        finalWord: "Enforce",
        buttonText: determineButtonText(countryGroup),
        buttonBgColor: determineBgclass(countryGroup),
        countryGroup: countryGroup,
    };
};

export const setMpowerRaiseTaxData = (
    countryGroup: string,
    indicatorInfo: CountryIndicatorType[],
) => {
    return {
        name: NAME_RAISE,
        img: RaiseImage,
        alt: "R-raise-tax",
        paraText: setIndicatortitle("R_Group", indicatorInfo),
        url: "/raisetax",
        initial: "R",
        finalWord: "Raise Tax",
        buttonText: determineButtonText(countryGroup),
        buttonBgColor: determineBgclass(countryGroup),
        countryGroup: countryGroup,
    };
};

export const getProccessedCountryInfo = (countryInfo: any) => {
    countryInfo.monitorBlockPropData = setMpowerMonitorData(
        countryInfo.monitoring,
        countryInfo.indicators,
    );
    countryInfo.protectBlockPropData = setMpowerProtectData(
        countryInfo.protect,
        countryInfo.indicators,
    );
    countryInfo.offerBlockPropData = setMpowerOfferData(
        countryInfo.offer,
        countryInfo.indicators,
    );
    countryInfo.warnPackageBlockPropData = setMpowerWarnPackageData(
        countryInfo.warnPackaging,
        countryInfo.indicators,
    );
    countryInfo.massMediaBlockPropData = setMpowerWarnCampaignData(
        countryInfo.warnMassMedia,
        countryInfo.indicators,
    );
    countryInfo.enforceBlockPropData = setMpowerEnforceData(
        countryInfo.enforce,
        countryInfo.indicators,
    );
    countryInfo.raiseTaxBlockPropData = setMpowerRaiseTaxData(
        countryInfo.raiseTax,
        countryInfo.indicators,
    );
    return countryInfo;
};
