import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as types from "./warn-package.types";

export const initialState: types.InitialState = {
    warnPackageData: {
        W_Group: "",
        indicators: [
            {
                code: "",
                title: "",
            },
        ],
        W1_HW_law_A: "",
        W2_pc_front_back_A: "",
        W3_pc_front_A: "",
        W4_pc_back_A: "",
        W5_top_side_A: "",
        W6_font_A: "",
        W7_rotation_A: "",
        W8_princ_langs_A: "",
        W9_no_obscure_A: "",
        W10_graphic_A: "",
        W11_outside_pack_A: "",
        W12_imports_dutyfree_A: "",
        W13_ind_liability_A: "",
        W14_harm_effects_A: "",
        W15_specific_hw_A: "",
        W17_fines_A: "",
        W18_misleading_terms_A: "",
        W19_colours_numbers_A: "",
        W20_flavours_A: "",
        W21_emissions_quant_A: "",
        W25_quitline_number_A: "",
        W26_plain_packaging_A: "",
        W1_HW_law_B: "",
        W2_pc_front_back_B: "",
        W3_pc_front_B: "",
        W4_pc_back_B: "",
        W5_top_side_B: "",
        W6_font_B: "",
        W7_rotation_B: "",
        W8_princ_langs_B: "",
        W9_no_obscure_B: "",
        W10_graphic_B: "",
        W11_outside_pack_B: "",
        W12_imports_dutyfree_B: "",
        W13_ind_liability_B: "",
        W14_harm_effects_B: "",
        W15_specific_hw_B: "",
        W17_fines_B: "",
        W18_misleading_terms_B: "",
        W19_colours_numbers_B: "",
        W20_flavours_B: "",
        W21_emissions_quant_B: "",
        W25_quitline_number_B: "",
        W26_plain_packaging_B: "",
        W1_HW_law_C: "",
        W2_pc_front_back_C: "",
        W3_pc_front_C: "",
        W4_pc_back_C: "",
        W5_top_side_C: "",
        W6_font_C: "",
        W7_rotation_C: "",
        W8_princ_langs_C: "",
        W9_no_obscure_C: "",
        W10_graphic_C: "",
        W11_outside_pack_C: "",
        W12_imports_dutyfree_C: "",
        W13_ind_liability_C: "",
        W14_harm_effects_C: "",
        W15_specific_hw_C: "",
        W17_fines_C: "",
        W18_misleading_terms_C: "",
        W19_colours_numbers_C: "",
        W20_flavours_C: "",
        W21_emissions_quant_C: "",
        W25_quitline_number_C: "",
        W26_plain_packaging_C: "",
        year: "",
        warnPackageCoverPropData: [],
        warnPackageCurrentPropData: [],
        warnPackageDBPropData: [],
    },
};

const warnPackageInfo = createSlice({
    name: "warnPackageInfo",
    initialState,
    reducers: {
        getWarnPackageInfo: (state, _action) => {
            return { ...state };
        },

        getWarnPackageInfoSuccess: (
            state,
            action: PayloadAction<types.WarnPackageDataInfoType>,
        ) => {
            return {
                ...state,
                warnPackageData: action.payload,
            };
        },

        getWarnPackageInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    getWarnPackageInfo,
    getWarnPackageInfoSuccess,
    getWarnPackageInfoError,
} = warnPackageInfo.actions;

export default warnPackageInfo.reducer;
