import { useSelector } from "react-redux";
import { offerInfoSelector } from "../../redux/offer.selector";

// Components import
import CoverScoreKeyCardComponent from "../../../shared/components/cover-score-key-card/cover-score-key-card.component";
import OfferCurrentstatusDataViewComponent from "../offer-current-status-data-view/offer-current-status-data-view.component";

/**
 * OfferCurrentStatusComponent is a functional component that displays current status information for offer page.
 * @component
 * @returns {React.JSX.Element}
 */

const OfferCurrentStatusComponent = (): React.JSX.Element => {
    const offerInfo = useSelector(offerInfoSelector);

    return (
        <div className="container mx-auto my-10">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    What has been achieved
                </h2>
            </div>
            <div className="grid grid-cols-3 justify-center my-10 mx-4">
                <div className="col-span-3 lg:col-span-1 lg:mr-8">
                    <p>
                        The groupings for the offer to help quit indicator are
                        based on the availability of smoking cessation support
                        in healthcare settings, a national toll-free quitline
                        and cost-covered nicotine replacement therapies.{" "}
                    </p>
                </div>
                <div className="col-span-3 lg:col-span-2 lg:ml-8">
                    <div className="current-status-diagram">
                        <div className="grid grid-cols-5 justify-center current-color-box mb-8">
                            <CoverScoreKeyCardComponent
                                scoreKeyCardData={offerInfo.offerPropData}
                            />
                        </div>
                    </div>
                    <OfferCurrentstatusDataViewComponent />
                </div>
            </div>
        </div>
    );
};
export default OfferCurrentStatusComponent;
