import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const protectInfoState = (state: RootState) => {
    return state.protectInfo;
};

export const protectInfoSelector = createSelector(
    [protectInfoState],
    (protectInfoState) => {
        return protectInfoState?.protectData || {};
    },
);
