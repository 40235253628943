// Components imports
import CountriesCompareComponent from "../../shared/components/countries-compare/components/countries-compare.component";
import HelpfullResourcesComponent from "../../shared/components/helpful-resources/components/helpful-resources.component";
import SharedActionComponent from "../../shared/components/shared-action/shared-action.component";
import WarnPackageDetailedBreakdownComponent from "./warn-package-detailed-breakdown/warn-package-detailed-breakdown.component";
import WarnPackageCoverComponent from "./warn-package-cover/warn-package-cover.component";
import WarnPackageCurrentStatusComponent from "./warn-package-current-status/warn-package-current-status.component";
import WarnPackageSuccessStoryComponent from "./warn-package-success-story/warn-package-success-story.component";
import CountriesRegionComponent from "../../shared/components/countries-compare-region/countries-compare-region.component";

/**
 * WarnPackageBodyComponent is responsible for rendering the main body section of the warn package page.
 * @function MonitorBodyComponent
 * @returns {React.JSX.Element}
 */

const WarnPackageBodyComponent = (): React.JSX.Element => {
    return (
        <div className="warn-package-page">
            <WarnPackageCoverComponent />
            <WarnPackageCurrentStatusComponent />
            <WarnPackageDetailedBreakdownComponent />
            <CountriesRegionComponent measureName="WARN" />
            <CountriesCompareComponent />
            <WarnPackageSuccessStoryComponent />
            <SharedActionComponent />
            <HelpfullResourcesComponent />
        </div>
    );
};

export default WarnPackageBodyComponent;
