import React from "react";

// Components imports
import MonitorDetailedBdBlockComponent from "../monitor-detailed-bd-block/monitor-detailed-bd-block.component";

//Interface import
import { MonitorDBTableProps } from "./monitor-detailed-table-interface";

/**
 * MonitorDetailedTableComponent is a functional component that displays detailed tables with data related to adults and youth.
 *
 * @component
 * @param {MonitorDBTableProps} props
 * @returns {React.JSX.Element}
 */

const MonitorDetailedTableComponent = (
    props: MonitorDBTableProps,
): React.JSX.Element => {
    return (
        <>
            {props.tableData.map((table) => (
                <React.Fragment key={table.id}>
                    <div className="col-span-2 md:col-span-1 text-center m-4 mb-4 detailed-table">
                        <div className="grid grid-row-4 rounded-lg overflow-hidden">
                            <div className="bg-black table-head">
                                <p className="text-white p-3">
                                    {table.tableHeading}
                                </p>
                            </div>
                            <MonitorDetailedBdBlockComponent
                                blockData={table.tablePropData}
                            />
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};
export default MonitorDetailedTableComponent;
