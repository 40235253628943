import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const offerInfoState = (state: RootState) => {
    return state.offerInfo;
};

export const offerInfoSelector = createSelector(
    [offerInfoState],
    (offerInfoState) => {
        return offerInfoState?.offerData || {};
    },
);
