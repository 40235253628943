import { useSelector } from "react-redux";
import { offerInfoSelector } from "../../redux/offer.selector";

// Components imports
import OfferCurrentStatusBlockListComponent from "../offer-current-status-block-list/offer-current-status-block-list.component";
import OfferCurrentStatusBlockSupportComponent from "../offer-current-status-block-support/offer-current-status-block-support.component";

/**
 * ProtectCurrentstatusDataViewComponent is a functional component that displays offer data.
 * @component
 * @returns {React.JSX.Element}
 */

const OfferCurrentstatusDataViewComponent = (): React.JSX.Element => {
    const offerInfo = useSelector(offerInfoSelector);

    return (
        <div className="rounded text-center border border-black p-8 mb-4">
            <div className="grid grid-cols-1 sm:grid-cols-2">
                <OfferCurrentStatusBlockListComponent
                    blockData={offerInfo.offerstatusListData}
                />
            </div>
            <div className="grid grid-cols-1 bg-blue p-4 rounded-lg mx-4 mt-8">
                <p>
                    At least one of the following should be{" "}
                    <span className="font-semibold">Partially</span> or{" "}
                    <span className="font-semibold">Fully</span> cost-covered
                </p>
            </div>
            <OfferCurrentStatusBlockSupportComponent
                blockData={offerInfo.offerstatusSupportData}
            />
        </div>
    );
};

export default OfferCurrentstatusDataViewComponent;
