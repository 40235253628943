import React from "react";
import { SCORE_PILL_TYPE } from "../../constants/shared.contants";

/**
 * CoverScoreKeyCardItemComponent is a functional component that displays score key cards.
 *
 * @component
 * @param {CoverScoreKeyCardProps} props
 * @returns {React.JSX.Element}
 */

const CoverScoreKeyCardItemComponent = (props: {
    cardId: string;
    type: SCORE_PILL_TYPE;
    cardBgColor: string;
    isLastExpanded: SCORE_PILL_TYPE;
    divId?:string;
}): React.JSX.Element => {
    const { cardId, type, cardBgColor, isLastExpanded, divId} = { ...props };
    let classname, lastExpandedClass;
    if (type === "big") {
        classname = "coverScoreBlockExpanded";
    } else {
        classname = "coverScoreBlockCompressed";
    }

    if (isLastExpanded === "big") {
        lastExpandedClass = "lastExpanded";
    } else {
        lastExpandedClass = "";
    }
    return (
        <React.Fragment key={cardId}>
            <div
                className={`col-span-1 p-2 ${classname} ${lastExpandedClass} m-2 rounded-full text-center border border-black monitor-color-box ${cardBgColor}`}
                data-testid={`score-key-card-${cardId}`}
                id={divId}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 256 512"
                >
                    <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                </svg>
                <div className="point"></div>
            </div>
        </React.Fragment>
    );
};

export default CoverScoreKeyCardItemComponent;
