import React from "react";
import { CompareRegionDataProps } from "./countries-compare-region-block-interface";
import { getTextColorClass } from "../../../utility/shared.utility";

/**
 * CountriesCompareRegionalBlockComponent is a functional component that displays and manages the categorization of countries
 * into different requirement categories.
 *
 * @component
 * @param {CompareRegionDataProps} props
 */

const CountriesCompareRegionalBlockComponent = (
    props: CompareRegionDataProps,
) => {
    const { compareRegionData } = props;

    return (
        <>
            {compareRegionData?.map((card) => (
                <React.Fragment key={card?.id}>
                    <div className="col-span-5 md:col-span-1 countries-color-block">
                        <div
                            className={`flex shadow px-2 compare-region ${
                                card.cardBgColor
                            } ${getTextColorClass(card?.cardBgColor)}`}
                            id={`cardBgColors${card.cardBgColor}`}
                        >
                            <div className="card-title">
                                <p id={`${card.id}-paraText`}>{card?.paraText}</p>
                            </div>
                            <div className="card-value">
                                <div>
                                    <p id={`${card.id}-countryCount`}>{card?.CountryCount}</p>
                                </div>
                                <div>
                                    <p id={`${card.id}-countries-label`}>(countries)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

export default CountriesCompareRegionalBlockComponent;
