import React from "react";

//Image or Icon or logo imports
import checkTick from "../../../assets/icon/check-box.png";
import emptyCheckBox from "../../../assets/icon/empty-check-box.png";

//Interface Import
import { OfferStatusBlockSupportProps } from "./offer-current-status-block-support-interface";

/**
 * OfferCurrentStatusBlockSupportComponent is a React component that displays a list of status blocks with support information.
 *
 * @component
 * @param {OfferStatusBlockSupportProps} props
 * @returns {React.JSX.Element}
 */

const OfferCurrentStatusBlockSupportComponent = (
    props: OfferStatusBlockSupportProps,
): React.JSX.Element => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 justify-center mt-4 mx-4 block-support">
            {props.blockData.map((block) => (
                <React.Fragment key={block.id}>
                    <div
                        className="col-span-1 md:flex justify-center items-center border rounded shadow"
                        data-testid={`offer-status-block`}
                    >
                        <img
                            src={block.img}
                            alt={block.alt}
                            className="w-24 h-24 m-auto"
                        />
                        <div className="flex justify-between gap-2 w-full bg-gray-100 p-2 pr-4">
                            <p
                                className={`w-11/12 ${
                                    block.result ? "font-bold" : ""
                                }`}
                            >
                                {block.text}
                            </p>
                            <img
                                src={block.result ? checkTick : emptyCheckBox}
                                alt={
                                    block.result
                                        ? "check-tick"
                                        : "empty-check-box"
                                }
                                className="w-8 h-8 self-center"
                            />
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default OfferCurrentStatusBlockSupportComponent;
