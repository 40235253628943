import { commonCoverPropData } from "../../shared/constants/shared.contants";
import { EnforceDataInfoType } from "./enforce.types";

//Image or Icon or logo imports
import television from "../../assets/icon/television.png";
import newsPaper from "../../assets/icon/news-paper.png";
import billboard from "../../assets/icon/billboard.png";
import saleMeter from "../../assets/icon/sale-meter.png";
import mail from "../../assets/icon/mail.png";
import brokeLabel from "../../assets/icon/broke-label.png";
import nonTobaccoProduct from "../../assets/icon/non-tobacco-product.png";
import can from "../../assets/icon/can.png";
import enforceTv from "../../assets/icon/enforce-tv.png";
import helmet from "../../assets/icon/helmet.png";

export const setEnforcePropData = (enforceInfo: EnforceDataInfoType) => {
    let groupNumber = parseInt(enforceInfo.E_Group);
    const enforceData = [...commonCoverPropData];
    for (let i = 0; i < groupNumber; i++) {
        enforceData[i] = { ...enforceData[i], meets: true };
    }

    for (let i = 1; i <= groupNumber; i++) {
        if (i === groupNumber) {
            enforceData[i - 1] = {
                ...enforceData[i - 1],
                lastExpanded: true,
            };
        }
    }

    return enforceData;
};

const setIndicatortitle = (
    indicatorCode: string,
    enforceInfo: EnforceDataInfoType,
) => {
    const indicatorData = enforceInfo.indicators.find(
        (indicator: { code: string }) => {
            return indicator.code === indicatorCode;
        },
    );
    return indicatorData ? indicatorData.title : "";
};

export const setEnforceStatusPropData = (enforceInfo: EnforceDataInfoType) => {
    return [
        {
            id: "01",
            img: television,
            alt: "television",
            title: setIndicatortitle("E1_nat_tv_radio", enforceInfo),
            result: enforceInfo.E1_nat_tv_radio,
        },
        {
            id: "02",
            img: newsPaper,
            alt: "news-paper",
            title: setIndicatortitle("E3_nat_print", enforceInfo),
            result: enforceInfo.E3_nat_print,
        },
        {
            id: "03",
            img: billboard,
            alt: "billboard",
            title: setIndicatortitle("E5_billboards", enforceInfo),
            result: enforceInfo.E5_billboards,
        },
        {
            id: "04",
            img: saleMeter,
            alt: "sale-meter",
            title: setIndicatortitle("E6a_pt_of_sale", enforceInfo),
            result: enforceInfo.E6a_pt_of_sale,
        },
        {
            id: "05",
            img: mail,
            alt: "mail",
            title: setIndicatortitle("E9_free_distrib", enforceInfo),
            result: enforceInfo.E9_free_distrib,
        },
        {
            id: "06",
            img: brokeLabel,
            alt: "broke-label",
            title: setIndicatortitle("E10_promo_discounts", enforceInfo),
            result: enforceInfo.E10_promo_discounts,
        },
        {
            id: "07",
            img: nonTobaccoProduct,
            alt: "non-tobacco-product",
            title: setIndicatortitle("E11_brand_stretching", enforceInfo),
            result: enforceInfo.E11_brand_stretching,
        },
        {
            id: "08",
            img: can,
            alt: "can",
            title: setIndicatortitle("E12_brand_sharing", enforceInfo),
            result: enforceInfo.E12_brand_sharing,
        },
        {
            id: "09",
            img: enforceTv,
            alt: "enforce-tv",
            title: setIndicatortitle("E13_brand_placement", enforceInfo),
            result: enforceInfo.E13_brand_placement,
        },
        {
            id: "10",
            img: helmet,
            alt: "helmet",
            title: setIndicatortitle("E15a_sponsorship", enforceInfo),
            result: enforceInfo.E15a_sponsorship,
        },
    ];
};

export const setEnforceDBPropData = (enforceInfo: EnforceDataInfoType) => {
    const setResult = (resultInfo: boolean) => {
        if (resultInfo) {
            return "Yes";
        } else {
            return "No";
        }
    };

    return [
        {
            id: 1,
            heading: setIndicatortitle("E1_nat_tv_radio", enforceInfo),
            result: setResult(enforceInfo.E1_nat_tv_radio),
            meets: enforceInfo.E1_nat_tv_radio,
        },
        {
            id: 2,
            heading: setIndicatortitle("E2_intl_tv_radio", enforceInfo),
            result: setResult(enforceInfo.E2_intl_tv_radio),
            meets: enforceInfo.E2_intl_tv_radio,
        },
        {
            id: 3,
            heading: setIndicatortitle("E3_nat_print", enforceInfo),
            result: setResult(enforceInfo.E3_nat_print),
            meets: enforceInfo.E3_nat_print,
        },
        {
            id: 4,
            heading: setIndicatortitle("E4_intl_print", enforceInfo),
            result: setResult(enforceInfo.E4_intl_print),
            meets: enforceInfo.E4_intl_print,
        },
        {
            id: 5,
            heading: setIndicatortitle("E5_billboards", enforceInfo),
            result: setResult(enforceInfo.E5_billboards),
            meets: enforceInfo.E5_billboards,
        },
        {
            id: 6,
            heading: setIndicatortitle("E6a_pt_of_sale", enforceInfo),
            result: setResult(enforceInfo.E6a_pt_of_sale),
            meets: enforceInfo.E6a_pt_of_sale,
        },
        {
            id: 7,
            heading: setIndicatortitle("E7_internet", enforceInfo),
            result: setResult(enforceInfo.E7_internet),
            meets: enforceInfo.E7_internet,
        },
        {
            id: 8,
            heading: setIndicatortitle("E21a_direct_ad_fines", enforceInfo),
            result: setResult(enforceInfo.E21a_direct_ad_fines),
            meets: enforceInfo.E21a_direct_ad_fines,
        },
        {
            id: 9,
            heading: setIndicatortitle("E9_free_distrib", enforceInfo),
            result: setResult(enforceInfo.E9_free_distrib),
            meets: enforceInfo.E9_free_distrib,
        },
        {
            id: 10,
            heading: setIndicatortitle("E10_promo_discounts", enforceInfo),
            result: setResult(enforceInfo.E10_promo_discounts),
            meets: enforceInfo.E10_promo_discounts,
        },
        {
            id: 11,
            heading: setIndicatortitle("E11_brand_stretching", enforceInfo),
            result: setResult(enforceInfo.E11_brand_stretching),
            meets: enforceInfo.E11_brand_stretching,
        },
        {
            id: 12,
            heading: setIndicatortitle("E12_brand_sharing", enforceInfo),
            result: setResult(enforceInfo.E12_brand_sharing),
            meets: enforceInfo.E12_brand_sharing,
        },
        {
            id: 13,
            heading: setIndicatortitle("E13_brand_placement", enforceInfo),
            result: setResult(enforceInfo.E13_brand_placement),
            meets: enforceInfo.E13_brand_placement,
        },
        {
            id: 14,
            heading: setIndicatortitle("E14a_prod_tv_films", enforceInfo),
            result: setResult(enforceInfo.E14a_prod_tv_films),
            meets: enforceInfo.E14a_prod_tv_films,
        },
        {
            id: 15,
            heading: setIndicatortitle("E14b_movies_anti_tob_ads", enforceInfo),
            result: setResult(enforceInfo.E14b_movies_anti_tob_ads),
            meets: enforceInfo.E14b_movies_anti_tob_ads,
        },
        {
            id: 16,
            heading: setIndicatortitle("E15a_sponsorship", enforceInfo),
            result: setResult(enforceInfo.E15a_sponsorship),
            meets: enforceInfo.E15a_sponsorship,
        },
        {
            id: 17,
            heading: setIndicatortitle(
                "E15b_sponsor_contribution",
                enforceInfo,
            ),
            result: setResult(enforceInfo.E15b_sponsor_contribution),
            meets: enforceInfo.E15b_sponsor_contribution,
        },
        {
            id: 18,
            heading: setIndicatortitle("E15c_sponsor_publicity", enforceInfo),
            result: setResult(enforceInfo.E15c_sponsor_publicity),
            meets: enforceInfo.E15c_sponsor_publicity,
        },
        {
            id: 19,
            heading: setIndicatortitle("E21b_indirect_ad_fines", enforceInfo),
            result: setResult(enforceInfo.E21b_indirect_ad_fines),
            meets: enforceInfo.E21b_indirect_ad_fines,
        },
        {
            id: 20,
            heading: setIndicatortitle("E17a_csr_ban", enforceInfo),
            result: setResult(enforceInfo.E17a_csr_ban),
            meets: enforceInfo.E17a_csr_ban,
        },
        {
            id: 21,
            heading: setIndicatortitle("E17b_csr_promo_self", enforceInfo),
            result: setResult(enforceInfo.E17b_csr_promo_self),
            meets: enforceInfo.E17b_csr_promo_self,
        },
        {
            id: 22,
            heading: setIndicatortitle("E17c_csr_promo_others", enforceInfo),
            result: setResult(enforceInfo.E17c_csr_promo_others),
            meets: enforceInfo.E17c_csr_promo_others,
        },
        {
            id: 23,
            heading: setIndicatortitle(
                "E18_csr_anti_tobacco_media",
                enforceInfo,
            ),
            result: setResult(enforceInfo.E18_csr_anti_tobacco_media),
            meets: enforceInfo.E18_csr_anti_tobacco_media,
        },
        {
            id: 24,
            heading: setIndicatortitle(
                "E6b_ban_display_pt_of_sale",
                enforceInfo,
            ),
            result: setResult(enforceInfo.E6b_ban_display_pt_of_sale),
            meets: enforceInfo.E6b_ban_display_pt_of_sale,
        },

        {
            id: 25,
            heading: setIndicatortitle("E23_vending_machines", enforceInfo),
            result: setResult(enforceInfo.E23_vending_machines),
            meets: enforceInfo.E23_vending_machines,
        },
        {
            id: 26,
            heading: setIndicatortitle("E24_internet_sales_ban", enforceInfo),
            result: setResult(enforceInfo.E24_internet_sales_ban),
            meets: enforceInfo.E24_internet_sales_ban,
        },
    ];
};

export const getProccessedEnforceInfo = (enforceInfo: EnforceDataInfoType) => {
    enforceInfo.enforcePropData = setEnforcePropData(enforceInfo);
    enforceInfo.enforceStatusBlockPropData =
        setEnforceStatusPropData(enforceInfo);
    enforceInfo.enforceDBPropData = setEnforceDBPropData(enforceInfo);

    return enforceInfo;
};
