import { useSelector } from "react-redux";
import { protectInfoSelector } from "../../redux/protect.selector";

// Components imports
import ButtonComponent from "../../../shared/components/button/button.component";
import NoDataMessageComponent from "../../../shared/components/no-data-message/no-data-message.component";

//Interface import
import { ProtectDetailedBreakdownPropType } from "./protect-detailed-breakdown-interface";
import { useEffect, useState } from "react";

/**
 * ProtectDetailedBreakdownComponent is a functional component that displays a detailed breakdown data.
 *
 * @component

 * @returns {React.JSX.Element}
 */

const ProtectDetailedBreakdownComponent = (): React.JSX.Element => {
    const protectInfo = useSelector(protectInfoSelector);

    const [propData, setPropData] = useState<
        ProtectDetailedBreakdownPropType[]
    >([]);
    const [improvedClass, setImprovedClass] = useState("bg-yellow");
    const [achievedClass, setAchievedClass] = useState("bg-primary-border");

    useEffect(() => {
        const initialData = protectInfo?.protectDBPropData.filter(
            (data) => !data?.meets,
        );
        setPropData(initialData);
    }, [protectInfo?.protectDBPropData]);

    const setImprovePropData = () => {
        const newData = protectInfo?.protectDBPropData.filter(
            (data) => !data?.meets,
        );
        setPropData(newData);
        setImprovedClass("bg-yellow");
        setAchievedClass("bg-primary-border");
    };

    const setAchievedPropData = () => {
        const newData = protectInfo?.protectDBPropData.filter(
            (data) => data?.meets,
        );
        setPropData(newData);
        setImprovedClass("bg-primary-border");
        setAchievedClass("bg-yellow");
    };

    return (
        <div className="container mx-auto my-28">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    Know your gaps
                </h2>
            </div>
            <div className="grid grid-cols-4 my-10 mx-4 protect-details">
                <div className="col-span-4 lg:col-span-1 lg:mr-4 xl:mr-8">
                    <p>
                        Click on 'What has been achieved' and see the
                        characteristics of smoke-free environments
                        {""}
                        <sup className="italic">1</sup> that have already been
                        achieved in your country.
                        <br />
                        <br />
                        Click on 'Characteristics to improve' to see the policy
                        gaps in your country and the characteristics that have
                        not reached best-practice yet.
                        <br />
                        <br />
                        <span>
                            <sup className="italic">1</sup>
                            <span className="italic text-[14px]">
                                {" "}
                                Here we report 'No' where designated smoking
                                areas/rooms are allowed{" "}
                            </span>
                        </span>
                    </p>
                    <div className="flex flex-col gap-2 p-2 button-box shadow-inner lg:mr-4 mt-8">
                        <div onClick={setImprovePropData}>
                            <ButtonComponent
                                contentElement={
                                    <div>Characteristics to improve</div>
                                }
                                variantClass={improvedClass}
                            />
                        </div>

                        <div onClick={setAchievedPropData}>
                            <ButtonComponent
                                contentElement={
                                    <div>What has been achieved</div>
                                }
                                variantClass={achievedClass}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-4 lg:col-span-3 lg:ml-4 xl:ml-8 detailed-table">
                    <div className="grid grid-cols-4 justify-center overflow-hidden">
                        <div className="col-span-3"></div>
                        <div className="col-span-1 justify-center table-head text-center rounded-t-lg p-4 bg-black text-white">
                            2022
                        </div>
                    </div>

                    <ul className="shadow-lg rounded-lg">
                        {propData?.length === 0 ? (
                            <NoDataMessageComponent />
                        ) : (
                            propData.map((item) => (
                                <li key={item?.id}>
                                    <div className="grid grid-cols-4 justify-center">
                                        <div className="col-span-3 bg-white mr-1 p-4 table-data">
                                            {item?.heading}
                                        </div>
                                        <div className="col-span-1 p-4 bg-white table-data font-bold text-center">
                                            {item?.text}
                                        </div>
                                    </div>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default ProtectDetailedBreakdownComponent;
