import React from "react";
import { Link } from "react-router-dom";

//interface import
import { WaterMarkButtonProps } from "./water-mark-button-interface";
import { useAppDispatch } from "../../../redux/hooks";
import { setCarouselCurrentIndex } from "../../../shared/redux/shared.slice";

/**
 * WaterMarkButtonComponent displays a list of buttons.
 *
 * @component
 * @param {Object} props
 * @returns {React.JSX.Element}
 */

const WaterMarkButtonComponent = (
    props: WaterMarkButtonProps,
): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(setCarouselCurrentIndex(2));
    };

    return (
        <>
            {props?.buttonData.map((buttonInfo) => (
                <React.Fragment key={buttonInfo?.id}>
                    <Link
                        to={buttonInfo?.url ? buttonInfo?.url : ""}
                        target={buttonInfo?.target ? "_blank" : ""}
                    >
                        <div
                            className={`py-4 pl-8 pr-2 rounded-full my-4 ${buttonInfo.bgColor}` }
                            id={`searchCountryReport-${buttonInfo.id}`}
                        >
                            <button
                                className="flex w-full justify-between gap-5"
                                onClick={() => {
                                    handleClick();
                                }}
                            >
                                <span>{buttonInfo?.text}</span>
                                <div className="rounded-full bg-slate-800 w-10 p-1.5">
                                    <img
                                        src={buttonInfo?.img}
                                        alt={buttonInfo?.alt}
                                    />
                                </div>
                            </button>
                        </div>
                    </Link>
                </React.Fragment>
            ))}
        </>
    );
};

export default WaterMarkButtonComponent;
