// Components imports
import ButtonComponent from "../button/button.component";

//Image or Icon or logo imports
import ReverseArrowIcon from "../../../assets/icon/reverse-arrow-icon.png";
import DownlaodIcon from "../../../assets/icon/download-icon.png";
import { useSelector } from "react-redux";
import { matchedCountryCodeSelector } from "../../../country/redux/country.selector";

/**
 * SharedActionComponent is a functional component that displays a set of action buttons.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const SharedActionComponent = (): React.JSX.Element => {
    const matchedCountryCode = useSelector(matchedCountryCodeSelector);

    return (
        <div className="bg-yellow-500 button-section py-24 flex items-center">
            <div className="container mx-auto">
                <div className="grid grid-cols-4 gap-6 justify-center">
                    <div className="lg:col-span-1"></div>
                    <div className="col-span-4 md:col-span-1">
                        <ButtonComponent
                            contentElement={
                                <div className="flex justify-between items-center">
                                    <span className="text-base">
                                        Return to country results
                                    </span>
                                    <div className="rounded-full bg-slate-800 w-10 p-1.5">
                                        <img
                                            src={ReverseArrowIcon}
                                            alt="Reverse-Arrow-Icon"
                                        />
                                    </div>
                                </div>
                            }
                            id="MPRturnback"
                            variantClass={"bg-white"}
                            url="/country"
                        />
                    </div>
                    <div className="col-span-4 md:col-span-1">
                        <ButtonComponent
                            contentElement={
                                <div className="flex justify-between items-center">
                                    <span className="text-base">
                                        Download country report
                                    </span>
                                    <div className="rounded-full bg-slate-800 w-10 p-1.5">
                                        <img
                                            src={DownlaodIcon}
                                            alt="Download-Icon"
                                        />
                                    </div>
                                </div>
                            }
                            id="MPDownback"
                            variantClass={"bg-primary"}
                            url={`${
                                process.env.PUBLIC_URL
                            }/pdf/MPOWER_country_profiles/Tobacco-2023-${matchedCountryCode.toUpperCase()}.pdf`}
                            target="_blank"
                            isThisDownloadCountryReport={true}
                        />
                    </div>
                    <div className="lg:col-span-1"></div>
                </div>
            </div>
        </div>
    );
};
export default SharedActionComponent;
