import { SCORE_PILL_TYPE } from "../../constants/shared.contants";

//Component import
import CoverScoreKeyCardItemComponent from "./cover-score-key-card-item.component";

//Interface import
import { CoverScoreKeyCardProps } from "./cover-score-key-card-interface";

/**
 * CoverScoreKeyCardComponent is a functional component that displays score key cards.
 *
 * @component
 * @param {CoverScoreKeyCardProps} props
 * @returns {React.JSX.Element}
 */

const CoverScoreKeyCardComponent = (
    props: CoverScoreKeyCardProps,
): React.JSX.Element => {
    return (
        <>
            {props.scoreKeyCardData.map((card,index) => {
                return (
                    <CoverScoreKeyCardItemComponent
                    divId={`${props.divid}-color-${index}`}
                        cardId={card.id}
                        type={
                            card.meets
                                ? SCORE_PILL_TYPE.Big
                                : SCORE_PILL_TYPE.Small
                        }
                        cardBgColor={card.cardBgColor}
                        isLastExpanded={
                            card.lastExpanded
                                ? SCORE_PILL_TYPE.Big
                                : SCORE_PILL_TYPE.Small
                        }
                        key={card.id}
                    />
                );
            })}
        </>
    );
};

export default CoverScoreKeyCardComponent;
