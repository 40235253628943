import React from "react";
import Slider from "react-slick";
import "../../styles.css";
import "../../responsive.css";
import CarouselSectionOneComponent from "./carousel-section-one/carousel-section-one.component";
import CarouselSectionTwoComponent from "./carousel-section-two/carousel-section-two.component";
import CarouselSectionThreeComponent from "./carousel-section-three/carousel-section-three.component";
import { connect } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setCarouselCurrentIndex } from "../../shared/redux/shared.slice";
import {
    setIsCountryNotFound,
    setSearchData,
} from "../../country/redux/country.slice";

/**
 * The `SliderComponent` class represents a carousel slider component.
 * @class SliderComponent
 * @extends {React.Component}
 */

interface IHomeSliderProps {
    setCarouselCurrentIndex: any;
    carouselCurrentIndex: number;
    setSearchData: (inputData: string) => void;
    inputData: string;
    setIsCountryNotFound: (isCountryNotFound: boolean) => void;
    isCountryNotFound: boolean;
}
class HomeSliderComponent extends React.Component<IHomeSliderProps> {
    slider: any;

    state = {
        isInputFocused: false,
    };

    handleInputFocus = () => {
        this.setState({ isInputFocused: true });
    };

    handleInputBlur = () => {
        this.setState({ isInputFocused: false });
    };

    render() {
        const { isInputFocused } = this.state;

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <></>,
            nextArrow: <></>,
            swipe: !isInputFocused,
            initialSlide: this.props.carouselCurrentIndex,
            afterChange: (index: number) => {
                this.props.setCarouselCurrentIndex(index);
                this.props.setSearchData("");
                this.props.setIsCountryNotFound(false);
            },
        };
        return (
            <Slider
                ref={(slider) => (this.slider = slider)}
                {...settings}
                data-testid="slider-component"
            >
                <div>
                    <CarouselSectionOneComponent
                        onNextClick={() => {
                            this.slider.slickNext();
                        }}
                        onFocus={this.handleInputFocus}
                        onBlur={this.handleInputBlur}
                        data-testid="carousel-section-one"
                    />
                </div>
                <div>
                    <CarouselSectionTwoComponent
                        onNextClick={() => {
                            this.slider.slickNext();
                        }}
                        onFocus={this.handleInputFocus}
                        onBlur={this.handleInputBlur}
                    />
                </div>
                <div>
                    <CarouselSectionThreeComponent
                        onFocus={this.handleInputFocus}
                        onBlur={this.handleInputBlur}
                    />
                </div>
            </Slider>
        );
    }
}

export const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setCarouselCurrentIndex: (currentIndex: number) =>
            dispatch(setCarouselCurrentIndex(currentIndex)),
        setSearchData: (inputData: string) =>
            dispatch(setSearchData(inputData)),
        setIsCountryNotFound: (isCountryNotFound: boolean) =>
            dispatch(setIsCountryNotFound(isCountryNotFound)),
    };
};

const mapStateToProps = (state: any) => {
    return {
        carouselCurrentIndex: state?.sharedSlice?.carousel?.currentIndex,
        inputData: state.countryInfo?.inputData,
        isCountryNotFound: state.countryInfo?.isCountryNotFound,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomeSliderComponent);
