// Components imports
import CountriesCompareComponent from "../../shared/components/countries-compare/components/countries-compare.component";
import HelpfullResourcesComponent from "../../shared/components/helpful-resources/components/helpful-resources.component";
import SharedActionComponent from "../../shared/components/shared-action/shared-action.component";
import OfferCoverComponent from "./offer-cover/offer-cover.component";
import OfferCurrentStatusComponent from "./offer-current-status/offer-current-status.component";
import OfferSuccessStoryComponent from "./offer-success-story/offer-success-story.component";
import OfferDetailedBreakdownComponent from "./offer-detailed-breakdown/offer-detailed-breakdown.component";
import CountriesRegionComponent from "../../shared/components/countries-compare-region/countries-compare-region.component";

/**
 * OfferBodyComponent is responsible for rendering the main body section of the Offer page.
 * @function MonitorBodyComponent
 * @returns {React.JSX.Element}
 */

const OfferBodyComponent = (): React.JSX.Element => {
    return (
        <div className="offer-page">
            <OfferCoverComponent />
            <OfferCurrentStatusComponent />
            <OfferDetailedBreakdownComponent />
            <CountriesRegionComponent measureName="OFFER" />
            <CountriesCompareComponent />
            <OfferSuccessStoryComponent />
            <SharedActionComponent />
            <HelpfullResourcesComponent />
        </div>
    );
};

export default OfferBodyComponent;
