export const DUPLICATE_COUNTRY_MESSAGE =
    "The country you entered already exists!";

export const VALIDATION_MESSAGE_FOR_NO_DATA =
    "The limit for no data category has reached, please remove any country before proceeding";
export const VALIDATION_MESSAGE_FOR_NO_CRITERIA =
    "The limit for no criteria category has reached, please remove any country before proceeding";
export const VALIDATION_MESSAGE_FOR_MINIMAL =
    "The limit for minimal category has reached, please remove any country before proceeding";
export const VALIDATION_MESSAGE_FOR_MODERATE =
    "The limit for moderate category has reached, please remove any country before proceeding";
export const VALIDATION_MESSAGE_FOR_COMPLETE =
    "The limit for complete category has reached, please remove any country before proceeding";

export const ERROR_MESSAGE = {
    "0": {
        1: VALIDATION_MESSAGE_FOR_NO_CRITERIA,
        2: VALIDATION_MESSAGE_FOR_MINIMAL,
        3: VALIDATION_MESSAGE_FOR_MODERATE,
        4: VALIDATION_MESSAGE_FOR_COMPLETE,
    },
    common: {
        1: VALIDATION_MESSAGE_FOR_NO_DATA,
        2: VALIDATION_MESSAGE_FOR_NO_CRITERIA,
        3: VALIDATION_MESSAGE_FOR_MINIMAL,
        4: VALIDATION_MESSAGE_FOR_MODERATE,
        5: VALIDATION_MESSAGE_FOR_COMPLETE,
    },
};

export const NOT_CATEGORIZED_COUNTRY_LIST_NAME = "notCategorizedCountryList";
export const NO_REQUIREMENT_COUNTRY_LIST_NAME = "noRequirementCountryList";
export const MINIMAL_REQUIREMENTS_COUNTRY_LIST_NAME =
    "minimalRequirementsCountryList";
export const MODERATE_REQUIREMENTS_COUNTRY_LIST_NAME =
    "moderateRequirementsCountryList";
export const COMPLETE_REQUIREMENTS_COUNTRY_LIST_NAME =
    "completeRequirementsCountryList";

export const LIST_SELECTOR: Record<number, string> = {
    1: NOT_CATEGORIZED_COUNTRY_LIST_NAME,
    2: NO_REQUIREMENT_COUNTRY_LIST_NAME,
    3: MINIMAL_REQUIREMENTS_COUNTRY_LIST_NAME,
    4: MODERATE_REQUIREMENTS_COUNTRY_LIST_NAME,
    5: COMPLETE_REQUIREMENTS_COUNTRY_LIST_NAME,
};

export const GRID_COLS_4 = "grid grid-cols-4 gap-4";
export const GRID_COLS_5 = "grid grid-cols-4 md:grid-cols-5 gap-4";
