import { combineReducers } from "@reduxjs/toolkit";
import countryInfo from "../country/redux/country.slice";
import monitorInfo from "../monitor/redux/monitor.slice";
import protectInfo from "../protect/redux/protect.slice";
import offerInfo from "../offer/redux/offer.slice";
import enforceInfo from "../enforce/redux/enforce.slice";
import raiseTaxInfo from "../raise-tax/redux/raise-tax.slice";
import massMediaInfo from "../warn-mass-media/redux/warn-mass-media.slice";
import warnPackageInfo from "../warn-package/redux/warn-package.slice";
import countryCompareInfo from "../shared/components/countries-compare/redux/country-compare.slice";
import countryCompareRegionInfo from "../shared/components/countries-compare-region-block/redux/countries-compare-region-block.slice";
import sharedSlice from "../shared/redux/shared.slice";
import helpfulResources from "../shared/components/helpful-resources/redux/helpful-resources.slice";

export interface RootState {
    countryInfo: ReturnType<typeof countryInfo>;
    monitorInfo: ReturnType<typeof monitorInfo>;
    protectInfo: ReturnType<typeof protectInfo>;
    offerInfo: ReturnType<typeof offerInfo>;
    enforceInfo: ReturnType<typeof enforceInfo>;
    raiseTaxInfo: ReturnType<typeof raiseTaxInfo>;
    massMediaInfo: ReturnType<typeof massMediaInfo>;
    warnPackageInfo: ReturnType<typeof warnPackageInfo>;
    countryCompareInfo: ReturnType<typeof countryCompareInfo>;
    countryCompareRegionInfo: ReturnType<typeof countryCompareRegionInfo>;
    sharedSlice: ReturnType<typeof sharedSlice>;
    helpfulResources: ReturnType<typeof helpfulResources>;
}

export const rootReducer = combineReducers<RootState>({
    countryInfo: countryInfo,
    monitorInfo: monitorInfo,
    protectInfo: protectInfo,
    offerInfo: offerInfo,
    enforceInfo: enforceInfo,
    raiseTaxInfo: raiseTaxInfo,
    massMediaInfo: massMediaInfo,
    warnPackageInfo: warnPackageInfo,
    countryCompareInfo: countryCompareInfo,
    countryCompareRegionInfo: countryCompareRegionInfo,
    sharedSlice: sharedSlice,
    helpfulResources: helpfulResources,
});
