import React from "react";

//Image or Icon or logo imports
import checkTick from "../../../assets/icon/check-box.png";
import emptyCheckBox from "../../../assets/icon/empty-check-box.png";

//Interface import
import { ProtectStatusBlockProps } from "./protect-current-status-block-interface";

/**
 * ProtectCurrentStatusBlockComponent is a functional component that displays a list of status blocks for tobacco control.
 *
 * @component
 * @param {ProtectStatusBlockProps} props
 * @returns {React.JSX.Element}
 */

const ProtectCurrentStatusBlockComponent = (
    props: ProtectStatusBlockProps,
): React.JSX.Element => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 justify-center my-8">
            {props.statusBlockData.map((block) => (
                <React.Fragment key={block.id}>
                    <div
                        className="rounded border border-gray-300 p-2 bg-white flex justify-between flex-col"
                        data-testid={`protect-status-block`}
                    >
                        <img src={block.img} alt={block.alt} />
                        <div className="flex gap-4 bg-gray-100 justify-center items-center p-2 status-grey-content">
                            <p className={block.result ? "font-bold" : ""}>
                                {block.heading}
                            </p>
                            <img
                                src={block.result ? checkTick : emptyCheckBox}
                                alt={
                                    block.result
                                        ? "check-tick"
                                        : "empty-check-box"
                                }
                                className="w-6 h-6"
                            />
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default ProtectCurrentStatusBlockComponent;
