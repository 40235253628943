import { commonCoverPropData } from "../../shared/constants/shared.contants";
import { OfferDataInfoType } from "./offer.types";

//Image or Icon or logo imports
import mobile from "../../assets/icon/mobile.png";
import nicotineMedicine from "../../assets/icon/nicotine-medicine.png";
import stethoscope from "../../assets/icon/stethoscope.png";
import healthCare from "../../assets/icon/health-care.png";
import hospital from "../../assets/icon/hospital.png";
import community from "../../assets/icon/community.png";
import checksBox from "../../assets/icon/checks-box.png";

const setResult = (resultInfo: string) => {
    if (
        resultInfo.toLowerCase() === "partially" ||
        resultInfo.toLowerCase() === "fully" ||
        resultInfo.toLowerCase() === "yes"
    ) {
        return true;
    } else {
        return false;
    }
};

const setIndicatortitle = (
    indicatorCode: string,
    offerInfo: OfferDataInfoType,
) => {
    const indicatorData = offerInfo.indicators.find(
        (indicator: { code: string }) => {
            return indicator.code === indicatorCode;
        },
    );
    return indicatorData ? indicatorData.title : "";
};

export const setOfferPropData = (offerInfo: OfferDataInfoType) => {
    let groupNumber = parseInt(offerInfo.O_Group);
    const offerData = [...commonCoverPropData];
    for (let i = 0; i < groupNumber; i++) {
        offerData[i] = { ...offerData[i], meets: true };
    }

    for (let i = 1; i <= groupNumber; i++) {
        if (i === groupNumber) {
            offerData[i - 1] = {
                ...offerData[i - 1],
                lastExpanded: true,
            };
        }
    }

    return offerData;
};

export const setOfferStatusListData = (offerInfo: OfferDataInfoType) => {
    return [
        {
            id: "01",
            img: mobile,
            alt: "mobile",
            text: setIndicatortitle("O_Quitline", offerInfo),
            result: setResult(offerInfo.O_Quitline),
        },
        {
            id: "02",
            img: nicotineMedicine,
            alt: "education",
            text: setIndicatortitle("O_NRT_ins", offerInfo),
            result: setResult(offerInfo.O_NRT_ins),
        },
    ];
};

export const setOfferstatusSupportData = (offerInfo: OfferDataInfoType) => {
    return [
        {
            id: "01",
            img: stethoscope,
            alt: "stethoscope",
            text: setIndicatortitle("O_Ins_hlth_clin", offerInfo),
            result: setResult(offerInfo.O_Ins_hlth_clin),
        },
        {
            id: "02",
            img: healthCare,
            alt: "health-care",
            text: setIndicatortitle("O_Ins_hosp", offerInfo),
            result: setResult(offerInfo.O_Ins_hosp),
        },
        {
            id: "03",
            img: hospital,
            alt: "hospital",
            text: setIndicatortitle("O_Ins_off_hlth_prof", offerInfo),
            result: setResult(offerInfo.O_Ins_off_hlth_prof),
        },
        {
            id: "04",
            img: community,
            alt: "community",
            text: setIndicatortitle("O_Ins_community", offerInfo),
            result: setResult(offerInfo.O_Ins_community),
        },
        {
            id: "05",
            img: checksBox,
            alt: "checks-box",
            text: setIndicatortitle("O_Ins_other", offerInfo),
            result: setResult(offerInfo.O_Ins_other),
        },
    ];
};

export const getProccessedOfferInfo = (offerInfo: OfferDataInfoType) => {
    offerInfo.offerPropData = setOfferPropData(offerInfo);
    offerInfo.offerstatusListData = setOfferStatusListData(offerInfo);
    offerInfo.offerstatusSupportData = setOfferstatusSupportData(offerInfo);

    return offerInfo;
};
