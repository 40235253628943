import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../shared/components/rest-handler/rest-handler.service";
import * as RAISETAXINFO_SLICE from "./raise-tax.slice";
import { getProccessedRaiseTaxInfo } from "./raise-tax.utilities";

export function* getRaiseTaxInfo(action: any): any {
    let url = `/RaiseTax?countryCode=${action?.payload || ""}`;
    try {
        let raiseTaxInfo = yield call(RestHandlerService.get, url, {});
        raiseTaxInfo = getProccessedRaiseTaxInfo(raiseTaxInfo);
        yield put(RAISETAXINFO_SLICE.getRaiseTaxInfoSuccess(raiseTaxInfo));
    } catch (err) {
        yield put(RAISETAXINFO_SLICE.getRaiseTaxInfoError());
    }
}

export function* raiseTaxInfoSaga() {
    yield takeLatest("raiseTaxInfo/getRaiseTaxInfo", getRaiseTaxInfo);
}
