import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const sharedState = (state: RootState) => {
    return state.sharedSlice;
};

export const carouselSelector = createSelector([sharedState], (sharedState) => {
    return sharedState?.carousel;
});

export const showPageLoaderSelector = createSelector(
    [sharedState],
    (sharedState) => {
        return sharedState?.showPageLoader;
    },
);
