import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const enforceInfoState = (state: RootState) => {
    return state.enforceInfo;
};

export const enforceInfoSelector = createSelector(
    [enforceInfoState],
    (enforceInfoState) => {
        return enforceInfoState?.enforceData || {};
    },
);
