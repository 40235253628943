import { put, takeLatest, call, select } from "redux-saga/effects";
import RestHandlerService from "../../rest-handler/rest-handler.service";
import * as COUNTRYCOMPAREINFO_SLICE from "./country-compare.slice";
import { RootState } from "../../../../redux/store";
import { toast } from "react-toastify";

import { LIST_SELECTOR } from "../countries-comapre.constants";
import {
    getCategoryCountryList,
    getErrorMessage,
    isValidItem,
    isZeroPolicy,
} from "./country-compare.utilities";

const listSelectors: Record<number, string> = LIST_SELECTOR;

export function* getCountryCompareInfo(action: any): any {
    const { policyNumber, countryCode } = action.payload;

    let url = `/CountryComparision?countryCode=${
        countryCode || ""
    }&policy=${policyNumber}`;
    try {
        yield put(COUNTRYCOMPAREINFO_SLICE.setIsButtonClicked(true));
        yield put(COUNTRYCOMPAREINFO_SLICE.setShowCountriesCompareLoader(true));
        const countryCategory: number = yield call(
            RestHandlerService.get,
            url,
            {},
        );

        const countryCompareInfo = yield select(
            (state: RootState) => state.countryCompareInfo,
        );

        const inputData = yield select((state: RootState) =>
            !state?.countryCompareInfo?.compareInputData
                ? state.countryInfo.countryName
                : state.countryCompareInfo.compareInputData,
        );

        const categoryCountryList = getCategoryCountryList(
            policyNumber,
            countryCategory,
            countryCompareInfo,
        );

        const validationResult = isValidItem(categoryCountryList, inputData);

        if (validationResult.isItemValid) {
            yield addItemToList(policyNumber, countryCategory, inputData);
            yield put(
                COUNTRYCOMPAREINFO_SLICE.getCountryCompareInfoSuccess(
                    countryCategory,
                ),
            );
        } else {
            const errorMessgae = getErrorMessage(
                policyNumber,
                countryCategory,
                validationResult,
            );
            toast.error(errorMessgae);
        }
        yield put(COUNTRYCOMPAREINFO_SLICE.setCompareSearchData(""));
    } catch (err) {
        yield put(COUNTRYCOMPAREINFO_SLICE.getCountryCompareInfoError());
    } finally {
        yield put(COUNTRYCOMPAREINFO_SLICE.setIsButtonClicked(false));
        yield put(
            COUNTRYCOMPAREINFO_SLICE.setShowCountriesCompareLoader(false),
        );
    }
}

export function* addItemToList(
    _policyNumber: string,
    _countryCategory: number,
    _formattedInputText: string,
): any {
    const actionCreator = isZeroPolicy(_policyNumber)
        ? (COUNTRYCOMPAREINFO_SLICE as any)[
              `set${
                  listSelectors[_countryCategory + 1].charAt(0).toUpperCase() +
                  listSelectors[_countryCategory + 1].slice(1)
              }`
          ]
        : (COUNTRYCOMPAREINFO_SLICE as any)[
              `set${
                  listSelectors[_countryCategory].charAt(0).toUpperCase() +
                  listSelectors[_countryCategory].slice(1)
              }`
          ];

    yield put(actionCreator(_formattedInputText));
}

export function* countryCompareInfoSaga() {
    yield takeLatest(
        "countryCompareInfo/getCountryCompareInfo",
        getCountryCompareInfo,
    );
}
