import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as types from "./countries-compare-region-block.types";

export const initialState: types.InitialState = {
    countryCompareRegionData: {
        regionName: "",
        policyValue1: "",
        policyValue2: "",
        policyValue3: "",
        policyValue4: "",
        policyValue5: "",
        policyValueNA: "",
        countryRegionPropData: [],
    },
};

const countryCompareRegionInfo = createSlice({
    name: "countryCompareRegionInfo",
    initialState,
    reducers: {
        getCountryCompareRegionInfo: (state, _action) => {
            return { ...state };
        },

        getCountryCompareRegionInfoSuccess: (
            state,
            action: PayloadAction<types.CountryCompareRegionInfoType>,
        ) => {
            return {
                ...state,
                countryCompareRegionData: action.payload,
            };
        },

        getCountryCompareRegionInfoError: (state) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    getCountryCompareRegionInfo,
    getCountryCompareRegionInfoSuccess,
    getCountryCompareRegionInfoError,
} = countryCompareRegionInfo.actions;

export default countryCompareRegionInfo.reducer;
