//Image or Icon or logo imports
import { useSelector } from "react-redux";

import CountriesCompareRegionalBlockComponent from "../countries-compare-region-block/components/countries-compare-region-block.component";
import { countryCompareRegionInfoSelector } from "../countries-compare-region-block/redux/countries-compare-region-block.selector";
import {
    GRID_COLS_XL_4,
    GRID_COLS_XL_5,
} from "../countries-compare-region-block/countries-compare-region-block.constants";
import { comparePolicySelector } from "../countries-compare/redux/country-compare.selector";

/**
 * CountriesRegionComponent is a functional component that shows users region wise countries data for different catagories.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const CountriesRegionComponent = ({
    measureName,
}: {
    measureName: string;
}): React.JSX.Element => {
    const countryCompareRegionInfo = useSelector(
        countryCompareRegionInfoSelector,
    );
    const policyNymber = useSelector(comparePolicySelector);
    return (
        <div className="container mx-auto">
            <div className="mx-4 mb-10">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600" id="MPtext15">
                    Regional summary of {measureName} measures
                </h2>
                <div className="shadow-xl shadow-indigo-500/10 mt-4 rounded-lg p-4 light-grey">
                    <h2 className="text-xl font-bold hp-heading-color mt-4 mb-4 ml-4" id="MPtext16">
                        Region Name - {countryCompareRegionInfo.regionName}
                    </h2>
                    <div
                        className={`${
                            policyNymber === "0"
                                ? GRID_COLS_XL_4
                                : GRID_COLS_XL_5
                        }`}
                    >
                        <CountriesCompareRegionalBlockComponent
                            compareRegionData={
                                countryCompareRegionInfo.countryRegionPropData
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountriesRegionComponent;
