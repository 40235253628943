// Components imports
import CountriesRegionComponent from "../../shared/components/countries-compare-region/countries-compare-region.component";
import CountriesCompareComponent from "../../shared/components/countries-compare/components/countries-compare.component";
import HelpfullResourcesComponent from "../../shared/components/helpful-resources/components/helpful-resources.component";
import SharedActionComponent from "../../shared/components/shared-action/shared-action.component";
import MonitorCoverComponent from "./monitor-cover/monitor-cover.component";
import MonitorCurrentStatusComponent from "./monitor-current-status/monitor-current-status.component";
import MonitorDetailedBreakdownComponent from "./monitor-detailed-breakdown/monitor-detailed-breakdown.component";
import MonitorSuccessStoryComponent from "./monitor-success-story/monitor-success-story.component";

/**
 * MonitorBodyComponent is responsible for rendering the main body section of the Monitor page.
 * @function MonitorBodyComponent
 * @returns {React.JSX.Element}
 */

const MonitorBodyComponent = (): React.JSX.Element => {
    return (
        <div>
            <MonitorCoverComponent />
            <MonitorCurrentStatusComponent />
            <MonitorDetailedBreakdownComponent />
            <CountriesRegionComponent measureName="MONITOR" />
            <CountriesCompareComponent />
            <MonitorSuccessStoryComponent />
            <SharedActionComponent />
            <HelpfullResourcesComponent />
        </div>
    );
};

export default MonitorBodyComponent;
