const NoDataMessageComponent = () => {
    return (
        <li>
            <div className="grid grid-cols-4 justify-center">
                <div className="col-span-4 text-center p-4">
                    No data available.
                </div>
            </div>
        </li>
    );
};

export default NoDataMessageComponent;
