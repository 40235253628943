import { all, call } from "redux-saga/effects";
import { countryInfoSaga } from "../country/redux/country.saga";
import { monitorInfoSaga } from "../monitor/redux/monitor.saga";
import { protectInfoSaga } from "../protect/redux/protect.saga";
import { offerInfoSaga } from "../offer/redux/offer.saga";
import { enforceInfoSaga } from "../enforce/redux/enforce.saga";
import { raiseTaxInfoSaga } from "../raise-tax/redux/raise-tax.saga";
import { massMediaInfoSaga } from "../warn-mass-media/redux/warn-mass-media.saga";
import { warnPackageInfoSaga } from "../warn-package/redux/warn-package.saga";
import { countryCompareInfoSaga } from "../shared/components/countries-compare/redux/country-compare.saga";
import { countryCompareRegionInfoSaga } from "../shared/components/countries-compare-region-block/redux/countries-compare-region-block.saga";
import { getHelpfulResourcesSaga } from "../shared/components/helpful-resources/redux/helpful-resources.saga";
import { SharedSaga } from "../shared/redux/shared.saga";

export default function* rootSagas() {
    yield all([
        call(countryInfoSaga),
        call(monitorInfoSaga),
        call(protectInfoSaga),
        call(offerInfoSaga),
        call(enforceInfoSaga),
        call(raiseTaxInfoSaga),
        call(massMediaInfoSaga),
        call(warnPackageInfoSaga),
        call(countryCompareInfoSaga),
        call(countryCompareRegionInfoSaga),
        call(getHelpfulResourcesSaga),
        call(SharedSaga),
    ]);
}
