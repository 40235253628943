/**
 * MonitorCurrentStatusHeadingComponent is a functional component that displays a heading.
 *
 * @component
 * @param {Object} props
 * @returns {React.JSX.Element}
 */

const MonitorCurrentStatusHeadingComponent = (props: {
    text: string;
    id?:string
}): React.JSX.Element => {
    const { text,id} = props;
    return (
        <>
            <div className="border-b-2 border-indigo-800 pb-2">
                <p className="text-2xl text-indigo-800 font-bold" id={`${id}-MPAdults`}>{text}</p>
            </div>
        </>
    );
};

export default MonitorCurrentStatusHeadingComponent;
