// Components imports
import SuccessStoryComponent from "../../../shared/components/success-story/success-story.component";

import { OFFER_SUCCESS_PROP_DATA } from "../../../shared/constants/shared.contants";

/**
 * OfferSuccessStoryComponent is a functional component that displays a success story section with a description and an image.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const OfferSuccessStoryComponent = (): React.JSX.Element => {
    return <SuccessStoryComponent successData={OFFER_SUCCESS_PROP_DATA} />;
};

export default OfferSuccessStoryComponent;
