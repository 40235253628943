import { ThreeDots } from "react-loader-spinner";
import HeaderComponent from "./header/header.component";

const LoaderComponent = () => {
    return (
        <>
            <>
                <HeaderComponent />
                <ThreeDots
                    color="#0086ad"
                    height={80}
                    width={80}
                    radius="9"
                    ariaLabel="three-dots-loading"
                    visible={true}
                />
            </>
        </>
    );
};

export default LoaderComponent;
