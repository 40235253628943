// Components import
import CoverScoreKeyCardComponent from "../../../shared/components/cover-score-key-card/cover-score-key-card.component";
import WarnProtectCurrentstatusDataViewComponent from "../warn-package-current-status-data-view/warn-package-current-status-data-view.component";

import { useSelector } from "react-redux";
import { warnPackageInfoSelector } from "../../redux/warn-package.selector";

/**
 * WarnPackageCurrentStatusComponent is a functional component that displays current status information for protect page.
 * @component
 * @returns {React.JSX.Element}
 */

const WarnPackageCurrentStatusComponent = (): React.JSX.Element => {
    const warnPackageInfo = useSelector(warnPackageInfoSelector);

    return (
        <div className="container mx-auto">
            <div className="mx-4">
                <h2 className="hp-heading-color text-4xl border-b border-cyan-600">
                    What has been achieved
                </h2>
            </div>
            <div className="grid grid-cols-3 justify-center my-10 mx-4">
                <div className="col-span-3 lg:col-span-1 mr-8">
                    <p>
                        The groupings for the health warnings indicator are
                        based on the number of these requirements as stated in
                        the legislation of your country. The size of the
                        warnings on both the front and back of the cigarette
                        pack were averaged to calculate the percentage of the
                        total pack surface area covered by warnings. This
                        information was combined with the warning
                        characteristics to construct the groupings for the
                        health warnings indicator.
                    </p>
                </div>
                <div className="col-span-3 lg:col-span-2 ml-8">
                    <div className="current-status-diagram">
                        <div className="grid grid-cols-5 current-color-box justify-center mb-8 ">
                            <CoverScoreKeyCardComponent
                                scoreKeyCardData={
                                    warnPackageInfo.warnPackageCoverPropData
                                }
                            />
                        </div>
                    </div>
                    <WarnProtectCurrentstatusDataViewComponent />
                </div>
            </div>
        </div>
    );
};
export default WarnPackageCurrentStatusComponent;
