import { MpowerBlockHeaderProps } from "./mpower-block-header-interface";

/**
 * MpowerBlockHeaderComponent for rendering Mpower block headers.
 *
 * @component
 * @param {MpowerBlockHeaderProps} props
 * @returns {React.JSX.Element}
 */

const MpowerBlockHeaderComponent = (
    props: MpowerBlockHeaderProps,
): React.JSX.Element => {
    return (
        <div className="block-title"   id={`${props.id}-MpheaderText`}>
            <span>
                <span className="mpower-initial">
                    {props?.mpowerBlockHeaderData?.initial}
                </span>
                <span className="mpower-finalword">
                    {` - ${props?.mpowerBlockHeaderData?.finalWord}`}
                </span>
            </span>
        </div>
    );
};

export default MpowerBlockHeaderComponent;
