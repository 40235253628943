import { monitorInfoSelector } from "../../redux/monitor.selector";
import { useSelector } from "react-redux";

// Components imports
import MonitorCurrentStatusHeadingComponent from "./monitor-current-status-heading.component";
import MonitorCurrentStatusDataCard from "./monitor-current-status-data-card.component";

/**
 * MonitorCurrentstatusDataViewComponent is a functional component that displays monitoring data for both adults and youth.
 * @component
 * @returns {React.JSX.Element}
 */

const MonitorCurrentstatusDataViewComponent = (): React.JSX.Element => {
    const monitorInfo = useSelector(monitorInfoSelector);

    return (
        <div className="rounded-lg border border-black p-8 mb-4">
            <div className="grid grid-cols-2 gap-6 justify-start border-button">
                <div className="col-span-2 md:col-span-1">
                    <MonitorCurrentStatusHeadingComponent text={`Adults`}  id="Achivetext"/>
                    <div className="mt-4">
                        <MonitorCurrentStatusDataCard
                            heading={
                                <p className="text-sm">
                                    The year that fieldwork for the most recent
                                    nationally representative population-based
                                    survey among adults was completed.
                                </p>
                            }
                            yearText={
                                <p className="font-bold text-4xl text-indigo-800">
                                    {monitorInfo.Adult_svy_age_range}
                                </p>
                            }
                        />
                    </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                    <MonitorCurrentStatusHeadingComponent
                        text={`Adolescents`}
                        id="Adolescentstxt"
                    />
                    <div className="gap-2 mt-4">
                        <MonitorCurrentStatusDataCard
                            heading={
                                <p className="text-sm">
                                    The year that fieldwork for the most recent
                                    nationally representative school-based
                                    survey among adolescents was completed.
                                </p>
                            }
                            yearText={
                                <p className="font-bold text-4xl text-indigo-800">
                                    {monitorInfo.Youth_svy_age_range}
                                </p>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonitorCurrentstatusDataViewComponent;
