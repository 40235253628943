import { put, takeLatest, select, delay } from "redux-saga/effects";

import {
    getMpowerInfo,
    setMpowerScoreKeyData,
} from "../../country/redux/country.slice";
import { RootState } from "../../redux/store";
import {
    clearCountryCompareData,
    getCountryCompareInfo,
    setComparePolicyData,
} from "../components/countries-compare/redux/country-compare.slice";
import { getCountryCompareRegionInfo } from "../components/countries-compare-region-block/redux/countries-compare-region-block.slice";
import { getHelpfulResourcesInfo } from "../components/helpful-resources/redux/helpful-resources.slice";
import { setShowPageLoader } from "./shared.slice";

export function* genericActionDispatcher(action: any): any {
    const matchedCountryCode = yield select(
        (state: RootState) => state.countryInfo.matchedCountryCode,
    );

    try {
        yield put(setShowPageLoader(true));
        yield put(
            getMpowerInfo({
                matchedCountryCode: matchedCountryCode,
                name: action?.payload?.selectedCountry,
            }),
        );
        yield put(setMpowerScoreKeyData(action?.payload?.policyData));
        yield put(clearCountryCompareData());
        yield put(setComparePolicyData(action?.payload?.policyData));
        yield put(
            getCountryCompareInfo({
                countryCode: matchedCountryCode.toLowerCase(),
                policyNumber: action?.payload?.policyData,
            }),
        );
        yield put(
            getCountryCompareRegionInfo({
                countryCode: matchedCountryCode.toLowerCase(),
                policyNumber: action?.payload?.policyData,
            }),
        );
        yield delay(1000);
        yield put(getHelpfulResourcesInfo(action?.payload?.policyData));
    } catch (err) {
        yield put(setShowPageLoader(false));
    }
}

export function* SharedSaga() {
    yield takeLatest("shared/genericActionDispatcher", genericActionDispatcher);
}
