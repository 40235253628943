// Components imports
import CountryNameComponent from "../../../country/components/country-name/country-name.component";
import MpowerScoreColorKeyComponent from "../../../country/components/mpower-score-key/mpower-score-key.component";
import CoverCardViewComponent from "../../../shared/components/cover-card-view/cover-card-view.component";

//Image or Icon or logo imports
import warnPackageImage from "../../../assets/icon/w-warn-package.png";

//Interface Import
import { CoverCardViewPropType } from "../../../shared/components/cover-card-view/cover-card-view-interface";

import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
    STATUS_COMPLETE,
    STATUS_MINIMAL,
    STATUS_MODERATE,
    STATUS_NO_DATA,
    STATUS_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";
import { useSelector } from "react-redux";
import { warnPackageInfoSelector } from "../../redux/warn-package.selector";
import TechnicalNoteLinkComponent from "../../../shared/components/technical-note-link-component";

/**
 * WarnPackageCoverComponent is a functional component that displays Health warning related information.
 * It includes a country name component, a cover card view component, and an Mpower score color key component.
 * @component
 * @returns {React.JSX.Element}
 */

const WarnPackageCoverComponent = (): React.JSX.Element => {
    const warnPackageInfo = useSelector(warnPackageInfoSelector);

    let bgColor, statusText;

    switch (warnPackageInfo?.W_Group) {
        case "1":
            bgColor = BG_COLOR_NO_DATA;
            statusText = STATUS_NO_DATA;
            break;
        case "2":
            bgColor = BG_COLOR_NO_REQUIREMENT;
            statusText = STATUS_NO_REQUIREMENT;
            break;
        case "3":
            bgColor = BG_COLOR_MINIMAL;
            statusText = STATUS_MINIMAL;
            break;
        case "4":
            bgColor = BG_COLOR_MODERATE;
            statusText = STATUS_MODERATE;
            break;
        case "5":
            bgColor = BG_COLOR_COMPLETE;
            statusText = STATUS_COMPLETE;
            break;
        default:
            break;
    }

    const coverCardPropData: CoverCardViewPropType = {
        img: warnPackageImage,
        alt: "warn-package-image",
        headingText: "Health warnings on cigarette packages",
        statusText: statusText,
        coverCardPropData: warnPackageInfo.warnPackageCoverPropData,
        bgColor: bgColor,
        rightCardText: `Article 11 of the WHO FCTC: " Each Party shall ...  adopt and implement ... effective measures to ensure that ... tobacco product packaging and labelling do not promote a tobacco product by any means that are false, misleading, deceptive or likely to create an erroneous impression about its characteristics, health effects, hazards or emissions ... ; and each unit packet and package of tobacco products and any outside packaging and labelling of such products also carry health warnings describing the harmful effects of tobacco use, and may include other appropriate messages”.`,
    };

    return (
        <div className="container mx-auto">
            <CountryNameComponent
                contentElement={"Return to country results"}
                url={"/country"}
            />
            <CoverCardViewComponent coverCardViewData={coverCardPropData} />
            <MpowerScoreColorKeyComponent
                headingText={
                    <div>
                        The groupings for the health warnings indicator are
                        listed here. For more information on the criteria for
                        each measure please see the Technical Note:{" "}
                        <TechnicalNoteLinkComponent />
                    </div>
                }
                footNoteText={
                    <div className="pt-10">
                        <ol>
                            <li>
                                1. Average of front and back of package is less
                                than 30%.
                            </li>
                            <li>
                                2. Average of front and back of package is
                                between 30 and 49%.
                            </li>
                            <li>3. One to three.</li>
                            <li>4. Four or more.</li>
                            <li>
                                5. Appropriate characteristics:
                                <ul className="pl-5">
                                    <li>
                                        - specific health warnings mandated;
                                    </li>
                                    <li>
                                        - appearing on individual packages as
                                        well as on any outside packaging and
                                        labelling used in retail sale;
                                    </li>
                                    <li>
                                        - describing specific harmful effects of
                                        tobacco use on health;
                                    </li>
                                    <li>
                                        - are large, clear, visible and legible
                                        (e.g. specific colours and font style
                                        and sizes are mandated);
                                    </li>
                                    <li>- rotate;</li>
                                    <li>- include pictures or pictograms;</li>
                                    <li>
                                        - written in (all) the principal
                                        language(s) of the country.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                6. Average of front and back of the package is
                                at least 50%.
                            </li>
                        </ol>
                    </div>
                }
                measureName="WARN"
            />
        </div>
    );
};

export default WarnPackageCoverComponent;
