// Components imports
import CountriesCompareComponent from "../shared/components/countries-compare/components/countries-compare.component";
import HelpfullResourcesComponent from "../shared/components/helpful-resources/components/helpful-resources.component";
import SharedActionComponent from "../shared/components/shared-action/shared-action.component";
import RaiseTaxCoverComponent from "./components/raise-tax-cover/raise-tax-cover.component";
import RaiseTaxSuccessStoryComponent from "./components/raise-tax-success-story/raise-tax-success-story.component";
import RaiseTaxCurrentStatusComponent from "./components/raise-tax-current-status/raise-tax-current-status.component";
import CountriesRegionComponent from "../shared/components/countries-compare-region/countries-compare-region.component";

/**
 * RaiseTaxBodyComponent is responsible for rendering the main body section of the raise tax page.
 * @function ProtectBodyComponent
 * @returns {React.JSX.Element}
 */

const RaiseTaxBodyComponent = (): React.JSX.Element => {
    return (
        <div className="raisetax-page">
            <RaiseTaxCoverComponent />
            <RaiseTaxCurrentStatusComponent />
            <CountriesRegionComponent measureName="RAISE TAXES" />
            <CountriesCompareComponent />
            <RaiseTaxSuccessStoryComponent />
            <SharedActionComponent />
            <HelpfullResourcesComponent />
        </div>
    );
};

export default RaiseTaxBodyComponent;
