import { useAppDispatch } from "../redux/hooks";
import { CountryInfoType } from "../country/redux/country.types";
import { useSelector } from "react-redux";
import { selectedCountrySelector } from "../country/redux/country.selector";
import { useEffect } from "react";
import { genericActionDispatcher } from "../shared/redux/shared.slice";
import { showPageLoaderSelector } from "../shared/redux/shared.selector";

// Components imports
import HeaderComponent from "../shared/components/header/header.component";
import FooterComponent from "../shared/components/footer/footer.component";
import EnforceBodyComponent from "./enforce-body.component";
import LoaderComponent from "../shared/components/loader.component";

// Logo and image import
import mpowerLogoEnforce from "../assets/logo/mpower-logo-enforce.png";
import WhoBlueLogo from "../assets/logo/who-logo-blue.png";

//Interface import
import { HeaderPropType } from "../shared/components/header/header-interface";

const headerPropData: HeaderPropType[] = [
    {
        id: "01",
        img: WhoBlueLogo,
        alt: "who-blue-logo",
        class: "whologo",
        url: "",
    },
    {
        id: "02",
        img: mpowerLogoEnforce,
        alt: "mpower-enforce-logo",
        class: "mpowerlogo",
        url: "/",
    },
];

const EnforcePage = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const selectedCountry: CountryInfoType = useSelector(
        selectedCountrySelector,
    );

    useEffect(() => {
        dispatch(
            genericActionDispatcher({
                selectedCountry: selectedCountry?.enforceBlockPropData?.name,
                policyData: "5",
            }),
        );
    }, []);
    const showLoader = useSelector(showPageLoaderSelector);

    return (
        <>
            {showLoader ? (
                <LoaderComponent></LoaderComponent>
            ) : (
                <>
                    <HeaderComponent imgData={headerPropData} />
                    <EnforceBodyComponent />
                    <FooterComponent />
                </>
            )}
        </>
    );
};

export default EnforcePage;
