// Components imports
import CountryIndicatorBlockComponent from "../country-indicator-block/country-indicator-block.component";

//Interface import
import { CountryIndicatorDataType } from "../country-indicator/country-indicator-interface";

//Image or Icon or logo imports
import IncomeGroupIcon from "../../../assets/icon/income-group-icon.png";
import FCTCStatusIcon from "../../../assets/icon/fctc-status-icon.png";
import DataUpadtedIcon from "../../../assets/icon/data-updated-icon.png";
import { CountryInfoType } from "../../redux/country.types";
import { useSelector } from "react-redux";
import { selectedCountrySelector } from "../../redux/country.selector";

/**
 * CountryIndicatorComponent is a React component responsible for displaying a set of country indicators.
 * @function CountryIndicatorDataType
 * @returns {React.JSX.Element}
 */

const CountryIndicatorComponent = (): React.JSX.Element => {
    const selectedCountry: CountryInfoType = useSelector(
        selectedCountrySelector,
    );

    const propData: CountryIndicatorDataType[] = [
        {
            id: "01",
            text: "Country income group (2022)",
            iconImg: IncomeGroupIcon,
            alt: "Income-group",
            status: selectedCountry.countryInfo?.incomeGroup,
        },
        {
            id: "02",
            text: "WHO FCTC Status",
            iconImg: FCTCStatusIcon,
            alt: "FCTC-status",
            status: selectedCountry.countryInfo?.fctcStatus,
        },
        {
            id: "03",
            text: "Date updated",
            iconImg: DataUpadtedIcon,
            alt: "Date-update",
            status: selectedCountry.countryInfo?.dateUpdated,
        },
    ];

    return (
        <div className="grid grid-cols-12 justify-center items-center">
            <CountryIndicatorBlockComponent indicatorData={propData} />
        </div>
    );
};

export default CountryIndicatorComponent;
