import { ProtectCurrentStatusProptype } from "../../protect/components/protect-current-status/protect-current-status-interface";
import { SuccessPropType } from "../components/success-story/success-story-interface";

//Image or Icon or logo imports
import monitorSuccessStoryImg from "../../assets/success-story-images/monitor-success-story-img.jpg";
import protectSuccessStoryImg from "../../assets/success-story-images/protect-success-story-img.jpg";
import enforceSuccessStoryImg from "../../assets/success-story-images/enforce-success-story-img.jpg";
import raiseTaxSuccessStoryImg from "../../assets/success-story-images/raise-tax-success-story-img.png";
import offerSuccessStoryImg from "../../assets/success-story-images/offer-success-story-img.jpg";
import massMediaSuccessStoryImg from "../../assets/success-story-images/mass-media-success-story-img.png";
import warnPackageSuccessStoryImg from "../../assets/success-story-images/warn-package-success-story-img.png";

export const commonCoverPropData: ProtectCurrentStatusProptype[] = [
    {
        id: "01",
        cardBgColor: "color-no-data",
        meets: false,
    },
    {
        id: "02",
        cardBgColor: "color-no-requirement",
        meets: false,
    },
    {
        id: "03",
        cardBgColor: "color-minimal-requirement",
        meets: false,
    },
    {
        id: "04",
        cardBgColor: "color-moderate-requirement",
        meets: false,
    },
    {
        id: "05",
        cardBgColor: "color-complete-requirement",
        meets: false,
    },
];

export enum SCORE_PILL_TYPE {
    Big = "big",
    Small = "small",
}

export const BG_COLOR_NO_DATA = "color-no-data";
export const BG_COLOR_NO_REQUIREMENT = "color-no-requirement";
export const BG_COLOR_MINIMAL = "color-minimal-requirement";
export const BG_COLOR_MODERATE = "color-moderate-requirement";
export const BG_COLOR_COMPLETE = "color-complete-requirement";

export const STATUS_NO_DATA = "No data reported";
export const STATUS_NO_REQUIREMENT = "No measure or weak measure achieved";
export const STATUS_MINIMAL = "Minimal measure achieved";
export const STATUS_MODERATE = "Moderate measure achieved";
export const STATUS_COMPLETE = "Complete measure achieved";
export const STATUS_NOT_APPLICABLE = "Not applicable";

export const DATA_NOT_REPORTED = "Data not reported";
export const NO_CRITERIA_ACHIEVED = "No measure achieved";

export const TEXT_WHITE = "text-white";
export const TEXT_BLACK = "text-black";

export const VALIDATION_MESSAGE = "Country not found";

export const KEYBOARD_ENTER = "Enter";
export const KEYBOARD_ARROW_DOWN = "ArrowDown";
export const KEYBOARD_ARROW_UP = "ArrowUp";

export const MONITOR_SUCCESS_PROP_DATA: SuccessPropType[] = [
    {
        id: "01",
        heading: `Surveillance helps drive a decline in tobacco use, Philippines`,
        text: `Over the past two decades the Philippines has conducted repeated national surveys to monitor tobacco use and evaluate progress in tobacco control, including the Global Adult Tobacco Survey (GATS) in 2009, 2015, and 2021; and the Global Youth Tobacco Survey (GYTS) in 2000, 2003, 2007, 2011, 2015, and 2019. Completing six rounds of GYTS and three rounds of GATS to date, the Philippines has invested in sustainable monitoring and surveillance systems to enable the formulation, tracking, and implementation of evidence-based tobacco control policy and interventions.\n
    Since the launch of the Philippines’ first National Tobacco Control Strategy in 2012, sustainable surveillance and monitoring systems (including regular funding and use of evidence for policy action) have formed one of the country’s key strategies to advance tobacco control. Strengthening surveillance data was also one of the priority areas of the second National Tobacco Control Strategy 2017–2022.\n
    The landmark Sin Tax Law 2012 used the findings from the 2009 GATS as evidence of the high rates of smoking (28.2%) in the Philippines, particularly among men (47.6%), and kickstarted a series of tax reforms adopted between 2012 and 2020 that increased tax rates and made tobacco products less affordable. As a result, the prevalence of tobacco use among adults fell from 29.7% to 19.5% between 2009 and 2021, while tobacco smoking among adults fell from 28.2% to 18.5% during the same period.`,
        img: monitorSuccessStoryImg,
        alt: "monitor-success-story-img",
    },
];

export const PROTECT_SUCCESS_PROP_DATA: SuccessPropType[] = [
    {
        id: "01",
        heading: `Collaborating and focusing on smoke-free legislation, Kyrgyzstan`,
        text: `In July 2021, Kyrgyzstan’s Supreme Council adopted the Law entitled “On protecting the health of citizens of the Kyrgyz Republic from the consequences of tobacco consumption, nicotine and exposure to second-hand tobacco smoke and aerosol”. This law requires all workplaces and public places – including public transport, stations and taxis – to be 100% smoke-free.\n
    In a country where half of all men smoke, effective measures to combat tobacco use and protect the population from the harmful consequences of exposure to second-hand smoke are hugely important. Over the past few years, Kyrgyzstan has taken steps to promote smoke-free environments as part of wider measures to reduce tobacco consumption – one of the most notable examples being the Smoke-free III World Nomad Games, held in Kyrgyzstan in September 2018. The Games were a success, attracting over 70 000 spectators from across the world, and more than 2300 athletes from 74 countries. Initiatives such as the smoke-free Games are rare in Kyrgyzstan, and have not remedied the issue of widespread exposure to tobacco smoke in public places. As a result, and through meticulous work and joint efforts of various stakeholders, a tobacco control law was developed, and adopted in 2021.\n
    Kyrgyzstan’s new smoking ban is very comprehensive as it also includes the use of hookahs, e-cigarettes and HTPs. Article 5 of the Law specifically states that its provisions apply to e-cigarettes and e-liquids, and that the use of e-cigarettes and HTPs is considered as smoking under the Law.\n
    In a separate by-law, a new no-smoking sign has been approved that depicts a cigarette, a hookah, an HTP and an e-cigarette. All these graphics are crossed out with a red line, meaning a complete ban. In accordance with the legislation in force in Kyrgyzstan, the sign will be placed at the entrance to buildings and in areas where smoking is prohibited. While the new law met with resistance from tobacco companies, the government has stood firm in its commitment to protecting public health. Furthermore, several other factors have contributed to the successful adoption of a strong and comprehensive law (with TAPS now completely banned), including the mobilization of civil society; creation of a support group that includes decision-makers; strong international support; and continuous pressure maintained to secure the process without interruption.`,
        img: protectSuccessStoryImg,
        alt: "protect-success-story-img",
    },
];

export const OFFER_SUCCESS_PROP_DATA: SuccessPropType[] = [
    {
        id: "01",
        heading: `Tobacco cessation services go nationwide, Islamic Republic of Iran`,
        text: `In 2021, the Islamic Republic of Iran’s Ministry of Health and Medical Education (MOHME) established a national tobacco cessation helpline and tobacco cessation service clinics nationwide.\n
    The free tobacco cessation helpline is available 9am to 3pm, offers evidence-based support to people who want to quit tobacco, i.e., counseling and referrals to tobacco cessation service clinics for medication. The cessation clinics operate in 63 medical science universities (UMSs) – set up, supported and led by the Mental Health and Substance Abuse Prevention Department of MOHME.\n
    The tobacco cessation clinics (based in primary health care centers under supervision of the UMSs or specialist centers) can provide prescriptions from general practitioners for the NRT – which is fully covered by the insurance system, and affordable also for those who want to use it without a prescription.\n
    By adding a national toll-free quit line and covering the cost of NRTs for tobacco users, Iran (Islamic Republic of) has progressed to meet the highest level of the “O” component of the MPOWER package for tobacco control. This key achievement was possible with WHO’s technical assistance and the support of the WHO TFI Unit.\n
    The efforts led to establishing the Iranian comprehensive national cessation service including improving the management of tobacco cessation services, developing and operating a national toll-free quit line; capacity building for primary health care staff working in selected comprehensive health centers offering free tobacco cessation services; advocacy and social awareness-raising on tobacco cessation; and boosting the visibility of tobacco cessation services.`,
        img: offerSuccessStoryImg,
        alt: "offer-success-story-img",
    },
];

export const WARN_PACKAGING_SUCCESS_PROP_DATA: SuccessPropType[] = [
    {
        id: "01",
        heading: `Tobacco warnings go from strength to strength, Benin`,
        text: `Tobacco warnings go from strength to strength, Benin When the newly adopted WHO FCTC was closed for signature on 29 June 2004, Benin was one of the 168 signatories. Signing the Treaty was a political act that indicated the agreement of a Member State to ratify it and its commitment to implement its the provisions. One year later, on 3 November 2005, Benin ratified the Treaty, and became a Party to the WHO FCTC three months later.\n
    Soon after this, on 7 August 2006, the national assembly adopted the Law No. 2006-12 Concerning Regulation of the Production, Commercialization and Consumption of Cigarettes and Other Tobacco Products. Under this law, for the first time in Benin, a textual warning was required to be displayed on at least 30% of the main areas of tobacco packages.\n
    This single textual warning was still displayed until recently. Indeed, in 2017, Law No. 2017-27 of December 18, 2017 relating to the production, packaging, labelling, sale and use of tobacco and similar products was adopted, requiring large pictorial health warnings to be displayed on tobacco packages for the first time in Benin. On 11 June 2021, the Minister of Health signed Decree No. 2021-0065 prescribing the graphic and written health warnings to be printed on the packaging of cigarettes and other tobacco products in 2022. Four new health warnings are required, each of them being accompanied with a descriptive picture. The four warnings will rotate every two years. The image cover 60% of front and rear surfaces of the packages, and the textual warning covers an additional 30% of these surfaces, which means 90% of front and rear of the tobacco packages are used for displaying mandatory health warnings and labelling requirements.\n
    This was followed by Decree No. 2022-073 of February 9, 2022 setting the conditions and procedures for issuing approvals for the manufacture and import of tobacco, its derivatives and similar in the Republic of Benin, initiated by the Ministry in charge of trade pursuant to Article 8 of the Protocol to Eliminate Illicit Trade in Tobacco Products that Benin ratified in 2018. The latter requires the validation of mock-ups of tobacco product packaging by the Ministry in charge of health before the issuance of approval to import and market tobacco products in Benin.`,
        img: warnPackageSuccessStoryImg,
        alt: "case-study-image",
    },
];

export const WARN_MASS_MEDIA_SUCCESS_PROP_DATA: SuccessPropType[] = [
    {
        id: "01",
        heading: `“United against tobacco and COVID-19” campaign success, occupied Palestinian territory`,
        text: `In the occupied Palestinian territory, the Palestinian Ministry of Health successfully aired its “United against tobacco and COVID-19” mass media campaign between June and September 2022, despite the ongoing emergency situation that deepened during the COVID-19 pandemic. The campaign was also conducted in Egypt, Iraq, Jordan, and Türkiye, and was funded by the Centers for Disease Control and implemented by Global Health Development Eastern Mediterranean Public Health Network (GHD|EMPHNET) with technical assistance from Vital Strategies.\n
    The campaign, covering villages, cities, and refugee camps, was run in parallel with other campaigns and highlighted the harms of tobacco and the benefits of tobacco cessation to smokers, their families and friends, and others. Built on five key activities, the campaign was aired on television and radio, and advertised via billboards, SMS messages, pre-paid Internet adverts, social media platforms, and posters on public transport. In addition, supporting activities were developed throughout the campaign, including video interviews with children (held with their parents’ permission) in which they expressed their opinions about smoking.\n
    Public surveys and focus group discussions were used to research the views of the target audience and inform and develop the campaign messages, and to pre-test the campaign materials before roll-out. The Ministry of Health monitored the campaign to ensure the campaign materials were used as planned, and worked with journalists to gain publicity.\n
    The campaign achieved its predefined target by reaching more the 3 million Palestinian people, despite the challenges faced. The Palestinian Ministry of Health used the timing of the campaign to open smoking cessation clinics at all primary health care centers in 14 districts and used the campaign platform to start conversations with local communities to enact policies to decrease smoking prevalence. A post-implementation impact evaluation of the campaign was held.`,
        img: massMediaSuccessStoryImg,
        alt: "mass-media-success-story-img",
    },
];

export const ENFORCE_SUCCESS_PROP_DATA: SuccessPropType[] = [
    {
        id: "01",
        heading: `Cabo Verde strengthens legislative field regarding tobacco advertising`,
        text: `New legislation to ban tobacco advertising in Cabo Verde was approved by the country’s parliament in 2022, stipulating that “all forms of direct or indirect, hidden, concealed and subliminal advertising and promotion of tobacco companies and their brands are prohibited”, alongside all types of sponsorship or information campaigns.\n
    The ban on direct tobacco advertising includes advertising in international magazines and newspapers, international television and radio, national television and radio, local magazines and newspapers, posters and outdoor advertising, point-of-sale advertising and Internet advertising.\n
    The new tobacco legislation also prohibits smoking (including through the use of e-cigarettes) in places where sovereign bodies, public administration services and bodies, and State companies are located, as well as in hospitals, buildings serving people under the age of 18 years; and sports facilities, among others.\n
    It also sets out the prohibition of the sale, supply and consumption of tobacco by people under the age of 18 years, and a “ban on the marketing of tobacco in establishments, particularly educational, health and sports establishments, thus limiting young people’s access to tobacco products”.\n
    According to a STEPS non-communicable disease survey conducted in 2020, the prevalence of tobacco use in Cabo Verde is 12.5% and 15% of the population is exposed to second-hand smoke.`,
        img: enforceSuccessStoryImg,
        alt: "enforce-success-story-img",
    },
];

export const RAISE_TAX_SUCCESS_PROP_DATA: SuccessPropType[] = [
    {
        id: "01",
        heading: `Historic levels of tobacco taxation are reached, Peru`,
        text: `Peru is a regional and global leader in tobacco taxation, ranking among the top 10 countries implementing tobacco taxes by the Tobacconomics Cigarette Tax Scorecard – a tool that evaluates cigarette tax policies based on their structure, tax share, price, and affordability. The scorecard assigned Peru a score of 4.13/5 – the second-highest score among Latin American countries after Ecuador and significantly higher than regional and global averages.\n
    This achievement has been years in the making. Since the 1990s, Peru has applied an excise tax on cigarettes, but between 1999 and 2009, tobacco tax policy, from a health perspective, moved slowly. In 2010, the excise tax design was strengthened when a specific (quantity-based) component was introduced to replace the ad-valorem structure. Between 2010 and 2016, no further adjustments took place. Then, in 2016, the government increased the specific excise by more than 150% – the largest increase in tobacco excise tax in Peru’s history. The total tax share (taxes as a percentage of the price of the most sold brand) jumped from 37.8% to 49.5%.\n
    This was a win for reducing the affordability of cigarettes (by nearly 2%) and for simultaneously increasing tax collection. As a result, in 2017, Peru’s Ministry of Economy and Finance was selected as one of WHO’s World No Tobacco Day Award recipients, an award that “recognizes institutions, organizations, and individuals who have made outstanding contributions in the fight against smoking in their country”.\n
    This trend continued, and in 2018 another increase of the specific excise was implemented – along with adjustments for alcohol, sugar-sweetened beverages, and fuel taxes – marking the first time such a tax adjustment was introduced in the country with an explicit public health rationale. In a celebrated move, Peru then took a further step in 2020 to protect public health and implement a key WHO FCTC Article 6 guideline by modifying the methodology to calculate its amount-specific excise tax on cigarettes so that it would automatically account for inflation, thus preserving the real value of the tax over time.\n
    Peru currently boasts a 73.3% total tax share, the highest ever recorded by the country. Yet there is room for improvement – prices have been declining since the last adjustment. Challenges for the future include pushing affordability levels lower and the tax rate higher to achieve the WHO-recommended 75% total tax share thresholds`,
        img: raiseTaxSuccessStoryImg,
        alt: "raise-tax-success-story-img",
    },
    {
        id: "02",
        heading: `Cigarette tax hikes pave the way for a healthier future, Timor-Leste`,
        text: `Timor-Leste is one of the newest countries in the world and its fast-growing population is one of the	 world’s youngest. In his speech for Universal Health Coverage (UHC) Day in December 2022, the country’s Finance Minister highlighted this, noting that in order to “reap the advantage of this demographic dividend, our people need to be healthy, educated and live with confidence”. This observation reflects the high levels of tobacco use and other causes of noncommunicable diseases to which the population is exposed.\n
        The Minister’s UHC Day speech announced that Timor-Leste would significantly increase excise taxes on unhealthy products like tobacco, emphasizing that, “these taxes are considered win-win policies because not only do they save lives and prevent disease, but they promote health equity and they are a great source of financing the state budget”. Most notably, the excise tax on tobacco has increased dramatically – from US$ 19/kg in December 2021 to US$ 50/kg in January 2022, and US$ 100/kg in January 2023.\n
        Evidence collected for this report shows the immediate impact of these excise tax increases, with the retail price of cigarettes increasing by 75% – from US$ 2 in 2020 to US$2 .25 in 2022 and US$ 3.50 per pack in 2023. The share of tax in the retail price of cigarettes increased from 21.8% in 2020 to 47.2% in 2022 and to 59.8% in 2023. While it is still too early to assess the impact of these increases on tobacco use, there is little doubt that Timor-Leste has become a global leader for health taxes and, in turn, has promoted a healthier – more sustainable – future for generations to come.`,
    },
];

export const GRID_COLS_4_MONITOR_COVER_LEFT =
    "grid grid-cols-4 monitor-cover-left";
export const GRID_COLS_5_MONITOR_COVER_LEFT = "grid grid-cols-5";

export const ERROR_MESSAGE_FOR_LESS_CHARACTER =
    "Please enter minimum 3 characters";
