//Component import
import WaterMarkButtonComponent from "../water-mark-button/water-mark-button.component";

//Image or Icon or logo imports
import ReverseArrowIcon from "../../../assets/icon/reverse-arrow-icon.png";
import DownlaodIcon from "../../../assets/icon/download-icon.png";

//Interface import
import { WaterMarkDataType } from "./water-mark-interface";
import { IRIS_REPORT_URL } from "../../../shared/constants/url.constants";

const watermarkButtonData: WaterMarkDataType[] = [
    {
        id: "01",
        bgColor: "bg-white",
        img: ReverseArrowIcon,
        alt: "Reverse-Arrow-Icon",
        text: "Search again for another country",
        url: "/",
    },
    {
        id: "02",
        bgColor: "bg-yellow-500",
        img: DownlaodIcon,
        alt: "Download-Icon",
        text: "Download the full mpower report",
        url: IRIS_REPORT_URL,
        target: true,
    },
];

/**
 * WaterMarkComponent displays a watermark banner with buttons.
 *
 * @component
 * @returns {React.JSX.Element}
 */

const WaterMarkComponent = (): React.JSX.Element => {
    return (
        <div className="banner-bg banner-height mt-20 gap-5 md:flex justify-center items-center">
            <WaterMarkButtonComponent buttonData={watermarkButtonData} />
        </div>
    );
};

export default WaterMarkComponent;
