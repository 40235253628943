import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as types from "./country.types";
import { SELECTED_COUNTRY } from "../country.constants";

export const initialState: types.InitialState = {
    inputData: "",
    selectedCountry: SELECTED_COUNTRY,
    countryList: [],
    blockData: [
        {
            id: "01",
            nameInitial: "M",
            nameFinal: "Monitor",
            colorPalette: [],
        },
        {
            id: "02",
            nameInitial: "P",
            nameFinal: "Protect",
            colorPalette: [],
        },
        {
            id: "03",
            nameInitial: "O",
            nameFinal: "Offer",
            colorPalette: [],
        },
        {
            id: "04",
            nameInitial: "W",
            nameFinal: "Health warnings",
            colorPalette: [],
        },
        {
            id: "05",
            nameInitial: "W",
            nameFinal: "Mass media",
            colorPalette: [],
        },
        {
            id: "06",
            nameInitial: "E",
            nameFinal: "Enforce",
            colorPalette: [],
        },
        {
            id: "07",
            nameInitial: "R",
            nameFinal: "Raise tax",
            colorPalette: [],
        },
    ],
    countryName: "",
    matchedCountryCode: "",
    isCountryNotFound: false,
    mpowerScoreKeyData: [],
};

const countryInfo = createSlice({
    name: "countryInfo",
    initialState,
    reducers: {
        getCountryInfo: (state, _action) => {
            return { ...state };
        },

        getCountryInfoSuccess: (
            state,
            action: PayloadAction<types.CountryInfoType>,
        ) => {
            const progressMappings: Record<string, string> = {
                "01": "monitoring",
                "02": "protect",
                "03": "offer",
                "04": "warnPackaging",
                "05": "warnMassMedia",
                "06": "enforce",
                "07": "raiseTax",
            };

            const updatedBlockData = state.blockData.map((blockData) => {
                let newColorPalette: any[] = [];
                const ifYearsRange = (year: number) => {
                    return (
                        year === 2007 ||
                        year === 2008 ||
                        year === 2010 ||
                        year === 2012 ||
                        year === 2014 ||
                        year === 2016 ||
                        year === 2018 ||
                        year === 2020 ||
                        year === 2022
                    );
                };

                for (let year = 2007; year <= 2022; year++) {
                    if (ifYearsRange(year)) {
                        const fieldName = progressMappings[blockData.id];
                        newColorPalette.push(
                            action.payload.progress[year][fieldName],
                        );
                    }
                }

                return {
                    ...blockData,
                    colorPalette: newColorPalette,
                };
            });

            return {
                ...state,
                blockData: updatedBlockData,
                selectedCountry: action.payload,
            };
        },

        getCountryInfoError: (state) => {
            return {
                ...state,
            };
        },

        setSearchData: (state, action) => {
            return { ...state, inputData: action.payload };
        },

        getCountryListInfo: (state) => {
            return { ...state };
        },

        getCountryListInfoSuccess: (
            state,
            action: PayloadAction<types.CountryListInfoType>,
        ) => {
            return {
                ...state,
                countryList: action.payload,
            };
        },

        getCountryListInfoError: (state) => {
            return {
                ...state,
            };
        },

        setCountryNameData: (state, action) => {
            return { ...state, countryName: action.payload };
        },

        setMatchedCountryCode: (state, action) => {
            return { ...state, matchedCountryCode: action.payload };
        },

        getMpowerInfo(state, _action) {
            return { ...state };
        },

        setIsCountryNotFound: (state, action) => {
            return { ...state, isCountryNotFound: action.payload };
        },

        clearCountryInfoData: (state) => {
            return {
                ...state,
                selectedCountry: {
                    countryInfo: {
                        dateUpdated: "",
                        fctcStatus: "",
                        incomeGroup: "",
                    },
                    enforce: "",
                    monitoring: "",
                    offer: "",
                    progress: {},
                    protect: "",
                    affordCat: "",
                    totalTaxEstimate: "",
                    raiseTax: "",
                    warnMassMedia: "",
                    warnPackaging: "",
                    indicators: [
                        {
                            code: "",
                            title: "",
                        },
                    ],
                    monitorBlockPropData: {
                        name: "",
                        img: "",
                        alt: "",
                        paraText: "",
                        url: "",
                        initial: "",
                        finalWord: "",
                        buttonText: "",
                        buttonBgColor: "",
                        countryGroup: null,
                    },
                    protectBlockPropData: {
                        name: "",
                        img: "",
                        alt: "",
                        paraText: "",
                        url: "",
                        initial: "",
                        finalWord: "",
                        buttonText: "",
                        buttonBgColor: "",
                        countryGroup: null,
                    },
                    offerBlockPropData: {
                        name: "",
                        img: "",
                        alt: "",
                        paraText: "",
                        url: "",
                        initial: "",
                        finalWord: "",
                        buttonText: "",
                        buttonBgColor: "",
                        countryGroup: null,
                    },
                    warnPackageBlockPropData: {
                        name: "",
                        img: "",
                        alt: "",
                        paraText: "",
                        url: "",
                        initial: "",
                        finalWord: "",
                        buttonText: "",
                        buttonBgColor: "",
                        countryGroup: null,
                    },
                    massMediaBlockPropData: {
                        name: "",
                        img: "",
                        alt: "",
                        paraText: "",
                        url: "",
                        initial: "",
                        finalWord: "",
                        buttonText: "",
                        buttonBgColor: "",
                        countryGroup: null,
                    },
                    enforceBlockPropData: {
                        name: "",
                        img: "",
                        alt: "",
                        paraText: "",
                        url: "",
                        initial: "",
                        finalWord: "",
                        buttonText: "",
                        buttonBgColor: "",
                        countryGroup: null,
                    },
                    raiseTaxBlockPropData: {
                        name: "",
                        img: "",
                        alt: "",
                        paraText: "",
                        url: "",
                        initial: "",
                        finalWord: "",
                        buttonText: "",
                        buttonBgColor: "",
                        countryGroup: null,
                    },
                },
                blockData: [
                    {
                        id: "01",
                        nameInitial: "M",
                        nameFinal: "Monitor",
                        colorPalette: [],
                    },
                    {
                        id: "02",
                        nameInitial: "P",
                        nameFinal: "Protect",
                        colorPalette: [],
                    },
                    {
                        id: "03",
                        nameInitial: "O",
                        nameFinal: "Offer",
                        colorPalette: [],
                    },
                    {
                        id: "04",
                        nameInitial: "W",
                        nameFinal: "Health warnings",
                        colorPalette: [],
                    },
                    {
                        id: "05",
                        nameInitial: "W",
                        nameFinal: "Mass media",
                        colorPalette: [],
                    },
                    {
                        id: "06",
                        nameInitial: "E",
                        nameFinal: "Enforce",
                        colorPalette: [],
                    },
                    {
                        id: "07",
                        nameInitial: "R",
                        nameFinal: "Raise tax",
                        colorPalette: [],
                    },
                ],
            };
        },

        setMpowerScoreKeyData: (state, _action) => {
            return { ...state };
        },

        setMpowerScoreKeyDataSuccess: (state, action) => {
            return { ...state, mpowerScoreKeyData: action.payload };
        },
    },
});

export const {
    getCountryInfo,
    getCountryInfoSuccess,
    getCountryInfoError,

    setSearchData,

    getCountryListInfo,
    getCountryListInfoSuccess,
    getCountryListInfoError,

    setCountryNameData,

    setMatchedCountryCode,

    getMpowerInfo,

    setIsCountryNotFound,
    clearCountryInfoData,

    setMpowerScoreKeyData,
    setMpowerScoreKeyDataSuccess,
} = countryInfo.actions;

export default countryInfo.reducer;
