import React from "react";

//Interface import
import { ProgressYearBlockProps } from "./progress-year-block-interface";

/**
 * ProgressYearBlockComponent component for rendering a year blocks.
 *
 * @Component
 * @param {ProgressYearBlockProps} props
 * @returns {React.JSX.Element}
 */

const ProgressYearBlockComponent = (
    props: ProgressYearBlockProps,
): React.JSX.Element => {
    return (
        <>
            {props?.yearData?.map((yearData) => (
                <React.Fragment key={yearData.id}>
                    <div
                        className="col-span-1 rounded bg-slate-800 text-white m-2 p-1"
                        id={`year-${yearData.year}`}
                    >
                        {yearData.year}
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

export default ProgressYearBlockComponent;
