import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../shared/components/rest-handler/rest-handler.service";
import * as COUNTRYINFO_SLICE from "./country.slice";
import { getProccessedCountryInfo } from "./country.utilities";
import { getMonitorInfo } from "../../monitor/redux/monitor.slice";
import { getProtectInfo } from "../../protect/redux/protect.slice";
import { getOfferInfo } from "../../offer/redux/offer.slice";
import { getWarnPackageInfo } from "../../warn-package/redux/warn-package.slice";
import { getMassMediaInfo } from "../../warn-mass-media/redux/warn-mass-media.slice";
import { getEnforceInfo } from "../../enforce/redux/enforce.slice";
import { getRaiseTaxInfo } from "../../raise-tax/redux/raise-tax.slice";
import {
    NAME_ENFORCE,
    NAME_MONITOR,
    NAME_OFFER,
    NAME_PROTECT,
    NAME_RAISE,
    NAME_WARNCAMPAIGN,
    NAME_WARNPACKAGE,
} from "../country.constants";
import {
    GENERAL_MPOWER_SCORE_KEY_DATA,
    OFFER_MPOWER_SCORE_KEY_DATA,
    PROTECT_MPOWER_SCORE_KEY_DATA,
    ENFORCE_MPOWER_SCORE_KEY_DATA,
    WARN_MASSMEDIA_MPOWER_SCORE_KEY_DATA,
    WARN_PACKAGE_MPOWER_SCORE_KEY_DATA,
    RAISETAX_MPOWER_SCORE_KEY_DATA,
    MONITOR_MPOWER_SCORE_KEY_DATA,
} from "../components/mpower-progress-main/mpower-progress.constants";
import { setShowPageLoader } from "../../shared/redux/shared.slice";
import { normalizeString } from "../../shared/utility/shared.utility";
import { CountryListInfoType } from "./country.types";

export function* getCountryInfo(action: any): any {
    let url = `/CountryData?countryCode=${action?.payload || ""}`;
    try {
        yield put(setShowPageLoader(true));
        let countryInfo = yield call(RestHandlerService.get, url, {});

        countryInfo = getProccessedCountryInfo(countryInfo);

        yield put(COUNTRYINFO_SLICE.getCountryInfoSuccess(countryInfo));
    } catch (err) {
        yield put(COUNTRYINFO_SLICE.getCountryInfoError());
    } finally {
        yield put(setShowPageLoader(false));
    }
}

export function* getCountryListInfo(): any {
    let url = `/CountryList`;
    try {
        const countryListInfo = yield call(RestHandlerService.get, url, {});
        countryListInfo.forEach((country: CountryListInfoType) => {
            country.normalizedTitle = normalizeString(country.title);
        });
        yield put(COUNTRYINFO_SLICE.getCountryListInfoSuccess(countryListInfo));
    } catch (err) {
        yield put(COUNTRYINFO_SLICE.getCountryListInfoError());
    }
}

export function* getMpowerInfoAsync(action: any): any {
    switch (action.payload.name) {
        case NAME_MONITOR:
            yield put(getMonitorInfo(action?.payload?.matchedCountryCode));

            break;
        case NAME_PROTECT:
            yield put(getProtectInfo(action?.payload?.matchedCountryCode));

            break;
        case NAME_OFFER:
            yield put(getOfferInfo(action?.payload?.matchedCountryCode));

            break;
        case NAME_WARNPACKAGE:
            yield put(getWarnPackageInfo(action?.payload?.matchedCountryCode));

            break;
        case NAME_WARNCAMPAIGN:
            yield put(getMassMediaInfo(action?.payload?.matchedCountryCode));

            break;
        case NAME_ENFORCE:
            yield put(getEnforceInfo(action?.payload?.matchedCountryCode));

            break;
        case NAME_RAISE:
            yield put(getRaiseTaxInfo(action?.payload?.matchedCountryCode));

            break;
        default:
            break;
    }
}

const mpowerScoreKeyDataMap: any = {
    "-": GENERAL_MPOWER_SCORE_KEY_DATA,
    "0": MONITOR_MPOWER_SCORE_KEY_DATA,
    "1": PROTECT_MPOWER_SCORE_KEY_DATA,
    "2": OFFER_MPOWER_SCORE_KEY_DATA,
    "3": WARN_MASSMEDIA_MPOWER_SCORE_KEY_DATA,
    "4": WARN_PACKAGE_MPOWER_SCORE_KEY_DATA,
    "5": ENFORCE_MPOWER_SCORE_KEY_DATA,
    "6": RAISETAX_MPOWER_SCORE_KEY_DATA,
};

export function* setMpowerScoreKeyDataSaga(action: any): any {
    const mappedData = mpowerScoreKeyDataMap[action?.payload] || [];

    yield put(COUNTRYINFO_SLICE.setMpowerScoreKeyDataSuccess(mappedData));
}

export function* countryInfoSaga() {
    yield takeLatest("countryInfo/getCountryInfo", getCountryInfo);
    yield takeLatest("countryInfo/getCountryListInfo", getCountryListInfo);
    yield takeLatest("countryInfo/getMpowerInfo", getMpowerInfoAsync);
    yield takeLatest(
        "countryInfo/setMpowerScoreKeyData",
        setMpowerScoreKeyDataSaga,
    );
}
