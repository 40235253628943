import homeSearch from "../../../assets/icon/home-search.png";
import homeCompare from "../../../assets/icon/home-compare.png";
import homeOpenBook from "../../../assets/icon/home-openbook.png";
import CountrySearchComponent from "../country-search/country-search.component";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

/**
 * The CarouselSectionThree component represents the third slide of a carousel.
 * @function CarouselSectionThreeComponent
 * @returns {React.JSX.Element}
 */

export interface ICountrySectionThreeComponentProps {
    onFocus: () => void;
    onBlur: () => void;
}

const CarouselSectionThreeComponent: React.FC<
    ICountrySectionThreeComponentProps
> = ({ onFocus, onBlur }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const currentIndex = useSelector(
        (state: RootState) => state?.sharedSlice?.carousel?.currentIndex,
    );

    useEffect(() => {
        if (inputRef.current && currentIndex === 2) {
            inputRef.current.focus();
        }
    }, [currentIndex]);

    return (
        <>
            <p className="text-4xl text-center mb-6 hp-heading-color carousel-three" id="txtpath09">
                The pathway to a tobacco-free world
            </p>
            <div className="sm:flex text-center mb-6">
                <div className="flex-grow m-2">
                    <div className="p-7 rounded-full bg-white w-32 h-32 mx-auto flex justify-center items-center mb-3">
                        <img
                            src={homeSearch}
                            alt="home-search-icon"
                            className="w-full -rotate-90"
                        />
                    </div>
                    <p className="text-lg">
                        Search for a country to explore its data in the MPOWER
                        categories
                    </p>
                </div>
                <div className="flex-grow m-2">
                    <div className="p-8 rounded-full bg-white w-32 h-32 mx-auto flex justify-center items-center mb-3">
                        <img
                            src={homeCompare}
                            alt="home-compare-icon"
                            className="w-full"
                        />
                    </div>
                    <p className="text-lg">
                        Compare progress against other countries in each of the
                        sections
                    </p>
                </div>
                <div className="flex-grow m-2">
                    <div className="p-4 rounded-full bg-white w-32 h-32 mx-auto flex justify-center items-center mb-3">
                        <img
                            src={homeOpenBook}
                            alt="home-open-icon"
                            className="w-full"
                        />
                    </div>
                    <p className="text-lg">
                        Download reports from your customised searches to help
                        push for change
                    </p>
                </div>
            </div>

            <CountrySearchComponent
                inputRef={inputRef}
                showFirstTimeErrorMessage={true}
                widthClass="carousel-three-width-class"
                onFocus={onFocus}
                onBlur={onBlur}
                id="carousbtn03-searchbegin"
            />
        </>
    );
};

export default CarouselSectionThreeComponent;
