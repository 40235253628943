import {
    BG_COLOR_COMPLETE,
    BG_COLOR_MINIMAL,
    BG_COLOR_MODERATE,
    BG_COLOR_NO_DATA,
    BG_COLOR_NO_REQUIREMENT,
} from "../../../shared/constants/shared.contants";

//Interface import
import { ColorBlockprops } from "./progress-color-block-interface";

/**
 * ProgressColorBlockComponent component for rendering color blocks.
 *
 * @Comopnent
 * @param {ColorBlockprops} props
 * @returns {React.JSX.Element}
 */

const ProgressColorBlockComponent = (
    props: ColorBlockprops,
): React.JSX.Element => {
    let colorClass, text;

    if (props?.nameFinal === "Monitor") {
        switch (props?.colorClass) {
            case "1":
                colorClass = BG_COLOR_NO_REQUIREMENT;
                break;
            case "2":
                colorClass = BG_COLOR_MINIMAL;
                break;
            case "3":
                colorClass = BG_COLOR_MODERATE;
                break;
            case "4":
                colorClass = BG_COLOR_COMPLETE;
                break;
            case "—":
                text = "—";
                break;
            default:
                break;
        }
    } else {
        switch (props?.colorClass) {
            case "1":
                colorClass = BG_COLOR_NO_DATA;
                break;
            case "2":
                colorClass = BG_COLOR_NO_REQUIREMENT;
                break;
            case "3":
                colorClass = BG_COLOR_MINIMAL;
                break;
            case "4":
                colorClass = BG_COLOR_MODERATE;
                break;
            case "5":
                colorClass = BG_COLOR_COMPLETE;
                break;
            case "—":
                text = "—";
                break;
            default:
                break;
        }
    }

    return (
        <div
            className={`col-span-1 rounded ${colorClass} m-2 border border-black color-block`}
        >
            {text}
        </div>
    );
};

export default ProgressColorBlockComponent;
