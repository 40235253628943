import { put, takeLatest, call } from "redux-saga/effects";
import RestHandlerService from "../../shared/components/rest-handler/rest-handler.service";
import * as PROTECTINFO_SLICE from "./protect.slice";
import { getProccessedProtectInfo } from "./protect.utilities";

export function* getProtectInfo(action: any): any {
    let url = `/Protect?countryCode=${action?.payload || ""}`;
    try {
        let protectInfo = yield call(RestHandlerService.get, url, {});

        protectInfo = getProccessedProtectInfo(protectInfo);
        yield put(PROTECTINFO_SLICE.getProtectInfoSuccess(protectInfo));
    } catch (err) {
        yield put(PROTECTINFO_SLICE.getProtectInfoError());
    }
}

export function* protectInfoSaga() {
    yield takeLatest("protectInfo/getProtectInfo", getProtectInfo);
}
